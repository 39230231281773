import {TranslateService} from '@ngx-translate/core';

export class WorkerPosition {
  id: number;
  active: boolean;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  nameTranslated: string;

  public translateWorkerPosition(name: string): WorkerPosition {
    this.nameTranslated = name;
    return this;
  }
}

export function convertWorkerPosition(workerPositions: WorkerPosition[], translate: TranslateService): WorkerPosition[] {
  switch (translate.currentLang.toLowerCase()) {
    case 'fr':
      return workerPositions.map(wf => wf.translateWorkerPosition(wf.nameFR)).sort((a, b) => a.nameFR < b.nameFR ? -1 : 1);
    case 'nl':
      return workerPositions.map(wf => wf.translateWorkerPosition(wf.nameNL)).sort((a, b) => a.nameNL < b.nameNL ? -1 : 1);
    default :
      return workerPositions.map(wf => wf.translateWorkerPosition(wf.nameEN)).sort((a, b) => a.nameEN < b.nameEN ? -1 : 1);
  }
}


