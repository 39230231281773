import {Serializer} from '../../serializer';
import {PublicHolidayPage} from './public-holiday-page.model';
import { PublicHolidaySerializer } from './../../../../admin/model/public-holiday.serializer';

export class PublicHolidayPageSerializer implements Serializer {

  private publicHolidaySerializer = new PublicHolidaySerializer();

  fromJson(json: any): PublicHolidayPage {
    const publicHolidayPage = new PublicHolidayPage();

    json.content.forEach(
      content => {
        publicHolidayPage.content.push(this.publicHolidaySerializer.fromJson(content));
      }
    );

    publicHolidayPage.totalElements = json.totalElements;
    publicHolidayPage.totalPages = json.totalPages;
    return publicHolidayPage;
  }

  toJson(resource: Object): any {
  }
}

