import { JointCommission } from './../../../shared/model/joint-commission/joint-commission.model';
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FacadeService } from "../../../shared/service/facade/facade.service";
import { MatDialog } from "@angular/material/dialog";
import { WorkFunctionPage } from "src/app/shared/model/pagination/work-function/work-function-page.model";
import { WorkFunctionDialogComponent } from "src/app/shared/component/admin-dialog/work-function-dialog/work-function-dialog.component";
import { WorkFunction } from "src/app/shared/model/work-function/work-function.model";

@Component({
  selector: "app-work-function",
  templateUrl: "./work-function.component.html",
  styleUrls: ["./work-function.component.css", "../../admin.component.css"],
})
export class WorkFunctionComponent implements OnInit {
  @ViewChild("WorkFunctionPaginator", { static: true }) paginator: MatPaginator;

  workFunction: WorkFunction;
  jointCommissionList: JointCommission[] = [];

  dataSource = new MatTableDataSource<WorkFunction>();
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 20;
  length: number;

  constructor(
    private facadeService: FacadeService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getAllJointCommissions();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = [
    "id",
    "active",
    "jointCommissionCode",
    "category",
    "code",
    "nameFR",
    "nameEN",
    "nameNL",
    "nameDE",
  ];

  public getData(event?: PageEvent) {
    this.pageIndex = event ? event.pageIndex : 0;
    this.pageSize = event ? event.pageSize : 20;

    this.facadeService.getPageWorkFunction(this.pageIndex, this.pageSize).subscribe((data: WorkFunctionPage) => {
        for (let i = 0; i < data.content.length; i++) {
            this.setJointCommission(data.content[i]);
        }
        this.dataSource.data = data.content;
        this.dataSource.paginator = this.paginator;
        this.length = data.totalElements;
      });

    return event;
  }

  getAllJointCommissions() {
    this.facadeService.getAllJointCommissions().subscribe((list: JointCommission[]) => {
        this.jointCommissionList = list;
        this.getData();
    });
  }

  setJointCommission(element: WorkFunction) {
    for (let i = 0; i < this.jointCommissionList.length; i++) {
        if (this.jointCommissionList[i].id === element.jointCommission.id) {
            element.jointCommission = this.jointCommissionList[i];
            break;
        }
    }
  }

  openPopUp(row) {
    const dialogRef = this.dialog.open(WorkFunctionDialogComponent, {
      data: { element: row, jcList: this.jointCommissionList },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  addElement() {
    this.workFunction = new WorkFunction();
    this.workFunction.jointCommission = new JointCommission();
    this.workFunction.jointCommission.id = 1;
    this.openPopUp(this.workFunction);
  }
}
