import {Component, Input, ViewChild} from '@angular/core';
import {Request, RequestStatus} from '../../model/request.model';
import {FacadeService} from '../../../../shared/service/facade/facade.service';
import {AuthorizationService} from '../../../../login/shared/service/authorization.service';
import {Role} from '../../../../users/shared/model/user.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {RequestPage} from '../../../../shared/model/pagination/request/request-page.model';

@Component({
  selector: 'app-error-listing-request',
  templateUrl: './error-listing-request.component.html',
  styleUrls: ['./error-listing-request.component.css']
})
export class ErrorListingRequestComponent {
  displayedColumns: string[] = ['statusColor', 'requestId', 'customerName', 'interimName', 'startDate', 'endDate', 'errors'];
  dataSource = new MatTableDataSource<Request>();
  @ViewChild('errorRequestsPaginator', {static: true}) paginator: MatPaginator;
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;
  request: Request[];

  constructor(private facadeService: FacadeService,
              private authorizationService: AuthorizationService) {
  }

  get RequestStatus() {
    return RequestStatus;
  }

  @Input() set requests(r: RequestPage) {
    this.pageIndex = 0;
    this.pageSize = 5;
    this.length = r.totalElements;
    this.dataSource = new MatTableDataSource<Request>(r.content);
    this.dataSource.paginator = this.paginator;
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  public getEventRequestErrorData(event?: PageEvent) {
    this.facadeService.getPageRequestsWithError(event.pageIndex, event.pageSize).subscribe((request: RequestPage) => {
      this.dataSource.data = request.content;
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    });
    return event;
  }
}
