import {Serializer} from 'src/app/shared/model/serializer';
import {Status} from './status.model';

export class StatusSerializer implements Serializer {

  fromJson(json: any): Status {
    const status: Status = new Status();

    status.id = json.id;
    status.name = json.name;
    status.notified = json.notified;
    status.state = json.state;
    status.url = json.url;

    return status;
  }

  toJson(status: Status): any {
    return {
      id: status.id,
      name: status.name,
      notified: status.notified,
      state: status.state,
      url: status.url,
    };
  }

}
