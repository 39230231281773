import {Serializer} from 'src/app/shared/model/serializer';
import {ContactData, OperatingUnit, OtherInformation} from './operating-unit.model';
import {Address} from '../../../shared/model/address/address.model';
import {AddressSerializer} from '../../../shared/model/address/address.serializer';

export class OperatingUnitSerializer implements Serializer {
  private addressSerializer: AddressSerializer = new AddressSerializer();

  private static contactDataFromJson(jsonContractData: any): ContactData {
    const contactData: ContactData = new ContactData();

    contactData.phone = jsonContractData.phone;
    contactData.fax = jsonContractData.fax;
    contactData.email = jsonContractData.email;
    contactData.languageCode = jsonContractData.languageCode;

    return contactData;
  }

  private static otherInformationFromJson(jsonOtherInformation: any): OtherInformation {
    const otherInformation: OtherInformation = new OtherInformation();

    otherInformation.walloonRegionalApproval = jsonOtherInformation.walloonRegionalApproval;
    otherInformation.brusselsRegionalApproval = jsonOtherInformation.brusselsRegionalApproval;
    otherInformation.flemishRegionalApproval = jsonOtherInformation.flemishRegionalApproval;
    otherInformation.legalInsurance = jsonOtherInformation.legalInsurance;
    otherInformation.legalInsuranceNumber = jsonOtherInformation.legalInsuranceNumber;
    otherInformation.externalPreventionService = jsonOtherInformation.externalPreventionService;
    otherInformation.externalPreventionNumber = jsonOtherInformation.externalPreventionNumber;
    otherInformation.holidayAllocationOffice = jsonOtherInformation.holidayAllocationOffice;
    otherInformation.holidayAllocationNumber = jsonOtherInformation.holidayAllocationNumber;
    otherInformation.familyAllocationOffice = jsonOtherInformation.familyAllocationOffice;
    otherInformation.familyAllocationNumber = jsonOtherInformation.familyAllocationNumber;
    otherInformation.socialSecretary = jsonOtherInformation.socialSecretary;
    otherInformation.bankAccountNumber = jsonOtherInformation.bankAccountNumber;

    return otherInformation;
  }

  private static otherInformationToJson(otherInformation: OtherInformation) {
    return {
      walloonRegionalApproval: otherInformation.walloonRegionalApproval,
      brusselsRegionalApproval: otherInformation.brusselsRegionalApproval,
      flemishRegionalApproval: otherInformation.flemishRegionalApproval,
      legalInsurance: otherInformation.legalInsurance,
      legalInsuranceNumber: otherInformation.legalInsuranceNumber,
      externalPreventionService: otherInformation.externalPreventionService,
      externalPreventionNumber: otherInformation.externalPreventionNumber,
      holidayAllocationOffice: otherInformation.holidayAllocationOffice,
      holidayAllocationNumber: otherInformation.holidayAllocationNumber,
      familyAllocationOffice: otherInformation.familyAllocationOffice,
      familyAllocationNumber: otherInformation.familyAllocationNumber,
      socialSecretary: otherInformation.socialSecretary,
      bankAccountNumber: otherInformation.bankAccountNumber
    };
  }

  private static contactDataToJson(contactData: ContactData) {
    return {
      phone: contactData.phone,
      fax: contactData.fax,
      email: contactData.email,
      languageCode: contactData.languageCode
    };
  }

  fromJson(json: any): OperatingUnit {
    const operatingUnit: OperatingUnit = new OperatingUnit();
    let address: Address = new Address();
    let contactData: ContactData = new ContactData();
    let otherInformation: OtherInformation = new OtherInformation();

    operatingUnit.id = json.id;
    operatingUnit.operatingUnitNumber = json.operatingUnitNumber;
    operatingUnit.active = json.active;
    operatingUnit.name = json.name;
    operatingUnit.onssNumber = json.onssNumber;
    operatingUnit.vatNumber = json.vatNumber;
    operatingUnit.sectorId = json.sectorId;

    const jsonAddress = json.address;
    if (jsonAddress) {
      address = this.addressSerializer.fromJson(jsonAddress);
    }
    operatingUnit.address = address;

    const jsonContractData = json.contactData;
    if (jsonContractData) {
      contactData = OperatingUnitSerializer.contactDataFromJson(jsonContractData);
    }
    operatingUnit.contactData = contactData;

    const jsonOtherInformation = json.otherInformation;
    if (jsonOtherInformation) {
      otherInformation = OperatingUnitSerializer.otherInformationFromJson(jsonOtherInformation);
    }
    operatingUnit.otherInformation = otherInformation;

    return operatingUnit;
  }

  toJson(operatingUnit: OperatingUnit): any {
    return {
      id: operatingUnit.id,
      operatingUnitNumber: operatingUnit.operatingUnitNumber,
      active: operatingUnit.active,
      name: operatingUnit.name,
      onssNumber: operatingUnit.onssNumber,
      vatNumber: operatingUnit.vatNumber,
      sectorId: operatingUnit.sectorId,
      address: this.addressSerializer.toJson(operatingUnit.address),
      contactData: OperatingUnitSerializer.contactDataToJson(operatingUnit.contactData),
      otherInformation: OperatingUnitSerializer.otherInformationToJson(operatingUnit.otherInformation)
    };
  }
}
