import {DatabeInfo} from './databe/databe-info.model';
import {Address} from '../../../shared/model/address/address.model';
import {LanguageCode} from '../../../shared/enum/language-code.enum';

export enum Civility {
  MR = 'MR',
  MME = 'MME'
}

export enum BusinessArea {
  BILLING = 'BILLING',
  CONTRACT = 'CONTRACT',
  PRESTATION = 'PRESTATION',
  OTHER = 'OTHER',
  ASSESSMENT = 'ASSESSMENT'
}

export enum ContactMethod {
  MAIL = 'MAIL',
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
  PHONE = 'PHONE',
  FAX = 'FAX'
}

export enum VATCode {
  SUBJECT = 'SUBJECT',
  NOTSUBJECT = 'NOTSUBJECT'
}

export enum InvoicingType {
  EMAIL = 'EMAIL',
  MAIL = 'MAIL'
}

export enum InvoicingPeriodicity {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum CustomerSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  XL = 'XL',
  XXL = 'XXL',
  TRINITY = 'TRINITY',
  EXCEPTION = 'EXCEPTION'
}

export class Customer {
  id: number;
  customerNumber: number;
  operatingUnitId: number;
  enterpriseNumber: string;
  name: string;
  prefix: string;
  customerSize: CustomerSize;
  address: Address = new Address();
  subsidiaryAddresses: SubsidiaryAddress[];
  businessContact1: BusinessContact = new BusinessContact();
  businessContact2: BusinessContact = new BusinessContact();
  businessContact3: BusinessContact = new BusinessContact();
  businessContact4: BusinessContact = new BusinessContact();
  businessContact5: BusinessContact = new BusinessContact();
  billingData: BillingData = new BillingData();
  billingCoefficients: BillingCoefficients = new BillingCoefficients();
  paymentTerms: PaymentTerms = new PaymentTerms();
  uncomfortableWorkingHours: UncomfortableWorkingHours = new UncomfortableWorkingHours();
  legalData: LegalData = new LegalData();
  commercialFollowup: CommercialFollowup = new CommercialFollowup();
  countPendingRequest?: number;
  active = true;

  mapDatabeInfo(databeInfo: DatabeInfo) {
    this.enterpriseNumber = databeInfo.vat_clean;
    this.name = databeInfo.company_name;
    if (databeInfo.juridical_form) {
      this.prefix = databeInfo.juridical_form.code;
    }
    if (databeInfo.addresses[0]) {
      this.address.street = databeInfo.addresses[0].street;
      this.address.number = databeInfo.addresses[0].house_number;
      this.address.box = databeInfo.addresses[0].box;
      this.address.city = databeInfo.addresses[0].municipality;
      this.address.zip = databeInfo.addresses[0].zip_code;
      this.address.country = databeInfo.addresses[0].country_code;
      this.billingData.billingAddress.street = databeInfo.addresses[0].street;
      this.billingData.billingAddress.number = databeInfo.addresses[0].house_number;
      this.billingData.billingAddress.box = databeInfo.addresses[0].box;
      this.billingData.billingAddress.zip = databeInfo.addresses[0].zip_code;
      this.billingData.billingAddress.city = databeInfo.addresses[0].municipality;
      this.billingData.billingAddress.country = databeInfo.addresses[0].country_code;
    }
    this.billingData.vatNumber = databeInfo.vat_formatted;
  }
}

export class BusinessContact {
  function: string;
  name: string;
  email: string;
  phone: string;
  fax: string;
  mobile: string;
  languageCode: LanguageCode = LanguageCode.EN;
  contactMethod: ContactMethod;
  civility: Civility;
  businessArea: BusinessArea;
}

export class BillingData {
  vatCode: VATCode;
  jointCommissionCode: string;
  onssNumber: string;
  vatNumber: string;
  iban: string;
  bic: string;
  billingAddress: Address = new Address();
  billingEmail: string;
  multiSiteBilling = false;
  invoicingType: InvoicingType;
  socialCharter: string;
  specificScale: string;
  seniorityTaken: boolean;
}

export class SubsidiaryAddress extends Address {
  name: string;
  id: number;

  constructor() {
    super();
  }

  fromPrincipal(customer: Customer): SubsidiaryAddress {
    this.name = customer.name;
    const address = customer.address;
    this.street = address.street;
    this.number = address.number;
    this.box = address.box;
    this.zip = address.zip;
    this.city = address.city;
    this.country = address.country;
    return this;
  }
}

export class PaymentTerms {
  mealFacial: number;
  mealEmployer: number;
  dimonaFee: number;
  travelExpenses: number;
  coefficientClothes: number;
  coefficientPension: number;
  coefficientPurchasing: number;
  coefficientEcocheques: number;
  mealVoucher: number;
  travelExpenseBillingCoefficient: number;
  dimonaBillingCoefficient: number;
  clothesBillingCoefficient: number;
  purchasingBillingCoefficient: number;
  ecochequesBillingCoefficient: number;
  pensionBillingCoefficient: number;
  flexyRate: number;
}

export class UncomfortableWorkingHours {
  late: number;
  night: number;
  saturday: number;
  sunday: number;
  cutSchedule: number;
  publicHoliday: number;
}

export class LegalData {
  unionDelegation: boolean;
  socialReportStart: number;
  socialReportEnd: number;
  invoicingPeriodicity: InvoicingPeriodicity;
  paymentDelay: number;
  weeklyActivity: number;
  successiveContracts: boolean;
  holidayClosing: boolean;
  naceCodeId: number;
  costCenter: [] = [];
}

export class CommercialFollowup {
  comment: string;
}

export class BillingCoefficients {
    id: number;
    active: boolean;
    customerSize: string;
    employeePayroll : number;
    employeeSelection : number;
    extraPayroll: number;
    extraSelection: number;
    flexiPayroll: number;
    flexiSelection: number;
    studentPayrollNotSubmitted: number;
    studentPayrollSubmitted: number;
    studentSelectionNotSubmitted: number;
    studentSelectionSubmitted: number;
    workerPayroll: number;
    workerSelection: number;
}
