import {Component, Input, OnInit} from '@angular/core';
import {BusinessArea, Civility, ContactMethod} from '../../../model/customer.model';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Language} from '../../../../../shared/model/language/language.model';
import {FacadeService} from '../../../../../shared/service/facade/facade.service';

@Component({
  selector: 'app-business-contact',
  templateUrl: './business-contact.component.html',
  styleUrls: ['./business-contact.component.css']
})
export class BusinessContactComponent implements OnInit {
  @Input() form: AbstractControl<any>;
  businessAreas = Object.keys(BusinessArea);
  civilities = Object.keys(Civility);
  contactMethods = Object.keys(ContactMethod);
  languages: Language[] = [];

  constructor(private facadeService: FacadeService) {
  }

  get controlsAsFormGroup(): FormGroup {
    return this.form as FormGroup;
  }

  ngOnInit() {
    this.getLanguages();
  }

  getLanguages() {
    this.facadeService.getLanguages().subscribe({
      next: l => {
        this.languages = l;
      },
      error: () => {
        // alert(this.translate.instant('customers.form.message.error.businessContact.language'));
      }
    });
  }
}
