import {OperatingUnit} from '../../../operating-units/shared/model/operating-unit.model';
import {Customer} from '../../../customers/shared/model/customer.model';
import {AggregatedInvoiceLine} from './aggregated-invoice-line.model';
import {Worker} from '../../../workers/shared/model/worker.model';

export class Invoice {
  id: number;
  invoiceNumber: string;
  totalVatExcluded: number;
  totalVatIncluded: number;
  dueDate: Date;
  invoicingDate: Date;
  structuredCommunication: string;
  costCenter: string;
  customer: Customer;
  operatingUnit: OperatingUnit;
  aggregatedInvoiceLines: AggregatedInvoiceLine[] = [];
  workerGroups: WorkerGroup[] = [];

  // Only in pages
  totalVATExcluded: number;
}

export class WorkerGroup {
  worker: Worker;
  totalToBePaid: number;
  weekGroups: WeekGroup[] = [];
  codeGroups: CodeGroup[] = [];
}

export class WeekGroup {
  weekNumber: number;
  startDate: Date;
  endDate: Date;
  dayGroups: DayGroup[] = [];
  totalVATIncluded: number;
}

export class CodeGroup {
  code: string;
  labelEN: string;
  labelFR: string;
  labelNL: string;
  total: number;
  quantity: number;
}

export class DayGroup {
  startDate: Date;
  periodCodes: InvoicePeriod[] = [];
}

export class InvoicePeriod {
  value: number;
  quantity: number;
  totalVATIncluded: number;
  code: string;
  labelEN: string;
  labelFR: string;
  labelNL: string;
  vatRate: number;
}
