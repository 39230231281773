import {Serializer} from '../../../shared/model/serializer';
import {Period, TimePeriod} from './request.model';
import {DateSerializer} from '../../../shared/model/date.serializer';

export class PeriodSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): Period {
    const period = new Period();
    let timePeriod = new TimePeriod();

    period.id = json.id;
    period.startDate = this.dateSerializer.fromJson(json.startDate);
    period.endDate = this.dateSerializer.fromJson(json.endDate);
    period.description = json.description;
    period.active = json.active;
    period.breakDuration = this.dateSerializer.hoursFromJson(json.pauseTime);

    const jsonPeriodTimePeriod = json.timePeriod;
    if (jsonPeriodTimePeriod) {
      timePeriod = this.timePeriodFromJson(jsonPeriodTimePeriod);
    }
    period.timePeriod = timePeriod;

    return period;
  }

  toJson(period: Period): any {
    return {
      id: period.id,
      startDate: this.dateSerializer.dateWithTimeToJson(period.startDate),
      endDate: this.dateSerializer.dateWithTimeToJson(period.endDate),
      pauseTime: this.dateSerializer.hoursToJson(period.breakDuration),
      description: period.description,
      active: period.active,
      timePeriod: null
    };
  }

  private timePeriodFromJson(jsonPeriodTimePeriod: any): TimePeriod {
    const timePeriod = new TimePeriod();

    timePeriod.name = jsonPeriodTimePeriod.name;
    timePeriod.startTime = this.dateSerializer.fromJson(jsonPeriodTimePeriod.startTime);
    timePeriod.endTime = this.dateSerializer.fromJson(jsonPeriodTimePeriod.endTime);
    timePeriod.active = jsonPeriodTimePeriod.active;

    return timePeriod;
  }

}
