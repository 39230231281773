import {Serializer} from '../serializer';
import {DimonaCodeNotis} from './dimona-code-notis.model';

export class DimonaCodeNotisSerializer implements Serializer {

  fromJson(json: any): DimonaCodeNotis {
    const dimonaCodeNotis: DimonaCodeNotis = new DimonaCodeNotis();

    dimonaCodeNotis.id = json.id;
    dimonaCodeNotis.active = json.active;
    dimonaCodeNotis.code = json.code;
    dimonaCodeNotis.labelFR = json.labelFR;
    dimonaCodeNotis.labelEN = json.labelEN;
    dimonaCodeNotis.labelNL = json.labelNL;

    return dimonaCodeNotis;
  }

  toJson(dimonaCodeNotis: DimonaCodeNotis): any {
    return {
      id: dimonaCodeNotis.id,
      active: dimonaCodeNotis.active,
      code: dimonaCodeNotis.code,
      labelFR: dimonaCodeNotis.labelFR,
      labelEN: dimonaCodeNotis.labelEN,
      labelNL: dimonaCodeNotis.labelNL
    };
  }
}
