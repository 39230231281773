import {Component, Inject} from '@angular/core';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-validation-correction-dialog',
  templateUrl: './validation-correction-dialog.component.html',
  styleUrls: ['./validation-correction-dialog.component.css']
})
export class ValidationCorrectionDialogComponent {

  constructor(public dialogRef: MatDialogRef<ValidationCorrectionDialogComponent>,
              private facadeService: FacadeService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private translate: TranslateService) {
  }

  validatePrestationPeriod(form: NgForm) {
    if (!form.valid) {
      return alert(this.translate.instant('prestations.correction.validation.message.error.save'));
    }

    const correctionTypeId = form.value.correctionType;
    this.dialogRef.close();
  }
}
