import {Serializer} from 'src/app/shared/model/serializer';
import {JointCommission} from './joint-commission.model';
import {DatePipe} from '@angular/common';

export class JointCommissionSerializer implements Serializer {
  private datePipe: DatePipe = new DatePipe('en');

  fromJson(json: any): JointCommission {
    const joinCommission: JointCommission = new JointCommission();

    joinCommission.id = json.id;
    joinCommission.active = json.active;
    joinCommission.code = json.code;
    joinCommission.nameDE = json.nameDE;
    joinCommission.nameEN = json.nameEN;
    joinCommission.nameFR = json.nameFR;
    joinCommission.nameNL = json.nameNL;
    joinCommission.starred = json.starred;
    joinCommission.validityEndDate = (json.validityEndDate) ? new Date(json.validityEndDate) : null;
    joinCommission.validityStartDate = (json.validityStartDate) ? new Date(json.validityStartDate) : null;

    return joinCommission;
  }

  toJson(jointCommission: JointCommission): any {
    return {
      id: jointCommission.id,
      active: jointCommission.active,
      code: jointCommission.code,
      nameDE: jointCommission.nameDE,
      nameEN: jointCommission.nameEN,
      nameFR: jointCommission.nameFR,
      nameNL: jointCommission.nameNL,
      starred: jointCommission.starred,
      validityEndDate: (jointCommission.validityEndDate) ? this.datePipe.transform(jointCommission.validityEndDate, 'yyyy-MM-dd') : null,
      validityStartDate: (jointCommission.validityEndDate) ? this.datePipe.transform(jointCommission.validityEndDate, 'yyyy-MM-dd') : null
    };
  }
}
