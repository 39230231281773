import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgForm} from '@angular/forms';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {PrestationCode} from '../../../shared/model/prestation-code/prestation-code.model';
import {Prestation} from '../../shared/model/prestation.model';
import {PrestationPeriod} from '../../shared/model/prestation-period.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-prestation-reject-dialog',
  templateUrl: './prestation-reject-dialog.component.html',
  styleUrls: ['./prestation-reject-dialog.component.css']
})
export class PrestationRejectDialogComponent implements OnInit {
  prestationCodes: PrestationCode[] = [];
  prestation: Prestation;
  prestationPeriod: PrestationPeriod;

  constructor(private dialogRef: MatDialogRef<PrestationRejectDialogComponent>,
              private facadeService: FacadeService,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.prestation = data.prestation;
    this.prestationPeriod = data.prestationPeriod;
  }

  ngOnInit(): void {
    this.getPrestationCodes();
  }

  getTranslation(prestationCode: PrestationCode) {
    switch (this.translate.currentLang) {
      case 'fr':
        return prestationCode.labelFR;
      case 'nl':
        return prestationCode.labelNL;
      case 'de':
        return prestationCode.labelDE;
      case 'en':
        return prestationCode.labelEN;
      default:
        return prestationCode.labelEN;
    }
  }

  reject(form: NgForm) {
    if (form.invalid) {
      return alert('NOP');
    }
    this.prestationPeriod.prestation = this.prestation;
    this.prestationPeriod.description = form.controls.description.value;
    this.facadeService.rejectPrestationPeriod(this.prestationPeriod, form.controls.justification.value)
      .subscribe({
        next:
          ignoreProperty => {
            return this.closeDialog(true);
          },
        error: ignoreProperty => {
          return alert(this.translate.instant('prestations.validation.dialog.message.error.reject'));
        }
      });
  }

  closeDialog(updated = false): void {
    this.dialogRef.close(updated);
  }

  private getPrestationCodes() {
    this.facadeService.getPrestationCodeJustification()
      .subscribe(
        prestationCodes => {
          this.prestationCodes = prestationCodes;
        },
      );
  }

}
