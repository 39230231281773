import {Serializer} from 'src/app/shared/model/serializer';
import {Period, Request} from 'src/app/requests/shared/model/request.model';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {PeriodSerializer} from './period.serializer';

export class RequestSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();
  private periodSerializer: PeriodSerializer = new PeriodSerializer();

  fromJson(json: any): any {
    const request: Request = new Request();
    const periods: Period[] = [];

    request.id = json.id;
    request.operatingUnitId = json.operatingUnitId;
    request.customerId = json.customerId;
    request.customerName = json.customerName;
    request.workerId = json.workerId;
    request.workerFirstName = json.workerFirstName;
    request.workerLastName = json.workerLastName;
    request.customerAddress = json.customerAddress;
    request.startDate = this.dateSerializer.fromJson(json.startDate);
    request.endDate = this.dateSerializer.fromJson(json.endDate);
    request.status = json.status;
    request.workerType = json.workerType;
    request.contractType = json.contractType;
    request.workFunctionId = json.workFunctionId;
    request.workFunction = json.workFunction;
    request.costCenter = json.costCenter;
    request.flexyRate = json.flexyRate;
    request.description = json.description;
    request.active = json.active;
    request.dimonaPending = json.dimonaPending ?? false;
    request.dimonaOk = json.dimonaOk;
    request.inError = json.inError;
    request.toBePaid = json.toBePaid;
    request.paid = json.paid;
    request.validated = json.validated;

    const jsonPeriods = json.periods;
    if (jsonPeriods) {
      jsonPeriods.forEach(
        jsonPeriod => {
          periods.push(this.periodSerializer.fromJson(jsonPeriod));
        });
    }
    request.periods = periods;
    
    // Check if request's' first period's start date is < new Date() so that it can still be deleted //
    const sortedPeriods = periods.sort((a,b) => a.startDate.getTime() - b.startDate.getTime());
    const firstStartDate = sortedPeriods[0]?.startDate;

    if (firstStartDate < new Date()){
      request.prestationStarted = true;
    } else {
      request.prestationStarted = false;
    }

    // request.prestationStarted = request.startDate <= new Date();
    return request;
  }

  toJson(request: Request) {
    const periods: any[] = [];
    request.periods.forEach(
      period => {
        periods.push(this.periodSerializer.toJson(period));
      }
    );

    return {
      id: request.id,
      operatingUnitId: request.operatingUnitId,
      customerId: request.customerId,
      workerId: request.workerId,
      workerFirstName: request.workerFirstName,
      workerLastName: request.workerLastName,
      customerName: request.customerName,
      customerAddress: request.customerAddress,
      startDate: this.dateSerializer.toJson(request.startDate),
      endDate: this.dateSerializer.toJson(request.endDate),
      status: request.status,
      workerType: request.workerType,
      contractType: request.contractType,
      workFunctionId: request.workFunctionId,
      costCenter: request.costCenter,
      flexyRate: request.flexyRate,
      description: request.description,
      active: request.active,
      periods: periods
    };
  }
}
