import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {Contract} from '../../../contracts/shared/model/contract.model';

@Injectable()
export class ContractResolver implements Resolve<Observable<Contract>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Contract> {
    const contractId = route.queryParams['c'];
    return this.facadeService.getContractById(+contractId).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
