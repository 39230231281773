import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {PrestationSerializer} from '../model/prestation.serializer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Prestation} from '../model/prestation.model';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {PrestationPage} from '../../../shared/model/pagination/prestation/prestation-page.model';
import {PrestationPageSerializer} from '../../../shared/model/pagination/prestation/prestation-page.serializer';

@Injectable({
  providedIn: 'root'
})
export class PrestationService {

  private serviceURL = environment.prestation_service_URL + 'prestations';
  private serviceURLExport = environment.prestation_service_URL + 'export';
  private prestationSerializer: PrestationSerializer = new PrestationSerializer();
  private prestationPageSerializer: PrestationPageSerializer = new PrestationPageSerializer();

  private dateSerializer: DateSerializer = new DateSerializer();

  constructor(private http: HttpClient) {
  }

  get(id: number): Observable<any> {
    return this.http.get<Prestation>(this.serviceURL + '/' + id).pipe(
      map((data) => this.prestationSerializer.fromJson(data))
    );
  }

  list(): Observable<any> {
    return this.http.get<Prestation[]>(this.serviceURL).pipe(
      map((data) => this.convertData(data))
    );
  }

  save(prestation: Prestation): Observable<any> {
    return this.http.post<Prestation>(this.serviceURL, this.prestationSerializer.toJson(prestation)).pipe(
      map((data) => this.prestationSerializer.fromJson(data))
    );
  }

  validate(prestation: Prestation): Observable<any> {
    return this.http.post<Prestation>(this.serviceURL + `/${prestation.id}/validate`, this.prestationSerializer.toJson(prestation)).pipe(
      map((data) => this.prestationSerializer.fromJson(data))
    );
  }

  sendReminder() {
    return this.http.post<Prestation>(this.serviceURL + '/send-reminder', null);
  }

  listPageToValidate(index: number, size: number, requestId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));

    if (requestId) {
      params = params.append('requestId', String(requestId));
    }

    return this.http.get<PrestationPage>(this.serviceURL + '/page-to-validate', {params}).pipe(
      map((data: PrestationPage) => this.prestationPageSerializer.fromJson(data)));
  }

  listPageReleased(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<PrestationPage>(this.serviceURL + '/page-released', {params}).pipe(
      map((data: PrestationPage) => this.prestationPageSerializer.fromJson(data))
    );
  }

  listPageToRelease(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<PrestationPage>(this.serviceURL + '/page-not-released', {params}).pipe(
      map((data: PrestationPage) => this.prestationPageSerializer.fromJson(data))
    );
  }

  findIndexToRelease(id: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', String(id));
    params = params.append('size', String(size));
    return this.http.get<number>(this.serviceURL + '/index-not-released', {params});
  }

  filterByRequestId(requestId: number): Observable<any> {
    let params = new HttpParams();
    if (requestId) {
      params = params.append('requestId', String(requestId));
    }
    return this.http.get<Prestation[]>(this.serviceURL + '/filter', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  filter(startDate: Date,
         endDate: Date,
         workerId: number): Observable<any> {

    let params = this.getBasicFilter(startDate, endDate, workerId);
    params = params.append('active', String(true));

    return this.http.get<Prestation[]>(this.serviceURL + '/filter', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  getPrestationCorrectionFromRequest(requestId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('requestId', String(requestId));
    return this.http.get<Prestation[]>(this.serviceURL + '/filter-correction', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  filterForCorrection(id: number,
                      customerId: number,
                      startDate: Date,
                      endDate: Date,
                      workerId: number,
                      billingDate: Date,
                      dueDate: Date,
                      processingDate: Date,
                      billNumber: string): Observable<any> {

    let params = this.getBasicFilter(startDate, endDate, workerId);

    if (id) {
      params = params.append('id', String(id));
    }
    if (customerId) {
      params = params.append('customerId', String(customerId));
    }
    if (billingDate) {
      params = params.append('billingDate', this.dateSerializer.toJson(billingDate));
    }
    if (dueDate) {
      params = params.append('dueDate', this.dateSerializer.toJson(dueDate));
    }
    if (processingDate) {
      params = params.append('processingDate', this.dateSerializer.toJson(processingDate));
    }
    if (billNumber) {
      params = params.append('billNumber', String(billNumber));
    }

    return this.http.get<Prestation[]>(this.serviceURL + '/filter-correction', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  // Old Export Prestation //
  exportPrestationCsv(startDate: Date, endDate: Date, operationUnitIds: Array<number>, customerId: number, workerId: number): Observable<any> {

    const dateSerializer = new DateSerializer();
    const params = new HttpParams({
      fromObject: {
        operationUnitIds: String(operationUnitIds),
        ...workerId ? {workerId: workerId} : {},
        ...customerId ? {customerId: customerId} : {},
        ...startDate ? {startDate: dateSerializer.toJson(startDate)} : {},
        ...endDate ? {endDate: dateSerializer.toJson(endDate)} : {}
      }
    });

    return this.http.get(
      this.serviceURL + '/export',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  // New Export Prestation (November 2023) //
  exportPrestationNewCsv(operatingUnitIds: Array<number>, customerId: number, workerId: number, fromDate: Date, toDate: Date): Observable<any> {

    const dateSerializer = new DateSerializer();
    const params = new HttpParams({
      fromObject: {
        operatingUnitIds: String(operatingUnitIds),
        ...workerId ? {workerId: workerId} : {},
        ...customerId ? {customerId: customerId} : {},
        ...fromDate ? {fromDate: dateSerializer.toJson(fromDate)} : {},
        ...toDate ? {toDate: dateSerializer.toJson(toDate)} : {}
      }
    });

    return this.http.get(
      this.serviceURLExport + '/export-general',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  // Export Code Prestation //
  exportCodePrestationCsv(fromDate: Date, toDate: Date, operatingUnitIds: Array<number>, customerId: number, workerId: number): Observable<any> {

    const dateSerializer = new DateSerializer();
    const params = new HttpParams({
      fromObject: {
        operatingUnitIds: String(operatingUnitIds),
        ...workerId ? {workerId: workerId} : {},
        ...customerId ? {customerId: customerId} : {},
        ...fromDate ? {fromDate: dateSerializer.toJson(fromDate)} : {},
        ...toDate ? {toDate: dateSerializer.toJson(toDate)} : {}
      }
    });

    return this.http.get(
      this.serviceURLExport + '/export-details',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  releasePayroll(): Observable<any> {
    return this.http.post(this.serviceURL + '/payroll/release', null);
  }

  releasePayrollByContract(): Observable<any> {
    return this.http.post(this.serviceURL + '/payroll/release/contract', null);
  }

  exportActivityReport(startDate: Date, endDate: Date, operationUnitIds: Array<number>): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        operationUnitIds: String(operationUnitIds),
        ...startDate ? {startDate: this.dateSerializer.toJson(startDate)} : {},
        ...endDate ? {endDate: this.dateSerializer.toJson(endDate)} : {}
      }
    });
    return this.http.get(
      this.serviceURL + '/export-activity-report',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  exportInfoDutyReport(startDate: Date, endDate: Date): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        ...startDate ? {startDate: this.dateSerializer.toJson(startDate)} : {},
        ...endDate ? {endDate: this.dateSerializer.toJson(endDate)} : {}
      }
    });
    return this.http.get(
      this.serviceURL + '/export-info-duty-report',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  private getBasicFilter(startDate: Date,
                         endDate: Date,
                         workerId: number): HttpParams {
    let params = new HttpParams();

    if (workerId) {
      params = params.append('workerId', String(workerId));
    }
    if (startDate) {
      params = params.append('from', this.dateSerializer.toJson(startDate));
    }
    if (endDate) {
      params = params.append('to', this.dateSerializer.toJson(endDate.setDate(endDate.getDate() + 1)));
    }

    return params;
  }

  private convertData(data: any): Prestation[] {
    return data.map(prestation => this.prestationSerializer.fromJson(prestation));
  }
}
