import {Serializer} from '../../serializer';
import { StudentQuotaHoursSerializer } from '../../../../admin/model/student-quota-hours.serializer';
import { StudentQuotaHoursPage } from './student-quota-hours-page.model';

export class StudentQuotaHoursPageSerializer implements Serializer {

  private studentQuotaHoursSerializer = new StudentQuotaHoursSerializer();

  fromJson(json: any): StudentQuotaHoursPage {
    const studentQuotaHoursPage = new StudentQuotaHoursPage;

    json.content.forEach(
      content => {
        studentQuotaHoursPage.content.push(this.studentQuotaHoursSerializer.fromJson(content));
      }
    );

    studentQuotaHoursPage.totalElements = json.totalElements;
    studentQuotaHoursPage.totalPages = json.totalPages;

    return studentQuotaHoursPage;
  }

  toJson(resource: Object): any {
  }
}
