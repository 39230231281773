import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Request} from '../../model/request.model';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {FacadeService} from '../../../../shared/service/facade/facade.service';
import {AuthorizationService} from '../../../../login/shared/service/authorization.service';
import {Role} from '../../../../users/shared/model/user.model';
import {RequestPage} from '../../../../shared/model/pagination/request/request-page.model';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-pending-listing-requests',
  templateUrl: './pending-listing-requests.component.html',
  styleUrls: ['./pending-listing-requests.component.css']
})
export class PendingListingRequestsComponent implements OnInit {

  displayedColumns: string[] = ['statusColor', 'requestId', 'customerId', 'workerId', 'startDate',
    'endDate', 'status', 'dimona', 'validated', 'paid'];
  dataSource = new MatTableDataSource<Request>();
  @ViewChild('pendingRequestPaginator', {static: true}) paginator: MatPaginator;
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;
  originalParams: Params;
  @Input() customerId: number;

  constructor(private facadeService: FacadeService,
              private authorizationService: AuthorizationService,
              public route: ActivatedRoute,
              private router: Router) {
  }

  @Input() set requests(r: RequestPage) {
    this.length = r.totalElements;
    this.dataSource = new MatTableDataSource<Request>(r.content);
    this.dataSource.paginator = this.paginator;
  }

  @Input() set index(index: number) {
    this.pageIndex = index;
  }

  @Input() set size(size: number) {
    this.pageSize = size;
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  ngOnInit() {
    this.originalParams = {
      ...this.route.snapshot.queryParams
    };
  }

  public getEventRequestData(event?: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const params: Params = {
      ...this.originalParams,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize
    };
    this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: params
      }
    );
    this.facadeService.getPagePendingRequests(event.pageIndex, event.pageSize, {customerId: this.customerId}).subscribe(
      (requests: RequestPage) => {
        this.dataSource.data = requests.content;
      }
    );
    return event;
  }
}
