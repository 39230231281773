import {Serializer} from 'src/app/shared/model/serializer';
import {BankData, WorkerStatus} from 'src/app/workers/shared/model/worker.model';
import {AddressSerializer} from '../../../shared/model/address/address.serializer';
import {Address} from '../../../shared/model/address/address.model';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {WorkerLite} from './worker-lite.model';

export class WorkerLiteSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();
  private addressSerializer: AddressSerializer = new AddressSerializer();

  private static jsonToBankDate(jsonBankData: any): BankData {
    const bankData: BankData = new BankData();

    bankData.bic = jsonBankData.bic;
    bankData.iban = jsonBankData.iban;

    return bankData;
  }

  private static bankDataToJson(bankData: BankData) {
    return {
      bic: bankData.bic,
      iban: bankData.iban,
    };
  }

  private static workerStatusToJson(workerStatus: WorkerStatus) {
    return {
      seniority: workerStatus.seniority,
      nationality: workerStatus.nationality,
    };
  }

  private static jsonToWorkerStatus(jsonWorkerStatus: any): WorkerStatus {
    const workerStatus: WorkerStatus = new WorkerStatus();

    workerStatus.seniority = jsonWorkerStatus.seniority;
    workerStatus.nationality = jsonWorkerStatus.nationality;

    return workerStatus;
  }

  fromJson(json: any): any {
    const worker: WorkerLite = new WorkerLite();
    let address = new Address();
    let workerStatus = new WorkerStatus();
    let bankData = new BankData();

    worker.id = json.id;
    worker.firstName = json.firstName;
    worker.lastName = json.lastName;
    worker.gender = json.gender;
    worker.mobile = json.mobile;
    worker.email = json.email;
    worker.dateOfBirth = this.dateSerializer.fromJson(json.dateOfBirth);
    worker.placeOfBirth = json.placeOfBirth;
    worker.languageCode = json.languageCode;
    worker.active = json.active;

    const jsonAddress = json.address;
    if (jsonAddress) {
      address = this.addressSerializer.fromJson(jsonAddress);
    }
    worker.address = address;

    const jsonWorkerStatus = json.workerStatus;
    if (jsonWorkerStatus) {
      workerStatus = WorkerLiteSerializer.jsonToWorkerStatus(jsonWorkerStatus);
    }
    worker.workerStatus = workerStatus;

    const jsonBankData = json.bankData;
    if (jsonBankData) {
      bankData = WorkerLiteSerializer.jsonToBankDate(jsonBankData);
    }
    worker.bankData = bankData;

    return worker;
  }

  toJson(worker: WorkerLite): any {
    return {
      id: worker.id,
      firstName: worker.firstName,
      lastName: worker.lastName,
      gender: worker.gender,
      mobile: worker.mobile,
      email: worker.email,
      dateOfBirth: this.dateSerializer.toJson(worker.dateOfBirth),
      placeOfBirth: worker.placeOfBirth,
      languageCode: worker.languageCode,
      active: worker.active,
      address: this.addressSerializer.toJson(worker.address),
      workerStatus: WorkerLiteSerializer.workerStatusToJson(worker.workerStatus),
      bankData: WorkerLiteSerializer.bankDataToJson(worker.bankData),
    };
  }
}
