import {Serializer} from '../serializer';
import {PrestationCode} from './prestation-code.model';

export class PrestationCodeSerializer implements Serializer {
  fromJson(json: any): PrestationCode {
    const prestationCode: PrestationCode = new PrestationCode();

    prestationCode.id = json.id;
    prestationCode.code = json.code;
    prestationCode.labelFR = json.labelFR;
    prestationCode.labelNL = json.labelNL;
    prestationCode.labelEN = json.labelEN;
    prestationCode.labelDE = json.labelDE;
    prestationCode.prestationCodeType = json.prestationCodeType;
    prestationCode.processorClassName = json.processorClassName;
    prestationCode.processorArguments = json.processorArguments;
    prestationCode.ruleDescription = json.ruleDescription;
    prestationCode.daily = json.daily;
    prestationCode.hourly = json.hourly;
    prestationCode.totalBased = json.totalBased;
    prestationCode.prestation = json.prestation;
    prestationCode.justification = json.justification;
    prestationCode.prestationPercent = json.prestationPercent;
    prestationCode.billing = json.billing;
    prestationCode.billingPercent = json.billingPercent;
    prestationCode.validation = json.validation;
    prestationCode.correction = json.correction;
    prestationCode.ssCode = json.ssCode;
    prestationCode.ssCodeType = json.ssCodeType;
    prestationCode.account = json.account;
    prestationCode.accountingShortLabel = json.accountingShortLabel;
    prestationCode.accountingLabelFR = json.accountingLabelFR;
    prestationCode.jointCommissions = json.jointCommissions;
    prestationCode.active = json.active;

    return prestationCode;
  }

  toJson(prestationCode: PrestationCode): any {
    return {
      id: prestationCode.id,
      code: prestationCode.code,
      labelFR: prestationCode.labelFR,
      labelNL: prestationCode.labelNL,
      labelEN: prestationCode.labelEN,
      labelDE: prestationCode.labelDE,
      prestationCodeType: prestationCode.prestationCodeType,
      processorClassName: prestationCode.processorClassName,
      processorArguments: prestationCode.processorArguments,
      ruleDescription: prestationCode.ruleDescription,
      daily: prestationCode.daily,
      hourly: prestationCode.hourly,
      prestation: prestationCode.prestation,
      justification: prestationCode.justification,
      prestationPercent: prestationCode.prestationPercent,
      billing: prestationCode.billing,
      billingPercent: prestationCode.billingPercent,
      validation: prestationCode.validation,
      correction: prestationCode.correction,
      ssCode: prestationCode.ssCode,
      ssCodeType: prestationCode.ssCodeType,
      account: prestationCode.account,
      accountingShortLabel: prestationCode.accountingShortLabel,
      accountingLabelFR: prestationCode.accountingLabelFR,
      jointCommissions: prestationCode.jointCommissions,
      active: prestationCode.active
    };
  }
}
