import {Serializer} from '../../../shared/model/serializer';
import {CodeGroup, DayGroup, Invoice, InvoicePeriod, WeekGroup, WorkerGroup} from './invoice.model';
import {OperatingUnit} from '../../../operating-units/shared/model/operating-unit.model';
import {Customer} from '../../../customers/shared/model/customer.model';
import {OperatingUnitSerializer} from '../../../operating-units/shared/model/operating-unit.serializer';
import {CustomerSerializer} from '../../../customers/shared/model/customer.serializer';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {AggregatedInvoiceLine} from './aggregated-invoice-line.model';
import {AggregatedInvoiceLineSerializer} from './aggregated-invoice-line.serializer';
import {Worker} from '../../../workers/shared/model/worker.model';
import {WorkerSerializer} from '../../../workers/shared/model/woker.serializer';

export class InvoiceSerializer implements Serializer {
  private operatingUnitSerializer: OperatingUnitSerializer = new OperatingUnitSerializer();
  private customerSerializer: CustomerSerializer = new CustomerSerializer();
  private aggregatedInvoiceLineSerializer: AggregatedInvoiceLineSerializer = new AggregatedInvoiceLineSerializer();
  private workerGroupSerializer: WorkerGroupSerializer = new WorkerGroupSerializer();
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): Invoice {
    const invoice: Invoice = new Invoice();
    const aggregatedInvoiceLines: AggregatedInvoiceLine[] = [];
    const workerGroups: WorkerGroup[] = [];
    let operatingUnit: OperatingUnit = new OperatingUnit();
    let customer: Customer = new Customer();

    const jsonOperatingUnit = json.operatingUnit;
    if (jsonOperatingUnit) {
      operatingUnit = this.operatingUnitSerializer.fromJson(jsonOperatingUnit);
    }

    const jsonCustomer = json.customer;
    if (jsonCustomer) {
      customer = this.customerSerializer.fromJson(jsonCustomer);
    }

    const jsonAggregatedInvoiceLines = json.aggregatedInvoiceLines;
    if (jsonAggregatedInvoiceLines) {
      jsonAggregatedInvoiceLines.forEach(
        aggregatedInvoiceLine => {
          aggregatedInvoiceLines.push(this.aggregatedInvoiceLineSerializer.fromJson(aggregatedInvoiceLine));
        });
    }

    const jsonWorkerGroups = json.workerGroups;
    if (jsonWorkerGroups) {
      jsonWorkerGroups.forEach(
        workerGroup => {
          workerGroups.push(this.workerGroupSerializer.fromJson(workerGroup));
        });
    }

    invoice.id = json.id;
    invoice.invoiceNumber = json.invoiceNumber;
    invoice.totalVatExcluded = json.totalVatExcluded;
    invoice.totalVatIncluded = json.totalVatIncluded;
    invoice.invoicingDate = this.dateSerializer.fromJson(json.invoicingDate);
    invoice.dueDate = this.dateSerializer.fromJson(json.dueDate);
    invoice.structuredCommunication = json.structuredCommunication;
    invoice.costCenter = json.costCenter;

    invoice.customer = customer;
    invoice.operatingUnit = operatingUnit;
    invoice.aggregatedInvoiceLines = aggregatedInvoiceLines;
    invoice.workerGroups = workerGroups;

    invoice.totalVATExcluded = json.totalVATExcluded;

    return invoice;
  }

  toJson(invoice: Invoice): any {}
}

export class WorkerGroupSerializer implements Serializer {

  private workerSerializer: WorkerSerializer = new WorkerSerializer();
  private weekGroupSerializer: WeekGroupSerializer = new WeekGroupSerializer();
  private codeGroupSerializer: CodeGroupSerializer = new CodeGroupSerializer();

  fromJson(json: any): WorkerGroup {
    const workerGroup: WorkerGroup = new WorkerGroup();

    workerGroup.totalToBePaid = json.totalToBePaid;

    let worker: Worker = new Worker();
    const jsonWorker = json.worker;
    if (jsonWorker) {
      worker = this.workerSerializer.fromJson(jsonWorker);
    }
    workerGroup.worker = worker;

    const weekGroups: WeekGroup[] = [];
    json.weekGroups.forEach(
      weekGroup => {
        weekGroups.push(this.weekGroupSerializer.fromJson(weekGroup));
      });
    workerGroup.weekGroups = weekGroups;

    const codeGroups: CodeGroup[] = [];
    json.codeGroups.forEach(
      codeGroup => {
        codeGroups.push(this.codeGroupSerializer.fromJson(codeGroup));
      });
    workerGroup.codeGroups = codeGroups;

    return workerGroup;
  }

  toJson(resource: Object): any {}
}

export class WeekGroupSerializer implements Serializer {

  private dateSerializer: DateSerializer = new DateSerializer();
  private dayGroupSerializer: DayGroupSerializer = new DayGroupSerializer();

  fromJson(json: any): WeekGroup {
    const weekGroup: WeekGroup = new WeekGroup();

    weekGroup.weekNumber = json.weekNumber;
    weekGroup.startDate = this.dateSerializer.fromJson(json.startDate);
    weekGroup.endDate = this.dateSerializer.fromJson(json.endDate);
    weekGroup.totalVATIncluded = json.totalVATIncluded;

    const dayGroups: DayGroup[] = [];
    json.dayGroups.forEach(
      dayGroup => {
        dayGroups.push(this.dayGroupSerializer.fromJson(dayGroup));
      });
    weekGroup.dayGroups = dayGroups;

    return weekGroup;
  }

  toJson(resource: Object): any {}
}

export class CodeGroupSerializer implements Serializer {

  fromJson(json: any): CodeGroup {
    const codeGroup: CodeGroup = new CodeGroup();
    codeGroup.code = json.code;
    codeGroup.labelEN = json.labelEN;
    codeGroup.labelFR = json.labelFR;
    codeGroup.labelNL = json.labelNL;
    codeGroup.quantity = json.quantity;
    codeGroup.total = json.total;
    return codeGroup;
  }

  toJson(resource: Object): any {}
}

export class DayGroupSerializer implements Serializer {

  private dateSerializer: DateSerializer = new DateSerializer();
  private invoicePeriodSerializer: InvoicePeriodSerializer = new InvoicePeriodSerializer();

  fromJson(json: any): DayGroup {
    const dayGroup: DayGroup = new DayGroup();

    dayGroup.startDate = this.dateSerializer.fromJson(json.startDate);

    const invoicePeriodGroups: InvoicePeriod[] = [];
    json.periodCodes.forEach(
      invoicePeriodGroup => {
        invoicePeriodGroups.push(this.invoicePeriodSerializer.fromJson(invoicePeriodGroup));
      });
    dayGroup.periodCodes = invoicePeriodGroups;

    return dayGroup;
  }

  toJson(resource: Object): any {}
}

export class InvoicePeriodSerializer implements Serializer {

  fromJson(json: any): InvoicePeriod {
    const invoicePeriod: InvoicePeriod = new InvoicePeriod();

    invoicePeriod.quantity = json.quantity;
    invoicePeriod.value = json.value;
    invoicePeriod.totalVATIncluded = json.totalVATIncluded;
    invoicePeriod.code = json.code;
    invoicePeriod.labelEN = json.labelEN;
    invoicePeriod.labelFR = json.labelFR;
    invoicePeriod.labelNL = json.labelNL;
    invoicePeriod.vatRate = json.vatRate;

    return invoicePeriod;
  }

  toJson(resource: Object): any {}
}
