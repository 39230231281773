import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {DocumentSerializer} from '../../model/document/document.serializer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Document, TypeFileCustomer, TypeFileWorker} from '../../model/document/document.model';
import {DateSerializer} from '../../model/date.serializer';

export enum DocumentType {
  PENDING = 'PENDING',
  DOCUMENTS = 'DOCUMENTS'
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private workerDocumentServiceUrl = environment.worker_service_URL + 'documents/';
  private customerDocumentServiceUrl = environment.customer_service_URL + 'documents/';

  private documentSerializer: DocumentSerializer = new DocumentSerializer();
  private dateSerializer: DateSerializer = new DateSerializer();

  constructor(private http: HttpClient) {
  }

  getDocumentsByWorkerId(workerId: number, pending: boolean = false): Observable<any> {

    let params = new HttpParams();
    params = params.append('workerId', workerId);

    let url = 'list-document';

    if (pending) {
      url = 'list-pending-document';
    }

    return this.http.get<Document[]>(this.workerDocumentServiceUrl + url, {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  downloadWorkerDocument(workerId: number, document: Document): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', document.contentType);

    let params = new HttpParams();
    params = params.append('workerId', workerId);
    params = params.append('fileType', document.typeFile);
    params = params.append('fileName', document.fileName);

    let url = this.workerDocumentServiceUrl + 'download/';

    switch (document.contentType) {
      case 'application/pdf': {
        url += 'pdf';
        break;
      }
      case 'image/png':
      case 'image/jpeg': {
        url += 'image';
        break;
      }
      default: {
        throw Error('Unsupported media type');
      }
    }

    return this.http.get(url, {params: params, headers: headers, responseType: 'blob'});
  }

  deleteWorkerDocument(workerId: number, document: Document): Observable<any> {
    let params = new HttpParams();
    params = params.append('workerId', workerId);
    params = params.append('fileType', document.typeFile);
    params = params.append('fileName', document.fileName);
    return this.http.delete(this.workerDocumentServiceUrl + 'delete-document', {params});
  }

  processDocumentByWorkerId(workerId: number,
                            document: Document): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');

    let params = new HttpParams();
    params = params.append('workerId', workerId);
    params = params.append('fileName', document.fileName);
    params = params.append('fileType', document.typeFile);
    params = params.append('consultant', document.consultant);
    params = params.append('comment', document.comment);
    params = params.append('startDate', this.dateSerializer.dateWithTimeSSSToJson(document.startDate));
    params = params.append('endDate', this.dateSerializer.dateWithTimeSSSToJson(document.endDate));

    return this.http.post(this.workerDocumentServiceUrl + 'process-document',
      null,
      {headers: headers, params: params, responseType: 'text'});
  }

  editWorkerDocumentFileType(workerId: number, oldFileType: TypeFileWorker | TypeFileCustomer, document: Document): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');

    let params = new HttpParams();
    params = params.append('workerId', workerId);
    params = params.append('consultant', document.consultant);
    params = params.append('fileName', document.fileName);
    params = params.append('oldFileType', oldFileType);
    params = params.append('newFileType', document.typeFile);
    if (document.startDate) {
      params = params.append('startDate', this.dateSerializer.dateWithTimeSSSToJson(document.startDate));
    }
    if (document.endDate) {
      params = params.append('endDate', this.dateSerializer.dateWithTimeSSSToJson(document.endDate));
    }

    return this.http.post(
      this.workerDocumentServiceUrl + 'edit-filetype',
      null,
      {headers: headers, params: params, responseType: 'text'});
  }

  uploadDocumentByWorkerId(workerId: number, document: Document, file: File): Observable<any> {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('reportProgress', true.toString());
    formData.append('workerId', workerId.toString());
    formData.append('consultant', document.consultant);
    formData.append('fileType', document.typeFile);
    formData.append('comment', document.comment);

    if (document.startDate) {
      formData.append('startDate', this.dateSerializer.dateWithTimeSSSToJson(document.startDate));
    }

    if (document.endDate) {
      formData.append('endDate', this.dateSerializer.dateWithTimeSSSToJson(document.endDate));
    }

    return this.http.post(
      this.workerDocumentServiceUrl + 'upload-processed',
      formData, {responseType: 'text'});
  }


  // ----------------------- Customer -----------------------

  getDocumentsByCustomerId(customerId: number): Observable<any> {

    let params = new HttpParams();
    params = params.append('customerId', customerId);

    return this.http.get<Document[]>(this.customerDocumentServiceUrl + 'list-document', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  downloadCustomerDocument(customerId: number, document: Document): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', document.contentType);

    let params = new HttpParams();
    params = params.append('customerId', customerId);
    params = params.append('fileType', document.typeFile);
    params = params.append('fileName', document.fileName);

    let url = this.customerDocumentServiceUrl + 'download/';

    switch (document.contentType) {
      case 'application/pdf': {
        url += 'pdf';
        break;
      }
      case 'image/jpeg': {
        url += 'jpeg';
        break;
      }
      default: {
        throw Error('Unsupported media type');
      }
    }

    return this.http.get(url, {params: params, headers: headers, responseType: 'blob'});
  }

  uploadDocumentByCustomerId(customerId: number, document: Document, file: File): Observable<any> {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('reportProgress', true.toString());
    formData.append('customerId', customerId.toString());
    formData.append('consultant', document.consultant);
    formData.append('fileType', document.typeFile);
    formData.append('comment', document.comment);

    return this.http.post(
      this.customerDocumentServiceUrl + 'upload-processed',
      formData, {responseType: 'text'});
  }

  editCustomerDocumentFileType(customerId: number, oldFileType: TypeFileWorker | TypeFileCustomer, document: Document): Observable<any> {

    let params = new HttpParams();
    params = params.append('customerId', customerId);
    params = params.append('consultant', document.consultant);
    params = params.append('fileName', document.fileName);
    params = params.append('oldFileType', oldFileType);
    params = params.append('newFileType', document.typeFile);

    return this.http.post(
      this.customerDocumentServiceUrl + 'edit-filetype',
      null,
      {params: params, responseType: 'text'});
  }

  deleteCustomerDocument(customerId: number, document: Document): Observable<any> {
    let params = new HttpParams();
    params = params.append('customerId', customerId);
    params = params.append('fileType', document.typeFile);
    params = params.append('fileName', document.fileName);
    return this.http.delete(this.customerDocumentServiceUrl + 'delete-document', {params});
  }

  private convertData(data: any): Document[] {
    return data.map(document =>
      this.documentSerializer.fromJson(document));
  }
}
