import {Address} from '../../../shared/model/address/address.model';
import {LanguageCode} from '../../../shared/enum/language-code.enum';

export class OperatingUnit {
  id: number;
  operatingUnitNumber: number;
  name: string;
  vatNumber: string;
  onssNumber: string;
  sectorId: number;
  address: Address = new Address();
  contactData: ContactData = new ContactData();
  otherInformation: OtherInformation = new OtherInformation();
  active = true;
}

export class ContactData {
  phone: string;
  fax: string;
  email: string;
  languageCode: LanguageCode;
}

export class OtherInformation {
  walloonRegionalApproval: string;
  brusselsRegionalApproval: string;
  flemishRegionalApproval: string;
  legalInsurance: string;
  legalInsuranceNumber: string;
  externalPreventionService: string;
  externalPreventionNumber: string;
  holidayAllocationOffice: string;
  holidayAllocationNumber: string;
  familyAllocationOffice: string;
  familyAllocationNumber: string;
  socialSecretary: string;
  bankAccountNumber: string;
}
