export class JointCommission {
  id: number;
  active: boolean;
  code: string;
  nameDE: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  starred: boolean;
  validityEndDate: Date;
  validityStartDate: Date;
}
