import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {NaceCode} from '../../model/nace-code/nace-code.model';

@Injectable()
export class NaceCodesResolver implements Resolve<Observable<NaceCode[]>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(): Observable<NaceCode[]> {
    return this.facadeService.getAllNaceCodes().pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
