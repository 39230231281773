import {Component, OnInit} from '@angular/core';
import {Contract} from '../shared/model/contract.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FacadeService} from '../../shared/service/facade/facade.service';
import {convertSingleWorkFunction, WorkFunction} from '../../shared/model/work-function/work-function.model';
import {Location, registerLocaleData} from '@angular/common';
import {ContractWeekPeriod} from '../shared/model/dto/contract-week-period.dto';
import {CopyRequestDialogComponent} from './copy-request-dialog/copy-request-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEnGb from '@angular/common/locales/en-GB';
import {TranslateService} from '@ngx-translate/core';
import {AuthorizationService} from '../../login/shared/service/authorization.service';
import {Role} from '../../users/shared/model/user.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/shared/component/snackbar/snackbar.component';

@Component({
  selector: 'app-contract-view',
  templateUrl: './contract-view.component.html',
  styleUrls: ['./contract-view.component.css']
})
export class ContractViewComponent implements OnInit {
  contract: Contract = new Contract();
  contractWeekPeriods: ContractWeekPeriod;
  workFunction: WorkFunction;

  constructor(private route: ActivatedRoute,
              private dialog: MatDialog,
              private router: Router,
              private facadeService: FacadeService,
              private location: Location,
              public translate: TranslateService,
              private authorizationService: AuthorizationService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.contract = this.route.snapshot.data['contract'];
    this.contractWeekPeriods = new ContractWeekPeriod(this.contract);
    registerLocaleData(localeFr, 'fr-BE');
    registerLocaleData(localeNl, 'nl');
    registerLocaleData(localeEnGb, 'en-GB');
    this.setWorkFunctionLabelById(this.contract.workFunctionId);
    this.translate.onLangChange.subscribe(() => {
      if (this.workFunction) {
        Object.assign(this.workFunction, convertSingleWorkFunction(this.workFunction, this.translate));
      }
    });
  }

  openCustomSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.openFromComponent(SnackbarComponent, {
      panelClass: [pC],
      data: {
        message: message
      }
    });
  }
  
  printPDF() {
    this.openCustomSnackBar(this.translate.instant('contracts.view.downloading'));
    this.facadeService.getContractOriginalFileById(this.contract.id).subscribe(res => {
      const file = new Blob([res], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      this.openSnackBar(this.translate.instant('contracts.view.downloadingSuccessfull'));
      window.open(fileURL, '_blank');
    });
  }

  setWorkFunctionLabelById(workFunctionId: number) {
    this.facadeService.getWorkFunctionById(workFunctionId)
      .subscribe((w: WorkFunction) => {
        this.workFunction = w;
      });
  }

  onBack(): void {
    this.location.back();
  }

  computeColumnRepeat(length: number) {
    return new Array(3 - length);
  }

  copyRequestWithNewDate() {
    const dialogRef = this.dialog.open(CopyRequestDialogComponent, {
      data: {
        contract: this.contract
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        return this.router.navigate(['request-edit'], {queryParams: {'r': result.id}});
      }
    });
  }

  hasAdminRole() {
    return this.authorizationService.hasRole(Role.COMEXIS_SUPER_ADMIN);
  }

  generatePdf() {
    this.facadeService.generatePdfById(this.contract.id).subscribe(
      () => this.openSnackBar('Pdf generated'));
  }

  private openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: [pC]
    });
  }
}
