import {Component, OnInit} from '@angular/core';
import {OperatingUnit} from '../shared/model/operating-unit.model';
import {ActivatedRoute} from '@angular/router';
import {Sector} from '../../shared/model/sector/sector.model';

@Component({
  selector: 'app-operating-unit-edit',
  templateUrl: './operating-unit-edit.component.html',
  styleUrls: ['./operating-unit-edit.component.css']
})
export class OperatingUnitEditComponent implements OnInit {
  operatingUnit: OperatingUnit = new OperatingUnit();
  sectors: Sector[] = [];

  constructor(private route: ActivatedRoute) {
    this.getOperatingUnit();
    this.listSectors();
  }

  ngOnInit() {
  }

  private getOperatingUnit() {
    const data = this.route.snapshot.data['operatingUnit'];
    Object.assign(this.operatingUnit, data);
  }

  private listSectors() {
    const data = this.route.snapshot.data['sectors'];
    Object.assign(this.sectors, data);
  }
}
