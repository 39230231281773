import {Address} from '../../../shared/model/address/address.model';
import {LanguageCode} from '../../../shared/enum/language-code.enum';
import {BankData, Gender, Worker, WorkerStatus} from '../../../workers/shared/model/worker.model';

export class WorkerLite {
  id: number;
  firstName: string;
  lastName: string;
  address: Address = new Address();
  bankData: BankData = new BankData();
  workerStatus: WorkerStatus = new WorkerStatus();
  gender: Gender;
  mobile: string;
  email: string;
  dateOfBirth: Date;
  placeOfBirth: string;
  languageCode: LanguageCode;
  active = true;

  createWorkerFromWorkerLite(): Worker {
    const worker: Worker = new Worker();
    const address: Address = new Address();
    const bankData: BankData = new BankData();
    const workerStatus: WorkerStatus = new WorkerStatus();

    address.street = this.address.street;
    address.number = this.address.number;
    address.box = this.address.box;
    address.zip = this.address.zip;
    address.city = this.address.city;
    address.country = this.address.country;

    bankData.bic = this.bankData.bic;
    bankData.iban = this.bankData.iban;

    workerStatus.nationality = this.workerStatus.nationality;
    workerStatus.seniority = this.workerStatus.seniority;

    worker.firstName = this.firstName;
    worker.lastName = this.lastName;
    worker.gender = this.gender;
    worker.mobile = this.mobile;
    worker.email = this.email;
    worker.dateOfBirth = new Date(this.dateOfBirth);
    worker.placeOfBirth = this.placeOfBirth;
    worker.languageCode = this.languageCode;

    worker.address = address;
    worker.bankData = bankData;
    worker.workerStatus = workerStatus;

    return worker;
  }
}
