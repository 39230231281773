import {TranslateService} from '@ngx-translate/core';

export class NaceCode {
  id: number;
  active: boolean;
  code: string;
  isMaribelSocial: boolean;
  isOnssApl: boolean;
  nameDE: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  nameTranslated: string;
  starred: boolean;
  validityEndDate: Date;
  validityStartDate: Date;

  public translateNaceCode(name: string): NaceCode {
    this.nameTranslated = name;
    return this;
  }
}

export function convertNaceCode(naceCodes: NaceCode[], translate: TranslateService): NaceCode[] {
  switch (translate.currentLang.toLowerCase()) {
    case 'fr':
      return naceCodes.map(nc => nc.translateNaceCode(nc.nameFR))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
    case 'nl':
      return naceCodes.map(nc => nc.translateNaceCode(nc.nameNL))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
    case 'de':
      return naceCodes.map(nc => nc.translateNaceCode(nc.nameDE))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
    default :
      return naceCodes.map(nc => nc.translateNaceCode(nc.nameEN))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
  }
}
