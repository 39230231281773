import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Request, RequestStatus} from '../../model/request.model';
import {FacadeService} from '../../../../shared/service/facade/facade.service';
import {AuthorizationService} from '../../../../login/shared/service/authorization.service';
import {Role} from '../../../../users/shared/model/user.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {RequestPage} from '../../../../shared/model/pagination/request/request-page.model';
import {ActivatedRoute, Router} from '@angular/router';
import {checkIfValidSearchTerm} from '../../../../shared/model/pagination/pagination.model';

@Component({
  selector: 'app-listing-request',
  templateUrl: './listing-request.component.html',
  styleUrls: ['./listing-request.component.css']
})
export class ListingRequestComponent implements OnInit, OnChanges {

  displayedColumns: string[] = ['statusColor', 'requestId', 'customerName', 'workerId', 'startDate',
    'endDate', 'status', 'message', 'dimona', 'paid'];
  requestTable = new MatTableDataSource<Request>();
  @ViewChild('requestsPaginator', {static: true}) paginator: MatPaginator;
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;
  wasInFilter = false;
  previousIndex = 0;
  private queryFromRequest;

  constructor(private translateService: TranslateService,
              private facadeService: FacadeService,
              private authorizationService: AuthorizationService,
              public route: ActivatedRoute,
              private router: Router) {
  }

  get query(): any {
    return this.queryFromRequest;
  }

  @Input()
  set query(val: any) {
    this.queryFromRequest = val;
  }

  @Input() set requests(r: RequestPage) {
    this.pageIndex = this.route.snapshot.queryParams['pageIndex'] ?? 0;
    this.pageSize = this.route.snapshot.queryParams['pageSize'] ?? 20;
    this.length = r.totalElements;
    this.requestTable = new MatTableDataSource<Request>(r.content);
    this.requestTable.paginator = this.paginator;
    this.wasInFilter = checkIfValidSearchTerm(this.route.snapshot.queryParams['search']);
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  ngOnInit() {
  }

  handleChangePagination(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getEventRequestData();
    return event;
  }

  getEventRequestData() {
    this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: {pageIndex: this.pageIndex, pageSize: this.pageSize, search: this.queryFromRequest}
      }
    );
    this.facadeService.getRequestPage(this.pageIndex, this.pageSize, {query: this.queryFromRequest}).subscribe(
      (res: RequestPage) => {
        this.requestTable.data = res.content;
        this.length = res.totalElements;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (checkIfValidSearchTerm(changes.query.currentValue)) {
      this.queryFromRequest = changes.query.currentValue;
      if (!this.wasInFilter) {
        this.previousIndex = this.pageIndex;
        this.pageIndex = 0;
      }
      this.wasInFilter = true;
    } else {
      this.query = null;
      if (!this.wasInFilter) {
        return;
      }
      this.pageIndex = this.previousIndex;
      this.wasInFilter = false;
    }
    this.getEventRequestData();
  }

  getRouterLink(request: Request): string {
    if (request.status === RequestStatus.CUSTOMER_CREATED) {
      return '/request-new';
    }
    return '/request-edit';
  }
}
