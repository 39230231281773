import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {OperatingUnit} from '../model/operating-unit.model';
import {OperatingUnitSerializer} from '../model/operating-unit.serializer';
import {map} from 'rxjs/operators';
import {AuthorizationService} from '../../../login/shared/service/authorization.service';
import {Role} from '../../../users/shared/model/user.model';
import {OperatingUnitPage} from '../../../shared/model/pagination/operating-unit/operating-unit-page.model';
import {OperatingUnitPageSerializer} from '../../../shared/model/pagination/operating-unit/operating-unit-page.serializer';

@Injectable({
  providedIn: 'root'
})
export class OperatingUnitService {
  private serviceURL = environment.operating_unit_service_URL + 'operating-units';
  private operatingUnitSerializer: OperatingUnitSerializer = new OperatingUnitSerializer();
  private operatingUnitPageSerializer: OperatingUnitPageSerializer = new OperatingUnitPageSerializer();

  constructor(private http: HttpClient,
              private authorizationService: AuthorizationService) {
  }

  get(id: number): Observable<any> {
    return this.http.get<OperatingUnit>(this.serviceURL + '/' + id).pipe(
      map((data) => this.operatingUnitSerializer.fromJson(data))
    );
  }

  list(): Observable<any> {
    return this.http.get<OperatingUnit[]>(this.serviceURL).pipe(
      map((data) => this.convertData(data))
    );
  }

  listActive(): Observable<any> {
    return this.http.get<OperatingUnit[]>(this.serviceURL + '/list-active').pipe(
      map((data) => this.convertData(data))
    );
  }

  getPageOperatingUnits(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<OperatingUnitPage>(this.serviceURL + '/page', {params}).pipe(
      map((data: OperatingUnitPage) => this.operatingUnitPageSerializer.fromJson(data))
    );
  }

  save(operatingUnit: OperatingUnit): Observable<any> {
    if (!this.authorizationService.hasRole(Role.SUPERVISOR)) {
      return throwError(() => 'unauthorized');
    }
    return this.http.post<OperatingUnit>(this.serviceURL, this.operatingUnitSerializer.toJson(operatingUnit)).pipe(
      map((data) => this.operatingUnitSerializer.fromJson(data))
    );
  }

  delete(operatingUnit: OperatingUnit): Observable<any> {
    if (!this.authorizationService.hasRole(Role.SUPER_ADMIN)) {
      return throwError(() => 'unauthorized');
    }
    return this.http.delete(this.serviceURL + '/' + operatingUnit.id);
  }

  active(operatingUnit: OperatingUnit): Observable<any> {
    if (!this.authorizationService.hasRole(Role.SUPER_ADMIN)) {
      return throwError(() => 'unauthorized');
    }
    operatingUnit.active = true;
    return this.save(operatingUnit);
  }

  private convertData(data: any): OperatingUnit[] {
    return data.map(operatingUnit => this.operatingUnitSerializer.fromJson(operatingUnit));
  }
}
