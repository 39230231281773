export class Bic {
  id: number;
  identificationNumber: boolean;
  bicCode: string;
  institutionDutch: string;
  institutionFrench: string;
  institutionEnglish: string;
  institutionGerman: string;
  active: boolean;
}
