import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Customer} from 'src/app/customers/shared/model/customer.model';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';

//  https://blog.nathanaelcherrier.com/fr/angular-les-resolvers/

//  https://www.sitepoint.com/component-routing-angular-router/
//  https://www.concretepage.com/angular-2/angular-resolve-guard-example
//  https://www.callibrity.com/blog/angular-2-route-resolves
//  https://medium.com/angularid/angular-route-resolver-87f29f9e7053
//  https://makina-corpus.com/blog/metier/2018/routing-angular-optimisez-le-rendu-au-changement-de-page
@Injectable()
export class CustomersPageResolver implements Resolve<Observable<Customer[]>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Customer[]> {
    const pageIndex = route.queryParams['pageIndex'] ?? 0;
    const pageSize = route.queryParams['pageSize'] ?? 20;
    const query = route.queryParams['search'];
    return this.facadeService.getPageCustomer(pageIndex, pageSize, {query: query}).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
