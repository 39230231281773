import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {NonAvailability} from 'src/app/workers/shared/model/nonAvailability.model';

@Injectable()
export class NonAvailabilitiesResolver implements Resolve<Observable<NonAvailability>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<NonAvailability> {
    const workerId = route.queryParams['w'];
    return this.facadeService.getNonAvailabilitiesFilter(+workerId).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
