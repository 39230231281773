import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {OperatingUnit} from 'src/app/operating-units/shared/model/operating-unit.model';

@Injectable()
export class OperatingUnitResolver implements Resolve<Observable<OperatingUnit>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<OperatingUnit> {
    const operatingUnitId = route.queryParams['o'];
    return this.facadeService.getOperatingUnitById(+operatingUnitId).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
