import {Serializer} from '../../../../shared/model/serializer';
import {CostCenterPlanningDto, CustomerPlanningDto, PlanningDto, WorkerOccupancyDto, WorkerPlanningDto} from './planning-dto.model';
import {RequestSerializer} from '../../../../requests/shared/model/request.serializer';
import {Request} from '../../../../requests/shared/model/request.model';
import {NonAvailability} from '../../../../workers/shared/model/nonAvailability.model';
import {NonAvailabilitySerializer} from '../../../../workers/shared/model/nonAvailability.serializer';
import {DateSerializer} from '../../../../shared/model/date.serializer';

class WorkerPlanningDTOSerializer implements Serializer {
  private requestSerializer: RequestSerializer = new RequestSerializer();

  fromJson(json: any): WorkerPlanningDto {
    const worker: WorkerPlanningDto = new WorkerPlanningDto();
    const requests: Request[] = [];

    worker.id = json.id;
    worker.name = json.name;
    worker.totalHours = json.totalHours;
    worker.totalMinutes = json.totalMinutes;

    const requestsGroupJSON = json.requestsGroup;
    if (requestsGroupJSON) {
      requestsGroupJSON.forEach(
        requestGroupJSON => {
          requests.push(this.requestSerializer.fromJson(requestGroupJSON));
        }
      );
    }
    worker.requests = requests;

    return worker;
  }

  toJson(resource: Object): any {
  }

}

class CostCenterPlanningDTOSerialize implements Serializer {
  private workerPlanningDTOSerializer: WorkerPlanningDTOSerializer = new WorkerPlanningDTOSerializer();

  fromJson(json: any): CostCenterPlanningDto {
    const costCenter: CostCenterPlanningDto = new CostCenterPlanningDto();
    const workers: WorkerPlanningDto[] = [];

    costCenter.costCenter = json.costCenter;

    const workerGroupsJSON = json.workerGroups;
    if (workerGroupsJSON) {
      workerGroupsJSON.forEach(
        workerGroupJSON => {
          workers.push(this.workerPlanningDTOSerializer.fromJson(workerGroupJSON));
        }
      );
    }
    costCenter.workers = workers;

    return costCenter;
  }

  toJson(resource: Object): any {
  }

}

class CustomerPlanningDTOSerializer implements Serializer {
  private costCenterPlanningDTOSerialize: CostCenterPlanningDTOSerialize = new CostCenterPlanningDTOSerialize();

  fromJson(json: any): CustomerPlanningDto {
    const customer: CustomerPlanningDto = new CustomerPlanningDto();
    const costCenterGroups: CostCenterPlanningDto[] = [];

    customer.id = json.id;
    customer.name = json.name;

    const costCenterGroupsJSON = json.costCenterGroups;
    if (costCenterGroupsJSON) {
      costCenterGroupsJSON.forEach(
        costCenterJSON => {
          costCenterGroups.push(this.costCenterPlanningDTOSerialize.fromJson(costCenterJSON));
        }
      );
    }
    customer.costCenters = costCenterGroups;

    return customer;
  }

  toJson(resource: Object): any {
  }

}

class WorkerOccupancySerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): WorkerOccupancyDto {

    const occupancyDto = new WorkerOccupancyDto();

    occupancyDto.requestId = json.requestId;
    occupancyDto.startDate = this.dateSerializer.fromJson(json.start);
    occupancyDto.endDate = this.dateSerializer.fromJson(json.end);

    return occupancyDto;
  }

  toJson(resource: Object): any {
  }
}

export class PlanningDtoSerializer implements Serializer {
  private customerDTOSerializer: CustomerPlanningDTOSerializer = new CustomerPlanningDTOSerializer();
  private nonAvailabilitySerializer: NonAvailabilitySerializer = new NonAvailabilitySerializer();
  private workerOccupancySerializer: WorkerOccupancySerializer = new WorkerOccupancySerializer();


  fromJson(json: any): PlanningDto {
    const planningDTO: PlanningDto = new PlanningDto();
    const customersDTO: CustomerPlanningDto[] = [];

    planningDTO.count = json.count;

    const customersDTOJson = json.customerGroups;
    if (customersDTOJson) {
      customersDTOJson.forEach(
        customerDTOJSon => {
          customersDTO.push(this.customerDTOSerializer.fromJson(customerDTOJSon));
        }
      );
    }
    planningDTO.customers = customersDTO;

    if (json.nonAvailabilitiesGroup != null) {
      Object.keys(json.nonAvailabilitiesGroup).forEach(key => {
        planningDTO.nonAvailabilities.set(+key, this.convertNonAvailabilityData(json.nonAvailabilitiesGroup[key]));
      });
    }

    if (json.workerOccupancyGroup != null) {
      Object.keys(json.workerOccupancyGroup).forEach(key => {
        planningDTO.workerOccupancies.set(+key, this.convertWorkerOccupancyData(json.workerOccupancyGroup[key]));
      });
    }

    planningDTO.customers = planningDTO.customers.sort((a, b) => a.name < b.name ? -1 : 1);

    return planningDTO;
  }

  toJson(resource: Object): any {
  }

  private convertNonAvailabilityData(data: any): NonAvailability[] {
    return data.map(nonAvailability => this.nonAvailabilitySerializer.fromJson(nonAvailability));
  }

  private convertWorkerOccupancyData(data: any): WorkerOccupancyDto[] {
    return data.map(workerOccupancy => this.workerOccupancySerializer.fromJson(workerOccupancy));
  }

}
