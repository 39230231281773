import { StudentQuotaHours } from './student-quota-hours.model';
import {Serializer} from './../../shared/model/serializer';

export class StudentQuotaHoursSerializer implements Serializer {

  fromJson(json: any): StudentQuotaHours {
    const studentQuotaHours: StudentQuotaHours = new StudentQuotaHours();

    studentQuotaHours.id = json.id;
    studentQuotaHours.active = json.active;
    studentQuotaHours.maxHours = json.maxHours;
    studentQuotaHours.year = json.year;

    return studentQuotaHours;
  }

  toJson(studentQuotaHours: StudentQuotaHours): any {
    return {
      id: studentQuotaHours.id,
      active: studentQuotaHours.active,
      maxHours: studentQuotaHours.maxHours,
      year: studentQuotaHours.year
    };
  }

}
