import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {WorkerLite} from '../../../workers-lite/shared/model/worker-lite.model';

@Injectable()
export class ActiveWorkersLiteResolver implements Resolve<Observable<WorkerLite[]>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(): Observable<WorkerLite[]> {
    return this.facadeService.getPageWorkerLite(0, 20).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
