import {NgModule} from '@angular/core';
import {CustomersResolver} from './shared/resolver/customer/customers.resolver';
import {CustomerResolver} from './shared/resolver/customer/customer.resolver';
import {CustomersActiveResolver} from './shared/resolver/customer/customers-active.resolver';
import {OperatingUnitResolver} from './shared/resolver/operating-unit/operating-unit.resolver';
import {RequestsPageResolver} from './shared/resolver/request/requests-page.resolver';
import {RequestResolver} from './shared/resolver/request/request.resolver';
import {WorkersResolver} from './shared/resolver/worker/workers.resolver';
import {WorkerResolver} from './shared/resolver/worker/worker.resolver';
import {WorkersActiveResolver} from './shared/resolver/worker/workers-active.resolver';
import {UsersConsultantResolver} from './shared/resolver/user/users-consultant.resolver';
import {UserResolver} from './shared/resolver/user/user.resolver';
import {NonAvailabilitiesResolver} from './shared/resolver/worker/non-availabilities.resolver';
import {NaceCodesResolver} from './shared/resolver/reference-data/nace-codes.resolver';
import {PendingRequestsResolver} from './shared/resolver/request/pending-requests.resolver';
import {DeadlineRequestsResolver} from './shared/resolver/request/deadline-requests.resolver';
import {PrestationsToValidateResolver} from './shared/resolver/prestation/prestations-to-validate.resolver';
import {ContractResolver} from './shared/resolver/contract/contract.resolver';
import {LanguagesResolver} from './shared/resolver/language/languages.resolver';
import {CountriesResolver} from './shared/resolver/country/countries.resolver';
import {WorkerDocumentResolver} from './shared/resolver/document/worker-documents.resolver';
import {WorkerPendingDocumentResolver} from './shared/resolver/document/worker-pending-documents.resolver';
import {ActiveWorkersLiteResolver} from './shared/resolver/worker-lite/active-workers-lite-resolver.service';
import {WorkerLiteResolver} from './shared/resolver/worker-lite/worker-lite.resolver';
import {InvoicesResolver} from './shared/resolver/invoices/invoices.resolver';
import {InvoiceResolver} from './shared/resolver/invoices/invoice.resolver';
import {SectorsResolver} from './shared/resolver/sector/sectors.resolver';
import {ErrorRequestsResolver} from './shared/resolver/request/error-requests.resolver';
import {CompanyPrefixesResolver} from './shared/resolver/reference-data/company-prefixes.resolver';
import {WorkersPageResolver} from './shared/resolver/worker/workers-page.resolver';
import {CustomersPageResolver} from './shared/resolver/customer/customers-page.resolver';
import {OperatingUnitsPageResolver} from './shared/resolver/operating-unit/operating-units-page.resolver';
import {UserUuidResolver} from './shared/resolver/user/user-uuid.resolver';
import {PrestationsReleasedResolver} from './shared/resolver/prestation/prestations-released-resolver';
import {PrestationsNotReleasedResolver} from './shared/resolver/prestation/prestations-not-released.resolver';
import {CustomerDocumentResolver} from './shared/resolver/document/customer-documents.resolver';
import {WorkerPositionsResolver} from './shared/resolver/reference-data/worker-positions.resolver';
import {WorkerFromRequestResolver} from './shared/resolver/request/worker-from-request.resolver';
import {CustomerFromRequestResolver} from './shared/resolver/request/customer-from-request.resolver';
import {ActiveOperatingUnitsResolver} from './shared/resolver/operating-unit/active-operating-units.resolver';
import {CustomerRequestsResolver} from './shared/resolver/request/customer-requests.resolver';
import {ContractsPageResolver} from './shared/resolver/contract/contracts-page.resolver';
import {ContractsFromRequestResolver} from './shared/resolver/request/contracts-from-request.resolver';
import {PrestationsCorrectionResolver} from './shared/resolver/request/prestation-correction-from-request.resolver';
import {PrestationsValidationFromRequestResolver} from './shared/resolver/request/prestations-validation-from-request.resolver';
import {UsersWorkerResolver} from './shared/resolver/user/users-worker.resolver';
import {UsersCustomerResolver} from './shared/resolver/user/users-customer.resolver';

@NgModule({
  providers: [
    CustomersResolver,
    CustomerResolver,
    CustomersActiveResolver,
    CustomersPageResolver,
    ContractsPageResolver,
    ContractsFromRequestResolver,
    ActiveOperatingUnitsResolver,
    OperatingUnitResolver,
    RequestsPageResolver,
    RequestResolver,
    WorkerFromRequestResolver,
    CustomerFromRequestResolver,
    WorkersResolver,
    WorkerResolver,
    WorkersActiveResolver,
    WorkersPageResolver,
    UsersConsultantResolver,
    UsersWorkerResolver,
    UsersCustomerResolver,
    UserResolver,
    UserUuidResolver,
    NonAvailabilitiesResolver,
    WorkerPositionsResolver,
    NaceCodesResolver,
    PendingRequestsResolver,
    CustomerRequestsResolver,
    DeadlineRequestsResolver,
    PrestationsValidationFromRequestResolver,
    PrestationsCorrectionResolver,
    PrestationsToValidateResolver,
    PrestationsReleasedResolver,
    PrestationsNotReleasedResolver,
    ContractResolver,
    LanguagesResolver,
    CountriesResolver,
    CustomerDocumentResolver,
    WorkerDocumentResolver,
    WorkerPendingDocumentResolver,
    ActiveWorkersLiteResolver,
    WorkerLiteResolver,
    InvoicesResolver,
    InvoiceResolver,
    SectorsResolver,
    ErrorRequestsResolver,
    CompanyPrefixesResolver,
    OperatingUnitsPageResolver,
  ]
})
export class ResolverModule {
}
