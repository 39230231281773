import {Component, Inject} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Period} from '../../../model/request.model';
import {DatePipe} from '@angular/common';
import {Role} from '../../../../../users/shared/model/user.model';
import {AuthorizationService} from '../../../../../login/shared/service/authorization.service';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgxMatDateAdapter} from '@angular-material-components/datetime-picker';


class RequestEventFullCalendar {
  id: any;
  description: string;
  startDate: Date;
  endDate: Date;
  break: Date = new Date(0, 0, 0, 0, 0);

  constructor(start?: Date, end?: Date, eventId?: any, description?: string, breakDuration?: string) {
    if (start && end) {
      this.startDate = start;
      this.startDate.setSeconds(0);
      this.startDate.setMilliseconds(0);
      this.endDate = end;
    }
    if (eventId) {
      this.id = eventId;
    }
    if (description) {
      this.description = description;
    }
    if (breakDuration) {
      this.break = new Date(breakDuration);
    }
  }
}

@Component({
  selector: 'app-add-event-period-dialog',
  templateUrl: './add-event-period-dialog.component.html',
  styleUrls: ['./add-event-period-dialog.component.css']
})
export class AddEventPeriodDialogComponent {
  event: RequestEventFullCalendar = new RequestEventFullCalendar();
  breakDurationValues: string[] = [
    '00:00', '00:15', '00:30', '00:45',
    '01:00', '01:15', '01:30', '01:45',
    '02:00', '02:15', '02:30', '02:45',
    '03:00', '03:15', '03:30', '03:45',
    '04:00', '04:15', '04:30', '04:45',
    '05:00', '05:15', '05:30', '05:45',
    '06:00', '06:15', '06:30', '06:45',
    '07:00', '07:15', '07:30', '07:45',
    '08:00'];

  constructor(private dialogRef: MatDialogRef<AddEventPeriodDialogComponent>,
              private datePipe: DatePipe,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private translate: TranslateService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private authorizationService: AuthorizationService,
              private adapter: NgxMatDateAdapter<any>) {

    if (data) {
      if (data.error) {
        this.openSnackBar(this.translate.instant('requests.form.message.error.overlap'), 'customSnackError');
      }
      this.event = new RequestEventFullCalendar(data.start, data.end, data.eventId, data.description, data.breakDuration);
    }
    this.adapter.setLocale(translate.currentLang);
  }

  delete() {
    this.dialogRef.close([null, null, true]);
  }

  submitAddEvent(eventForm: NgForm) {

    if (eventForm.valid) {
      const startDate: Date = new Date(eventForm.value.startDate);
      const endDate: Date = new Date(eventForm.value.endDate);

      const startDate_ms = startDate.getTime();
      const today = new Date().getTime();

      if (startDate.getTime() >= endDate.getTime()) {
        this.openSnackBar(this.translate.instant('requests.form.message.error.event.endDateAfter'), 'customSnackError');
        return this.dialogRef.close();
      }

      if (today > startDate_ms && !this.authorizationService.hasRole(Role.SUPER_ADMIN)) {
        this.openSnackBar(this.translate.instant('requests.form.message.error.event.eventPast'), 'customSnackError');
        return this.dialogRef.close();
      }

      const period: Period = this.buildPeriod(startDate, endDate, eventForm.value.breakDuration, eventForm.value.description);

      this.closeDialog(period);
    }
  }

  buildPeriod(startDate: Date, endDate: Date, breakDuration: string, description: string): Period {
    const period: Period = new Period();
    period.description = description;

    const breakDurationSplit = breakDuration.split(':');
    period.breakDuration = new Date(new Date(0, 0, 0).setHours(+breakDurationSplit[0], +breakDurationSplit[1]));

    period.startDate = startDate;
    period.endDate = endDate;

    return period;
  }

  closeDialog(event = null): void {
    if (event) {
      return this.dialogRef.close([event, !!this.data.eventId]);
    }
    this.dialogRef.close();
  }

  private openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: [pC]
    });
  }
}
