import {Serializer} from '../serializer';
import {Sector} from './sector.model';

export class SectorSerializer implements Serializer {
  fromJson(json: any): Sector {
    const sector: Sector = new Sector();

    sector.id = json.id;
    sector.active = json.active;
    sector.code = json.code;
    sector.nameDE = json.nameDE;
    sector.nameFR = json.nameFR;
    sector.nameNL = json.nameNL;
    sector.nameEN = json.nameEN;

    return sector;
  }

  toJson(sector: Sector): any {
    return {
      id: sector.id,
      active: sector.active,
      code: sector.code,
      nameDE: sector.nameDE,
      nameFR: sector.nameFR,
      nameNL: sector.nameNL,
      nameEN: sector.nameEN
    };
  }

}
