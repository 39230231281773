import {WorkerType} from '../../../workers/shared/model/worker.model';
import {WorkFunction} from '../../../shared/model/work-function/work-function.model';
import {Address} from '../../../shared/model/address/address.model';

export enum RequestStatus {
  CREATED = 'CREATED',
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
  REOPENED = 'REOPENED',
  IN_VALIDATION = 'IN_VALIDATION',
  VALIDATED = 'VALIDATED',
  DIMONA_PENDING = 'DIMONA_PENDING',
  DIMONA_CANCEL_PENDING = 'DIMONA_PDIMONA_CANCEL_PENDINGENDING',

  DIMONA_OK = 'DIMONA_OK',
  DIMONA_OK_PARTIAL = 'DIMONA_OK_PARTIAL',
  DIMONA_NOK = 'DIMONA_NOK',
  DIMONA_CANCEL_OK = 'DIMONA_CANCEL_OK',
  CONTRACT_CREATED = 'CONTRACT_CREATED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  PRESTATION_REJECTED = 'PRESTATION_REJECTED',
  TO_BE_PAID = 'TO_BE_PAID',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED'
}

export enum ContractType {
  SELECTION = 'SELECTION',
  PAYROLL = 'PAYROLL'
}

export class Request {

  id: number;
  operatingUnitId: number;
  customerId: number;
  customerName: string;
  customerAddress: Address;
  workerId: number;
  workerLastName: string;
  workerFirstName: string;
  startDate: Date;
  endDate: Date;
  status: RequestStatus = RequestStatus.CREATED;
  workerType: WorkerType = WorkerType.WORKER;
  contractType: ContractType;
  workFunctionId: number;
  workFunction: WorkFunction;
  costCenter: string;
  periods: Period[] = [];
  description: string;
  flexyRate: number;
  active = true;
  prestationStarted: boolean;
  dimonaPending: boolean;
  dimonaOk: boolean;
  inError: boolean;
  toBePaid: boolean;
  paid: boolean;
  validated: boolean;
  dimonaReferenceNumber?: string;

  isTerminated() {
    return this.isCancelled() || this.isClosed();
  }

  isCustomerCreated() {
    return this.status === RequestStatus.CUSTOMER_CREATED;
  }

  canBeValidated(): boolean {
    return !this.validated && !this.dimonaPending;
  }

  canBeSaved(): boolean {
    return this.id != null && !this.dimonaPending && !this.isCancelled();
  }

  canBeModified(): boolean {
    return this.canBeSaved() && this.validated && !this.prestationStarted;
  }

  canBeDelete(): boolean {
    return !this.prestationStarted && !this.isCancelled();
  }

  canSeeContracts(): boolean {
    return this.validated || this.status === RequestStatus.REOPENED;
  }

  isValidated(): boolean {
    return this.dimonaOk && [
      RequestStatus.VALIDATED,
      RequestStatus.TO_BE_PAID,
      RequestStatus.PAID
    ].includes(this.status);
  }

  isPaid(): boolean {
    return this.isValidated() && [RequestStatus.PAID].includes(this.status);
  }

  isCancelled() {
    return [RequestStatus.CANCELLED].includes(this.status);
  }

  isClosed() {
    return [RequestStatus.PAID].includes(this.status);
  }

  getColorByStatus(): string {
    let color;
    switch (this.status) {
      case RequestStatus.CREATED:
      case RequestStatus.CUSTOMER_CREATED:
      case RequestStatus.REOPENED:
      case RequestStatus.DIMONA_PENDING:
      case RequestStatus.DIMONA_CANCEL_PENDING:
      case RequestStatus.DIMONA_OK_PARTIAL:
      case RequestStatus.DIMONA_NOK:
        color = '#FF9D00';
        break;
      case RequestStatus.VALIDATED:
      case RequestStatus.TO_BE_PAID:
      case RequestStatus.PRESTATION_REJECTED:
        color = '# ';
        break;
      case RequestStatus.CANCELLED:
        color = '#F44E4E';
        break;
      case RequestStatus.PAID:
        color = '#01B0F1';
        break;
      default:
        //  CONTRACT_CREATED
        //  CONTRACT_SENT
        //  CONTRACT_SIGNED
        //  DIMONA_OK
        color = '#92D14F';
        break;
    }
    return color;
  }
}

export class Period {
  id: number;
  timePeriod: TimePeriod = new TimePeriod();
  startDate: Date;
  endDate: Date;
  description: string;
  active: boolean;
  breakDuration: Date;

  public getDiffMs(): number {
    const breakDuration_base = new Date(0, 0, 0, this.breakDuration.getHours(), this.breakDuration.getMinutes(), 0);
    const base = new Date(0, 0, 0, 0, 0, 0);
    const durationInMs = this.endDate.getTime() - this.startDate.getTime() - (breakDuration_base.getTime() - base.getTime());
    return Math.ceil(durationInMs / 1000 / 3600);
  }
}

export class TimePeriod {
  id: number;
  name: string;
  startTime: Date;
  endTime: Date;
  active: boolean;
}
