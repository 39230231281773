import {Serializer} from '../../serializer';
import { WorkFunctionSerializer } from '../../work-function/work-function.serializer';
import { WorkFunctionPage } from './work-function-page.model';

export class WorkFunctionPageSerializer implements Serializer {

  private workFunctionSerializer = new WorkFunctionSerializer();

  fromJson(json: any): WorkFunctionPage {
    const workFunctionPage = new WorkFunctionPage;

    json.content.forEach(
      content => {
        workFunctionPage.content.push(this.workFunctionSerializer.fromJson(content));
      }
    );

    workFunctionPage.totalElements = json.totalElements;
    workFunctionPage.totalPages = json.totalPages;

    return workFunctionPage;
  }

  toJson(resource: Object): any {
  }
}
