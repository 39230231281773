import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BillingCoefficients } from '../../../customers/shared/model/customer.model';
import { FacadeService } from '../../../shared/service/facade/facade.service';
import { BillingCoefficientsPage } from 'src/app/shared/model/pagination/billing-coefficient/billing-coefficient-page.model';
import { MatDialog } from '@angular/material/dialog';
import { BillingCoefficientDialogComponent } from 'src/app/shared/component/admin-dialog/billing-coefficient-dialog/billing-coefficient-dialog.component';

@Component({
  selector: 'app-billing-coefficient',
  templateUrl: './billing-coefficient.component.html',
  styleUrls: ['./billing-coefficient.component.css', '../../admin.component.css']
})
export class BillingCoefficientComponent implements OnInit {
  @ViewChild('billingCoefficientPaginator', { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource<BillingCoefficients>();
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 20;
  length: number;

  constructor(
    private facadeService: FacadeService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = [
    "id",
    "active",
    "customerSize",
    "employeePayroll",
    "employeeSelection",
    "extraPayroll",
    "extraSelection",
    "flexiPayroll",
    "flexiSelection",
    "studentPayrollNotSubmitted",
    "studentPayrollSubmitted",
    "studentSelectionNotSubmitted",
    "studentSelectionSubmitted",
    "workerPayroll",
    "workerSelection"
  ];

  public getData(event?: PageEvent) {
    this.pageIndex = event ? event.pageIndex : 0;
    this.pageSize = event ? event.pageSize : 20;
    this.facadeService.getPageBillingCoefficient(this.pageIndex, this.pageSize).subscribe(
      (data: BillingCoefficientsPage) => {
        this.dataSource.data = data.content;
        this.dataSource.paginator = this.paginator;
        this.length = data.totalElements;
      }
    );
    return event;
  }

  openPopUp(row) {
    const dialogRef = this.dialog.open(BillingCoefficientDialogComponent, {
      data: { element: row },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }
}
