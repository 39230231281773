import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class MinimumDateValidators {
  static dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = new Date(control.value);

      if (!controlDate) {
        return null;
      }

      const validationDate = new Date(date);

      return controlDate.getTime() > validationDate.getTime() ? null : {
        'date-minimum': {
          'date-minimum': validationDate,
          'actual': controlDate
        }
      };
    };
  }
}
