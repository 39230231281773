import {Serializer} from '../../serializer';
import {InvoicePage} from './invoice-page.model';
import {InvoiceSerializer} from '../../../../bills/shared/model/invoice.serializer';

export class InvoicePageSerializer implements Serializer {

  private invoiceSerializer = new InvoiceSerializer();

  fromJson(json: any): InvoicePage {
    const invoicePage = new InvoicePage();

    json.content.forEach(
      content => {
        invoicePage.content.push(this.invoiceSerializer.fromJson(content));
      }
    );

    invoicePage.totalElements = json.totalElements;
    invoicePage.totalPages = json.totalPages;
    return invoicePage;
  }

  toJson(resource: Object): any {
  }
}

