import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Contract, DimonaStatus} from '../../model/contract.model';
import {FacadeService} from 'src/app/shared/service/facade/facade.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Location} from '@angular/common';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {ContractPage} from '../../../../shared/model/pagination/contract/contract-page.model';
import {checkIfValidSearchTerm} from '../../../../shared/model/pagination/pagination.model';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.css']
})
export class ContractListComponent implements OnInit, OnChanges {
  @ViewChild('contractListPaginator', {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['active', 'contractId', 'customerName', 'workerName', 'startDate', 'endDate', 'requestId', 'dimonaStatus', 'file'];
  contractTable = new MatTableDataSource<Contract>();
  pageEvent: PageEvent;
  previousIndex = 0;
  length: number;
  wasInFilter = false;
  originalParams: Params;
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Input() requestId: number;
  @Input() rememberPagination = false;
  private queryFromContract;
  private activeChecked = false;

  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private facadeService: FacadeService,
              private location: Location) {
  }

  @Input()
  set query(val: any) {
    this.queryFromContract = val;
  }

  @Input()
  set active(val: boolean) {
    this.activeChecked = val;
  }

  @Input() set contracts(c: ContractPage) {
    this.length = c.totalElements;
    this.contractTable = new MatTableDataSource<Contract>(c.content);
    this.contractTable.paginator = this.paginator;
    this.wasInFilter = checkIfValidSearchTerm(this.route.snapshot.queryParams['search']);
  }

  get DimonaStatus() {
    return DimonaStatus;
  }

  ngOnInit() {
    this.originalParams = {
      ...this.route.snapshot.queryParams
    };
  }

  handleChangePagination(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getEventContractData();
    return event;
  }

  getEventContractData() {
    const params: Params = {
      ...this.originalParams,
      search: this.queryFromContract,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize
    };
    if (this.rememberPagination) {
      this.router.navigate(['.'], {
          relativeTo: this.route,
          queryParams: params
        }
      );
    }
    this.facadeService.getPageContract(
      this.pageIndex,
      this.pageSize,
      {
        query: this.queryFromContract,
        requestId: this.requestId,
        active: this.activeChecked
      }).subscribe(
      (res: ContractPage) => {
        this.contractTable.data = res.content;
        this.length = res.totalElements;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      if (checkIfValidSearchTerm(changes.query.currentValue)) {
        this.queryFromContract = changes.query.currentValue;
        if (!this.wasInFilter) {
          this.previousIndex = this.pageIndex;
          this.pageIndex = 0;
        }
        this.wasInFilter = true;
      } else {
        this.queryFromContract = null;
        if (!this.wasInFilter) {
          return;
        }
        this.pageIndex = this.previousIndex;
        this.wasInFilter = false;
      }
      this.getEventContractData();
    }
    if (changes.active) {
      this.activeChecked = changes.active.currentValue;
      this.getEventContractData();
    }
  }

  onBack(): void {
    this.location.back();
  }
}
