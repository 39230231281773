import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FacadeService} from 'src/app/shared/service/facade/facade.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  BillingCoefficients,
  BusinessContact,
  Customer,
  CustomerSize,
  InvoicingPeriodicity,
  InvoicingType,
  VATCode
} from '../../model/customer.model';
import {OperatingUnit} from 'src/app/operating-units/shared/model/operating-unit.model';
import {JointCommission} from '../../../../shared/model/joint-commission/joint-commission.model';
import {convertNaceCode, NaceCode} from '../../../../shared/model/nace-code/nace-code.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {Address} from '../../../../shared/model/address/address.model';
import {JointCommissionCoefficient} from '../../../../shared/model/joint-commission/joint-commission-coefficient.model';
import {HttpErrorResponse} from '@angular/common/http';
import {CompanyPrefix, convertCompanyPrefix} from '../../../../shared/model/company-prefix/company-prefix.model';
import {AuthorizationService} from '../../../../login/shared/service/authorization.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Bic} from '../../../../shared/model/bic/bic.model';
import {ibanValidator} from 'ngx-iban';
import {convertCountries, Country} from '../../../../shared/model/country/country.model';
import {emailValidator} from '../../../../shared/validator/email.validator';
import {twoDecimalsValidator} from '../../../../shared/validator/two-decimals.validator';
import {fourDecimalsValidator} from '../../../../shared/validator/four-decimals.validator';
import {MatDialog} from '@angular/material/dialog';
import {
  SubsidiaryCustomerDialogComponent,
  TempSubsidiaryAddress
} from './subsidiary-adress/subsidiary-customer-dialog/subsidiary-customer-dialog.component';
import {Role} from '../../../../users/shared/model/user.model';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.css']
})
export class CustomerFormComponent implements OnInit, AfterViewChecked {
  @Input() customer: Customer;
  @Input() countries: Country[];
  @Input() companyPrefixes: CompanyPrefix[] = [];
  @Input() naceCodes: NaceCode[];
  @Input() operatingUnits: OperatingUnit[];

  customerForm: UntypedFormGroup;
  tempSubsidiaryAddresses: TempSubsidiaryAddress[] = [];
  jointCommissions: JointCommission[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  customerSizes = Object.keys(CustomerSize);
  vatCodes = Object.keys(VATCode);
  invoicingTypes = Object.keys(InvoicingType);
  invoicingPeriodicals = Object.keys(InvoicingPeriodicity);
  ibanModified: boolean;
  currentLang: string;
  indexToDivide: number;

  constructor(private facadeService: FacadeService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private snackBar: MatSnackBar,
              private authorizationService: AuthorizationService,
              private translate: TranslateService,
              private cdr: ChangeDetectorRef,
              private dialog: MatDialog) {
    this.getCustomer();
  }

  ngOnInit() {
    if (this.customer.subsidiaryAddresses) {
      this.tempSubsidiaryAddresses = this.customer.subsidiaryAddresses.map(s => new TempSubsidiaryAddress().convertFromSub(s));
    }
    this.initJointCommissions();
    this.formBuilderValidations();
    this.indexToDivide = this.countries.filter(c => c.commonCountry === true).length - 1;
    this.naceCodes.sort((a, b) => a.code.localeCompare(b.code));
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(() => {
      if (this.countries) {
        this.countries = convertCountries(this.countries, this.translate);
        this.indexToDivide = this.countries.filter(c => c.commonCountry === true).length - 1;
      }
      if (this.naceCodes) {
        this.naceCodes = convertNaceCode(this.naceCodes, this.translate);
      }
      if (this.companyPrefixes) {
        this.companyPrefixes = convertCompanyPrefix(this.companyPrefixes, this.translate);
      }
    });
  }

  // Fix error NG0100 when translating a label in mat-select which contains multiple mat-options
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  setJointCommissionByOperatingUnitId(value: any) {
    this.customerForm.controls.billingData.get('jointCommissionCode').disable();
    this.setJointCommissions([]);
    const operatingUnit = this.operatingUnits.find(oU => oU.id === value);
    this.getJointCommissionsBySectorId(operatingUnit.sectorId);
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  formBuilderValidations() {

    const operatingUnit = this.getOperatingUnit();
    this.customerForm = this.formBuilder.group({
      id: [this.customer.id, []],
      customerNumber: [this.customer.customerNumber, []],
      active: [this.customer.active, []],
      operatingUnitId: [operatingUnit === undefined ? null : operatingUnit.id, [Validators.required]],
      enterpriseNumber: [this.customer.enterpriseNumber, [Validators.required]],
      name: [this.customer.name, [Validators.required]],
      prefix: [this.customer.prefix, []],
      address: this.buildAddress(this.customer.address),
      subsidiaryAddresses: this.formBuilder.array([]),
      businessContact1: this.buildBusinessContact(this.customer.businessContact1),
      businessContact2: this.buildBusinessContact(this.customer.businessContact2),
      businessContact3: this.buildBusinessContact(this.customer.businessContact3),
      businessContact4: this.buildBusinessContact(this.customer.businessContact4),
      businessContact5: this.buildBusinessContact(this.customer.businessContact5),
      billingData: this.formBuilder.group({
        vatCode: [this.customer.billingData.vatCode, []],
        jointCommissionCode: [{
          value: this.customer.billingData.jointCommissionCode,
          disabled: !this.customer.operatingUnitId
        }, [Validators.required]],
        onssNumber: [this.customer.billingData.onssNumber, []],
        vatNumber: [this.customer.billingData.vatNumber, []], // The same as the enterprise number
        iban: [this.customer.billingData.iban, []],
        bic: [this.customer.billingData.bic, [Validators.maxLength(20)]],
        billingAddress: this.buildAddress(this.customer.billingData.billingAddress),
        billingEmail: [this.customer.billingData.billingEmail, [Validators.required, emailValidator]],
        multiSiteBilling: [this.customer.billingData.multiSiteBilling, [Validators.required]],
        invoicingType: [this.customer.billingData.invoicingType, [Validators.required]],
        socialCharter: [this.customer.billingData.socialCharter, []],
        specificScale: [this.customer.billingData.specificScale, []],
        seniorityTaken: [this.customer.billingData.seniorityTaken, []]
      }),
      customerSize: [this.customer.customerSize, [Validators.required]],
      paymentTerms: this.formBuilder.group({
        mealFacial: [this.customer.paymentTerms.mealFacial,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        mealEmployer: [this.customer.paymentTerms.mealEmployer,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        mealVoucher: [this.customer.paymentTerms.mealVoucher,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        dimonaFee: [this.customer.paymentTerms.dimonaFee,
          [Validators.required, Validators.min(0), fourDecimalsValidator]],
        coefficientClothes: [this.customer.paymentTerms.coefficientClothes,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        coefficientPension: [this.customer.paymentTerms.coefficientPension,
          [Validators.required, Validators.min(0), fourDecimalsValidator]],
        coefficientPurchasing: [this.customer.paymentTerms.coefficientPurchasing,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        coefficientEcocheques: [this.customer.paymentTerms.coefficientEcocheques,
          [Validators.required, Validators.min(0), fourDecimalsValidator]],
        travelExpenseBillingCoefficient: [this.customer.paymentTerms.travelExpenseBillingCoefficient,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        dimonaBillingCoefficient: [this.customer.paymentTerms.dimonaBillingCoefficient,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        clothesBillingCoefficient: [this.customer.paymentTerms.clothesBillingCoefficient,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        purchasingBillingCoefficient: [this.customer.paymentTerms.purchasingBillingCoefficient,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        ecochequesBillingCoefficient: [this.customer.paymentTerms.ecochequesBillingCoefficient,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        pensionBillingCoefficient: [this.customer.paymentTerms.pensionBillingCoefficient,
          [Validators.required, Validators.min(0), fourDecimalsValidator]]
      }),
      uncomfortableWorkingHours: this.formBuilder.group({
        late: [this.customer.uncomfortableWorkingHours.late,
          [Validators.required, Validators.min(0), fourDecimalsValidator]],
        night: [this.customer.uncomfortableWorkingHours.night,
          [Validators.required, Validators.min(0), fourDecimalsValidator]],
        saturday: [this.customer.uncomfortableWorkingHours.saturday,
          [Validators.required, Validators.min(0), fourDecimalsValidator]],
        sunday: [this.customer.uncomfortableWorkingHours.sunday,
          [Validators.required, Validators.min(0), fourDecimalsValidator]],
        cutSchedule: [this.customer.uncomfortableWorkingHours.cutSchedule,
          [Validators.required, Validators.min(0), fourDecimalsValidator]],
        publicHoliday: [this.customer.uncomfortableWorkingHours.publicHoliday,
          [Validators.required, Validators.min(0), fourDecimalsValidator]]
      }),
      legalData: this.formBuilder.group({
        unionDelegation: [this.customer.legalData.unionDelegation, [Validators.required]],
        socialReportStart: [this.customer.legalData.socialReportStart, []],
        socialReportEnd: [this.customer.legalData.socialReportEnd, []],
        invoicingPeriodicity: [this.customer.legalData.invoicingPeriodicity, [Validators.required]],
        paymentDelay: [this.customer.legalData.paymentDelay, []],
        weeklyActivity: [this.customer.legalData.weeklyActivity, []],
        successiveContracts: [this.customer.legalData.successiveContracts, []],
        holidayClosing: [this.customer.legalData.holidayClosing, []],
        naceCodeId: [this.customer.legalData.naceCodeId, []],
        costCenter: this.formBuilder.array(this.customer.legalData.costCenter)
      }),
      commercialFollowup: this.formBuilder.group({
        comment: [this.customer.commercialFollowup.comment, []]
      }),
      billingCoefficients: this.formBuilder.group({
        employeePayroll: [this.customer.billingCoefficients.employeePayroll,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        employeeSelection: [this.customer.billingCoefficients.employeeSelection,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        extraPayroll: [this.customer.billingCoefficients.extraPayroll,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        extraSelection: [this.customer.billingCoefficients.extraSelection,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        flexiPayroll: [this.customer.billingCoefficients.flexiPayroll,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        flexiSelection: [this.customer.billingCoefficients.flexiSelection,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        studentPayrollNotSubmitted: [this.customer.billingCoefficients.studentPayrollNotSubmitted,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        studentPayrollSubmitted: [this.customer.billingCoefficients.studentPayrollSubmitted,
          [Validators.min(0), twoDecimalsValidator]],
        studentSelectionNotSubmitted: [this.customer.billingCoefficients.studentSelectionNotSubmitted,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        studentSelectionSubmitted: [this.customer.billingCoefficients.studentSelectionSubmitted,
          [Validators.min(0), twoDecimalsValidator]],
        workerPayroll: [this.customer.billingCoefficients.workerPayroll,
          [Validators.required, Validators.min(0), twoDecimalsValidator]],
        workerSelection: [this.customer.billingCoefficients.workerSelection,
          [Validators.required, Validators.min(0), twoDecimalsValidator]]
      })
    });

    if (this.customer.billingData.iban) {
      this.customerForm.controls.billingData.get('iban').setValidators(
        [
          Validators.required,
          ibanValidator()
        ]
      );
      this.customerForm.controls.billingData.get('iban').updateValueAndValidity({emitEvent: false});
    }

    this.ibanModified = !!(this.customer.billingData.iban);

    this.customerForm.controls.billingData.get('iban').valueChanges.subscribe(() => {
      const iban = this.customerForm.controls.billingData.get('iban');
      if (iban.value == null || iban.value === '') {
        iban.setValue(null, {emitEvent: false});
        this.ibanModified = false;
        iban.clearValidators();
        iban.updateValueAndValidity({emitEvent: false});
      } else {
        if (!this.ibanModified) {
          this.ibanModified = true;
          iban.setValidators(
            [
              Validators.required,
              ibanValidator()
            ]
          );
          iban.updateValueAndValidity({emitEvent: false});
        }

        if (iban.valid && iban.value.replace(/\s/g, '').substring(0, 2) === 'BE') {
          this.facadeService.getBicByIdentificationNumber(iban.value.replace(/\s/g, '').substring(4, 7)).subscribe(
            (bic: Bic) => {
              this.customerForm.controls.billingData.get('bic').setValue(bic.bicCode);
            }
          );
        }
      }
    });

    this.customerForm.controls.billingData.get('bic').valueChanges.subscribe(() => {
        const bic = this.customerForm.controls.billingData.get('bic');
        if (bic.value === '') {
          bic.setValue(null, {emitEvent: false});
          bic.updateValueAndValidity({emitEvent: false});
        }
      }
    );
  }

  setVatNumber(event: any) {
    this.customerForm.controls.billingData.get('vatNumber').setValue(event.data);
  }

  onSearchVAT(enterpriseNumber: string) {
    this.facadeService.getInfoDatabe(enterpriseNumber)
      .subscribe({
        next:
          data => {
            this.customer.mapDatabeInfo(data);
            this.customerForm.patchValue(this.customer);
          },
        error: (error: HttpErrorResponse) => {
          return this.openSnackBar(this.translate.instant('customers.form.message.error.vat', {error: error.message}), 'X', 'customSnackError');
        }
      });
  }

  copyCustomerAddressToBillingAddress() {
    const address = this.customerForm.controls.address;
    const billingAddress = this.customerForm.controls.billingData.get('billingAddress');
    billingAddress.get('street').setValue(address.get('street').value);
    billingAddress.get('number').setValue(address.get('number').value);
    billingAddress.get('box').setValue(address.get('box').value);
    billingAddress.get('zip').setValue(address.get('zip').value);
    billingAddress.get('city').setValue(address.get('city').value);
    billingAddress.get('country').setValue(address.get('country').value);
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  onSubmit() {
    if (this.customerForm.invalid) {
      this.customerForm.markAllAsTouched();
      this.getFormValidationErrors(this.customerForm);
      const firstElementWithError = document.querySelector('.ng-invalid');
      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({behavior: 'smooth'});
      }
      return this.openSnackBar(this.translate.instant('customers.form.message.error.form'), 'X', 'customSnackError');
    }
    Object.assign(this.customer, this.customerForm.value);
    this.customer.subsidiaryAddresses = this.tempSubsidiaryAddresses;
    this.facadeService.saveCustomer(this.customer)
      .subscribe({
        next: customer => {
          this.router.navigate(['customers']).then();
          this.openSnackBar(this.translate.instant('customers.form.message.success.save', {id: customer.id}), 'X', 'customSnackValid');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(
            this.translate.instant('customers.form.message.error.save', {error: error.message}),
            'X', 'customSnackError');
        }
      });
  }

  onBack(): void {
    this.location.back();
  }

  addCostCenter(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value.trim();

    if ((value !== '')) {
      const costCenter = <UntypedFormArray>this.customerForm.controls.legalData.get('costCenter');
      costCenter.push(new UntypedFormControl(value.toUpperCase()));
      costCenter.updateValueAndValidity();
      input.value = '';
    }
  }

  removeCostCenter(event: any): void {
    const costCenter = <UntypedFormArray>this.customerForm.controls.legalData.get('costCenter');
    const index = costCenter.value.indexOf(event);

    if (index >= 0) {
      costCenter.removeAt(index);
      costCenter.updateValueAndValidity();
    }
  }

  getBillingCoefficientsByCustomerSize(event: any) {
    this.customerForm.controls.billingCoefficients.get('employeePayroll').reset();
    this.customerForm.controls.billingCoefficients.get('employeeSelection').reset();
    this.customerForm.controls.billingCoefficients.get('extraPayroll').reset();
    this.customerForm.controls.billingCoefficients.get('extraSelection').reset();
    this.customerForm.controls.billingCoefficients.get('flexiPayroll').reset();
    this.customerForm.controls.billingCoefficients.get('flexiSelection').reset();
    this.customerForm.controls.billingCoefficients.get('studentPayrollNotSubmitted').reset();
    this.customerForm.controls.billingCoefficients.get('studentSelectionNotSubmitted').reset();
    this.customerForm.controls.billingCoefficients.get('studentPayrollSubmitted').reset();
    this.customerForm.controls.billingCoefficients.get('studentSelectionSubmitted').reset();
    this.customerForm.controls.billingCoefficients.get('workerPayroll').reset();
    this.customerForm.controls.billingCoefficients.get('workerSelection').reset();

    this.facadeService.getBillingCoefficientsByCustomerSize(event.value)
      .subscribe({
        next: (data: BillingCoefficients) => {
          this.customerForm.controls.billingCoefficients.get('employeePayroll').setValue(data.employeePayroll);
          this.customerForm.controls.billingCoefficients.get('employeeSelection').setValue(data.employeeSelection);
          this.customerForm.controls.billingCoefficients.get('extraPayroll').setValue(data.extraPayroll);
          this.customerForm.controls.billingCoefficients.get('extraSelection').setValue(data.extraSelection);
          this.customerForm.controls.billingCoefficients.get('flexiPayroll').setValue(data.flexiPayroll);
          this.customerForm.controls.billingCoefficients.get('flexiSelection').setValue(data.flexiSelection);
          this.customerForm.controls.billingCoefficients.get('studentPayrollNotSubmitted').setValue(data.studentPayrollNotSubmitted);
          this.customerForm.controls.billingCoefficients.get('studentSelectionNotSubmitted').setValue(data.studentSelectionNotSubmitted);
          this.customerForm.controls.billingCoefficients.get('studentPayrollSubmitted').setValue(data.studentPayrollSubmitted);
          this.customerForm.controls.billingCoefficients.get('studentSelectionSubmitted').setValue(data.studentSelectionSubmitted);
          this.customerForm.controls.billingCoefficients.get('workerPayroll').setValue(data.workerPayroll);
          this.customerForm.controls.billingCoefficients.get('workerSelection').setValue(data.workerSelection);
        },
        error: () => {
          this.customerForm.controls.customerSize.reset();
          // alert(this.translate.instant('customers.form.message.error.billingCoefficients'));
        }
      });
  }

  getJointCommissionCoefficientsByJointCommission(event: any) {
    const uncomfortableWorkingHours = this.customerForm.controls.uncomfortableWorkingHours;
    const paymentTerms = this.customerForm.controls.paymentTerms;

    uncomfortableWorkingHours.get('late').reset();
    uncomfortableWorkingHours.get('night').reset();
    uncomfortableWorkingHours.get('saturday').reset();
    uncomfortableWorkingHours.get('sunday').reset();
    uncomfortableWorkingHours.get('cutSchedule').reset();
    uncomfortableWorkingHours.get('publicHoliday').reset();
    paymentTerms.get('mealFacial').reset();
    paymentTerms.get('mealEmployer').reset();
    paymentTerms.get('dimonaFee').reset();
    paymentTerms.get('coefficientClothes').reset();
    paymentTerms.get('coefficientPension').reset();
    paymentTerms.get('coefficientPurchasing').reset();
    paymentTerms.get('coefficientEcocheques').reset();
    paymentTerms.get('mealVoucher').reset();
    paymentTerms.get('travelExpenseBillingCoefficient').reset();
    paymentTerms.get('dimonaBillingCoefficient').reset();
    paymentTerms.get('clothesBillingCoefficient').reset();
    paymentTerms.get('purchasingBillingCoefficient').reset();
    paymentTerms.get('ecochequesBillingCoefficient').reset();
    paymentTerms.get('pensionBillingCoefficient').reset();

    this.facadeService.getJointCommissionCoefficientsByJointCommissionCode(event.value)
      .subscribe((data: JointCommissionCoefficient) => {
        const dataUncomfortableWorkingHours = data.uncomfortableWorkingHours;
        const dataPaymentTerms = data.paymentTerms;
        uncomfortableWorkingHours.get('late').setValue(dataUncomfortableWorkingHours.late);
        uncomfortableWorkingHours.get('night').setValue(dataUncomfortableWorkingHours.night);
        uncomfortableWorkingHours.get('saturday').setValue(dataUncomfortableWorkingHours.saturday);
        uncomfortableWorkingHours.get('sunday').setValue(dataUncomfortableWorkingHours.sunday);
        uncomfortableWorkingHours.get('cutSchedule').setValue(dataUncomfortableWorkingHours.cutSchedule);
        uncomfortableWorkingHours.get('publicHoliday').setValue(dataUncomfortableWorkingHours.publicHoliday);
        paymentTerms.get('mealFacial').setValue(dataPaymentTerms.mealFacial);
        paymentTerms.get('mealEmployer').setValue(dataPaymentTerms.mealEmployer);
        paymentTerms.get('dimonaFee').setValue(dataPaymentTerms.dimonaFee);
        paymentTerms.get('coefficientClothes').setValue(dataPaymentTerms.coefficientClothes);
        paymentTerms.get('coefficientPension').setValue(dataPaymentTerms.coefficientPension);
        paymentTerms.get('coefficientPurchasing').setValue(dataPaymentTerms.coefficientPurchasing);
        paymentTerms.get('coefficientEcocheques').setValue(dataPaymentTerms.coefficientEcocheques);
        paymentTerms.get('mealVoucher').setValue(dataPaymentTerms.mealVoucher);
        paymentTerms.get('travelExpenseBillingCoefficient').setValue(dataPaymentTerms.travelExpenseBillingCoefficient);
        paymentTerms.get('dimonaBillingCoefficient').setValue(dataPaymentTerms.dimonaBillingCoefficient);
        paymentTerms.get('clothesBillingCoefficient').setValue(dataPaymentTerms.clothesBillingCoefficient);
        paymentTerms.get('purchasingBillingCoefficient').setValue(dataPaymentTerms.purchasingBillingCoefficient);
        paymentTerms.get('ecochequesBillingCoefficient').setValue(dataPaymentTerms.ecochequesBillingCoefficient);
        paymentTerms.get('pensionBillingCoefficient').setValue(dataPaymentTerms.pensionBillingCoefficient);
      });
  }

  seeSubsidiaryAddress() {

    const dialogRef = this.dialog.open(SubsidiaryCustomerDialogComponent, {
      data: {
        countries: this.countries,
        subsidiaryAddresses: this.tempSubsidiaryAddresses,
      }
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.tempSubsidiaryAddresses = data;
        }
      }
    );
  }

  private setJointCommissions(jointCommissions: JointCommission[]) {
    this.jointCommissions = jointCommissions;
    this.customerForm.controls.billingData.get('jointCommissionCode').enable();
  }

  private getJointCommissionsBySectorId(sectorId: number) {
    this.facadeService.getJointCommissionsBySectorId(sectorId).subscribe((jointCommissions: JointCommission[]) => {
      this.setJointCommissions(jointCommissions);
    });
  }

  private getCustomer() {
    if (!this.customer) {
      this.customer = new Customer();
    }
  }

  private initJointCommissions() {
    const operatingUnit = this.getOperatingUnit();
    if (operatingUnit !== null) {
      this.getJointCommissionsBySectorId(operatingUnit.sectorId);
    }
  }

  private getOperatingUnit() {
    const operatingUnitId =
      this.customer.operatingUnitId
        ? this.customer.operatingUnitId
        : this.authorizationService.getCurrentUser().operatingUnitId;
    return this.operatingUnits.find(oU => oU.id === operatingUnitId);
  }

  private buildBusinessContact(businessContact: BusinessContact) {
    return this.formBuilder.group({
      function: [businessContact.function, []],
      name: [businessContact.name, []],
      email: [businessContact.email, [emailValidator]],
      phone: [businessContact.phone, []],
      fax: [businessContact.fax, []],
      mobile: [businessContact.mobile, []],
      languageCode: [businessContact.languageCode, []],
      contactMethod: [businessContact.contactMethod, []],
      civility: [businessContact.civility, []],
      businessArea: [businessContact.businessArea, []]
    });
  }

  private buildAddress(address: Address): UntypedFormGroup {
    return this.formBuilder.group({
      street: [address.street ? address.street : null, [Validators.required]],
      number: [address.number ? address.number : null, [Validators.required]],
      box: [address.box ? address.box : null, []],
      zip: [address.zip ? address.zip : null, [Validators.required]],
      city: [address.city ? address.city : null, [Validators.required]],
      country: [address.country ? address.country : null, [Validators.required]]
    });
  }

  private getFormValidationErrors(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.get(key) instanceof UntypedFormGroup) {
        this.getFormValidationErrors(form.get(key) as UntypedFormGroup);
      }
    });
  }
}
