import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FacadeService} from '../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ExportDialogComponent, ExportDialogParam} from '../shared/component/export-dialog/export-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Customer} from './shared/model/customer.model';
import {CustomerPage} from '../shared/model/pagination/customer/customer-page.model';
import {checkIfValidSearchTerm} from '../shared/model/pagination/pagination.model';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  displayedColumns: string[] = ['statusColor', 'customerNumber', 'customerName', 'pendingRequests', 'customerStatus'];
  customersTable = new MatTableDataSource<Customer>();
  @ViewChild('customersPaginator', {static: true}) paginator: MatPaginator;

  pageEvent: PageEvent;
  pageIndex: number;
  previousIndex = 0;
  pageSize: number;
  query: string;
  length: number;
  wasInFilter = false;
  searchForm: UntypedFormGroup;

  constructor(private dialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private facadeService: FacadeService,
              private translate: TranslateService,
              private snackBar: MatSnackBar,
              public route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.pageIndex = this.route.snapshot.queryParams['pageIndex'] ?? 0;
    this.pageSize = this.route.snapshot.queryParams['pageSize'] ?? 20;

    this.query = this.route.snapshot.queryParams['search'];

    const customersFromRoute: CustomerPage = this.route.snapshot.data['customers'];
    this.length = customersFromRoute.totalElements;
    this.customersTable = new MatTableDataSource<Customer>(customersFromRoute.content);
    this.customersTable.paginator = this.paginator;
    this.wasInFilter = checkIfValidSearchTerm(this.query);


    this.searchForm = this.formBuilder.group({
      query: [this.query, []],
    });

    this.searchForm.get('query').valueChanges.subscribe(value => {
      this.searchCustomer(value);
    });
  }

  handleChangePagination(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getEventCustomerData();
    return event;
  }

  getEventCustomerData() {
    this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: {pageIndex: this.pageIndex, pageSize: this.pageSize, search: this.query}
      }
    );
    this.facadeService.getPageCustomer(this.pageIndex, this.pageSize, {query: this.query}).subscribe(
      (res: CustomerPage) => {
        this.customersTable.data = res.content;
        this.length = res.totalElements;
      }
    );
  }

  searchCustomer(value: string) {
    if (checkIfValidSearchTerm(value)) {
      this.query = value;
      if (!this.wasInFilter) {
        this.previousIndex = this.pageIndex;
        this.pageIndex = 0;
      }
      this.wasInFilter = true;
    } else {
      this.query = null;
      if (!this.wasInFilter) {
        return;
      }
      this.pageIndex = this.previousIndex;
      this.wasInFilter = false;
    }
    this.getEventCustomerData();
  }

  export() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.genericExport = true;
    param.customerExport = true;

    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});
    dialogRef.afterClosed().subscribe(
      result => param.saveFile(this.facadeService.getCustomerToExport(
        result.contractStartDate,
        result.contractEndDate,
        result.operatingUnitIds)));
  }

  openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: [pC]
    });
  }

}
