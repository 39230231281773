import {PaymentTerms, UncomfortableWorkingHours} from '../../../customers/shared/model/customer.model';

export class JointCommissionCoefficient {
  id: number;
  jointCommissionCode: string;
  paymentTerms: PaymentTerms;
  uncomfortableWorkingHours: UncomfortableWorkingHours;
  rtt: boolean;
  nightShiftWorkType: string;
  riskedActivity: boolean;
  active: boolean;
}
