import {Component, Inject} from '@angular/core';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {NgForm} from '@angular/forms';
import {Document, TypeFileCustomer, TypeFileWorker} from '../../../shared/model/document/document.model';
import {AuthorizationService} from '../../../login/shared/service/authorization.service';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent {
  fileToUpload: File = null;
  typesFile = [];
  isMedicalAttestation = false;

  constructor(private dialogRef: MatDialogRef<UploadDocumentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private authorizationService: AuthorizationService,
              private datePipe: DatePipe,
              private facadeService: FacadeService,
              private translate: TranslateService) {
    if (data.worker) {
      Object.keys(TypeFileWorker).forEach(e => {
        if (e !== TypeFileWorker.PENDING) {
          this.typesFile.push(e);
        }
      });
    } else {
      Object.keys(TypeFileCustomer).forEach(e => {
        this.typesFile.push(e);
      });
    }
  }

  closeDialog(update = false): void {
    this.dialogRef.close(update);
  }

  uploadDocument(form: NgForm) {
    if (form.valid) {
      const doc: Document = new Document();
      doc.consultant = this.authorizationService.getCurrentUser().username;
      doc.typeFile = form.value.typeFile;
      doc.comment = form.value.comment;
      let startDate = form.value.startDate;
      let endDate = form.value.endDate;
      if (startDate && endDate) {
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        if (startDate.getTime() > endDate.getTime()) {
          return alert(this.translate.instant('workers.view.documents.upload.dialog.message.error.upload.date'));
        }
        doc.endDate = endDate;
        doc.startDate = startDate;
      }
      if (this.data.worker) {
        this.uploadWorkerDocument(doc);
      } else {
        this.uploadCustomerDocument(doc);
      }
    }
  }

  selectionChangeTypeFile(event: any) {
    this.isMedicalAttestation = (event.value === TypeFileWorker.MEDICAL_ATTESTATION);
  }

  handleFileInput(target) {
    this.fileToUpload = target.files.item(0);
  }

  private uploadWorkerDocument(doc: Document) {
    this.facadeService.uploadDocumentByWorkerId(this.data.worker.id, doc, this.fileToUpload).subscribe({
      next: ignoreProperty => {
        this.closeDialog(true);
      },
      error: ignoreProperty => {
        return alert(this.translate.instant('workers.view.documents.upload.dialog.message.error.upload.save'));
      }
    });
  }

  private uploadCustomerDocument(doc: Document) {
    this.facadeService.uploadDocumentByCustomerId(this.data.customer.id, doc, this.fileToUpload).subscribe({
      next: ignoreProperty => {
        this.closeDialog(true);
      },
      error: ignoreProperty => {
        return alert(this.translate.instant('workers.view.documents.upload.dialog.message.error.upload.save'));
      }
    });
  }
}
