import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Customer} from '../model/customer.model';
import {CustomerSerializer} from '../model/customer.serializer';
import {map} from 'rxjs/operators';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {CustomerPage} from '../../../shared/model/pagination/customer/customer-page.model';
import {CustomerPageSerializer} from '../../../shared/model/pagination/customer/customer-page.serializer';
import {DatabeInfo} from '../model/databe/databe-info.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private serviceURL = environment.customer_service_URL + 'customers';
  private authUrl = environment.customer_service_URL;

  private customerSerializer: CustomerSerializer = new CustomerSerializer();
  private customerPageSerializer: CustomerPageSerializer = new CustomerPageSerializer();

  constructor(private http: HttpClient) {
  }

  get(id: number): Observable<any> {
    return this.http.get<Customer>(`${this.serviceURL}/${id}`).pipe(
      map((data: Customer) => this.customerSerializer.fromJson(data))
    );
  }

  list(): Observable<any> {
    return this.http.get<Customer[]>(this.serviceURL).pipe(
      map((data: Customer[]) => this.convertData(data))
    );
  }

  getPageCustomer(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<CustomerPage>(this.serviceURL + '/page', {params}).pipe(
      map((data: CustomerPage) => this.customerPageSerializer.fromJson(data))
    );
  }

  listActive(): Observable<any> {
    return this.http.get<Customer[]>(`${this.serviceURL}/select`).pipe(
      map((data: Customer[]) => this.convertData(data))
    );
  }

  save(customer: Customer): Observable<any> {
    return this.http.post<Customer>(this.serviceURL, this.customerSerializer.toJson(customer)).pipe(
      map((data: Customer) => this.customerSerializer.fromJson(data))
    );
  }

  activate(customer: Customer): Observable<any> {
    customer.active = true;
    return this.save(customer);
  }

  deactivate(customer: Customer): Observable<any> {
    return this.http.delete(this.serviceURL + '/' + customer.id);
  }

  export(startDate: Date, endDate: Date, operationUnitIds: Array<number>): Observable<any> {
    // todo : remove this method
    const dateSerializer = new DateSerializer();

    const params = new HttpParams({
      fromObject: {
        operationUnitIds: String(operationUnitIds),
        ...startDate ? {startDate: dateSerializer.toJson(startDate)} : {},
        ...endDate ? {endDate: dateSerializer.toJson(endDate)} : {}
      }
    });

    return this.http.get(
      this.serviceURL + '/export',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }


  getInfo(vatNumber: string): Observable<any> {

    let params = new HttpParams();
    params = params.append('vatNumber', vatNumber);

    return this.http.get<DatabeInfo>(this.serviceURL + '/company-info', {params});
  }

  private convertData(data: any): Customer[] {
    return data.map(customer => this.customerSerializer.fromJson(customer));
  }
}
