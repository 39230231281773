import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Prestation} from '../shared/model/prestation.model';
import {MatTableDataSource} from '@angular/material/table';
import {PrestationPeriod, PrestationPeriodStatus} from '../shared/model/prestation-period.model';
import {FacadeService} from '../../shared/service/facade/facade.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {AuthorizationService} from '../../login/shared/service/authorization.service';
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';
import {PrestationPage} from '../../shared/model/pagination/prestation/prestation-page.model';

@Component({
  selector: 'app-prestation-payroll-released',
  templateUrl: './prestation-payroll-released.component.html',
  styleUrls: ['./prestation-payroll-released.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PrestationPayrollReleasedComponent implements OnInit {

  @ViewChild('prestationsPaginator', {static: true}) paginator: MatPaginator;
  displayedPrestationColumns: string[] = ['title'];
  displayedPrestationPeriodColumns: string[] =
    ['statusColor', 'number', 'plannedStartDate', 'actualStartDate', 'total', 'status'];
  expandedElement: Prestation | null;
  prestationDataSource = new MatTableDataSource<Prestation>();
  prestationPeriodDataSource = new MatTableDataSource<PrestationPeriod>();
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;

  constructor(private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private translate: TranslateService,
              private authService: AuthorizationService,
              private route: ActivatedRoute,
              private datepipe: DatePipe) {
  }

  get PrestationPeriodStatus() {
    return PrestationPeriodStatus;
  }

  @Input() set prestations(p: PrestationPage) {
    this.setPrestationDataSource(p);
  }

  ngOnInit() {
  }

  setPrestationDataSource(prestations: PrestationPage) {
    this.pageIndex = 0;
    this.pageSize = 25;
    this.prestationDataSource = new MatTableDataSource<Prestation>(prestations.content);
    this.prestationDataSource.paginator = this.paginator;
    this.length = prestations.totalElements;
    const id = +this.route.snapshot.queryParamMap.get('id');
    const index = +this.route.snapshot.queryParamMap.get('index');
    if (id != null && index != null) {
      this.pageIndex = Math.floor(index / this.pageSize);
      this.setPrestationReleased(this.pageIndex, this.pageSize, id);
    }
  }

  public getServerData(event?: PageEvent) {
    this.setPrestationReleased(event.pageIndex, event.pageSize);
    return event;
  }

  setExpandedElement(element: Prestation) {
    if (this.expandedElement === element) {
      return this.expandedElement = null;
    }
    this.expandedElement = element;
    this.setPrestationPeriodDataSource(element.periods);
  }

  setPrestationPeriodDataSource(prestationPeriods: PrestationPeriod[]) {
    this.prestationPeriodDataSource = new MatTableDataSource<PrestationPeriod>(prestationPeriods);
  }

  getWeekNumber(prestation: Prestation): string {
    const wn = this.datepipe.transform(prestation.startDate, 'ww');
    if (wn === '00') {
      return this.datepipe.transform(new Date(Date.UTC(prestation.startDate.getFullYear() - 1, 11, 31)), 'ww');
    } else {
      return wn;
    }
  }

  private setPrestationReleased(index: number, pageSize: number, id?: number) {
    this.facadeService.getPagePrestationReleased(index, pageSize).subscribe(
      (data: PrestationPage) => {
        this.prestationDataSource.data = data.content;
        this.pageIndex = index;
        this.pageSize = pageSize;
        if (id) {
          this.setExpandedElement(data.content.find(prestation => prestation.id === id));
        } else {
          this.expandedElement = null;
        }
      }
    );
  }
}
