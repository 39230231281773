import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { JointCommission } from 'src/app/shared/model/joint-commission/joint-commission.model';
import { WorkFunction } from 'src/app/shared/model/work-function/work-function.model';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';


@Component({
  selector: 'app-work-function-dialog',
  templateUrl: './work-function-dialog.component.html',
  styleUrls: ['./work-function-dialog.component.css']
})
export class WorkFunctionDialogComponent implements OnInit {

  workFunction: WorkFunction;
  jointCommissionList: JointCommission[];
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<WorkFunction>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {
    this.workFunction = this.data.element;
    this.jointCommissionList = this.data.jcList;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: [this.workFunction.id? this.workFunction.id : null, []],
      active: [this.workFunction.active ? this.workFunction.active : true, [Validators.required]],
      jointCommissionId: [this.workFunction.jointCommission.id ? this.workFunction.jointCommission.id : 302, [Validators.required]],
      category: [this.workFunction.category ? this.workFunction.category : 0, [Validators.required]],
      code: [this.workFunction.code ? this.workFunction.code : "", [Validators.required]],
      nameFR: [this.workFunction.nameFR ? this.workFunction.nameFR : "", [Validators.required]],
      nameEN: [this.workFunction.nameEN ? this.workFunction.nameEN : "", [Validators.required]],
      nameNL: [this.workFunction.nameNL ? this.workFunction.nameNL : "", [Validators.required]],
      nameDE: [this.workFunction.nameDE ? this.workFunction.nameDE : "", [Validators.required]],
    }
    );
  }

  setJointCommission(jointCommissionId: number) {
    for (let i = 0; i < this.jointCommissionList.length; i++) {
        if (this.jointCommissionList[i].id === jointCommissionId) {
            return this.jointCommissionList[i];
        }
    }
  }

  onSubmit() {
    Object.assign(this.workFunction, this.form.value);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.getFormValidationErrors(this.form);
      return this.openSnackBar(this.translate.instant('popup.form-invalid'), 'X', 'customSnackError');
    }

    this.workFunction.jointCommission = this.setJointCommission(this.form.value.jointCommissionId);

    this.facade.saveWorkFunction(this.workFunction)
      .subscribe({
        next: workFunction => {
          this.openSnackBar(this.translate.instant('popup.save-success', { id: workFunction.id }), 'X', 'customSnackValid');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(
            this.translate.instant('popup.save-error', { error: error.message }),
            'X', 'customSnackError');
        }
      });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.get(key) instanceof FormGroup) {
        this.getFormValidationErrors(form.get(key) as FormGroup);
      }
    });
  }

  cancel(): void {
    this.form.reset();
    this.dialogRef.close();
  }

}

