import {Serializer} from '../serializer';
import {Dimona, DimonaPeriod} from './dimona.model';
import {DateSerializer} from '../date.serializer';
import {DimonaPeriodSerializer} from './dimona-period.serializer';

export class DimonaSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();
  private dimonaPeriodSerializer: DimonaPeriodSerializer = new DimonaPeriodSerializer();

  fromJson(json: any): Dimona {
    const dimona: Dimona = new Dimona();
    const dimonaPeriods: DimonaPeriod[] = [];

    dimona.id = json.id;
    dimona.parentId = json.parentId;
    dimona.dimonaType = json.dimonaType;
    dimona.dimonaWorkerType = json.dimonaWorkerType;
    dimona.jointCommission = json.jointCommission;
    dimona.sequenceNumber = json.sequenceNumber;
    dimona.senderNumber = json.senderNumber;
    dimona.referenceNumber = json.referenceNumber;
    dimona.startDate = this.dateSerializer.fromJson(json.startDate);
    dimona.endDate = this.dateSerializer.fromJson(json.endDate);
    dimona.requestId = json.requestId;
    dimona.operatingUnitId = json.operatingUnitId;
    dimona.contractId = json.contractId;
    dimona.operatingUnitOnssNumber = json.operatingUnitOnssNumber;
    dimona.customerId = json.customerId;
    dimona.customerEnterpriseNumber = json.customerEnterpriseNumber;
    dimona.workerId = json.workerId;
    dimona.workerNiss = json.workerNiss;
    dimona.dimonaStatus = json.dimonaStatus;
    dimona.errorCodes = json.errorCodes;
    dimona.active = json.active;

    const dimonaPeriodsJson = json.dimonaPeriods;
    if (dimonaPeriodsJson) {
      dimonaPeriodsJson.forEach(
        dimonaPeriod => {
          dimonaPeriods.push(this.dimonaPeriodSerializer.fromJson(dimonaPeriod));
        }
      );
    }
    dimona.dimonaPeriods = dimonaPeriods;

    return dimona;
  }

  toJson(dimona: Dimona): any {
    const dimonaPeriods: any [] = [];
    dimona.dimonaPeriods.forEach(
      dimonaPeriod => {
        dimonaPeriods.push(this.dimonaPeriodSerializer.toJson(dimonaPeriod));
      }
    );

    return {
      id: dimona.id,
      parentId: dimona.parentId,
      dimonaType: dimona.dimonaType,
      dimonaWorkerType: dimona.dimonaWorkerType,
      jointCommission: dimona.jointCommission,
      sequenceNumber: dimona.sequenceNumber,
      senderNumber: dimona.senderNumber,
      referenceNumber: dimona.referenceNumber,
      startDate: this.dateSerializer.dateWithTimeToJson(dimona.startDate),
      endDate: this.dateSerializer.dateWithTimeToJson(dimona.endDate),
      requestId: dimona.requestId,
      operatingUnitId: dimona.operatingUnitId,
      contractId: dimona.contractId,
      operatingUnitOnssNumber: dimona.operatingUnitOnssNumber,
      customerId: dimona.customerId,
      customerEnterpriseNumber: dimona.customerEnterpriseNumber,
      workerId: dimona.workerId,
      workerNiss: dimona.workerNiss,
      dimonaStatus: dimona.dimonaStatus,
      errorCodes: dimona.errorCodes,
      active: dimona.active,
      dimonaPeriod: dimonaPeriods
    };
  }
}
