import {Serializer} from 'src/app/shared/model/serializer';
import {Country} from './country.model';

export class CountrySerializer implements Serializer {
  fromJson(json: any): Country {
    const country: Country = new Country();

    country.id = json.id;
    country.active = json.active;
    country.code = json.code;
    country.nameDE = json.nameDE;
    country.nameEN = json.nameEN;
    country.nameFR = json.nameFR;
    country.nameNL = json.nameNL;
    country.isEuropean = json.isEuropean;
    return country;
  }

  toJson(country: Country): any {
    return {
      id: country.id,
      active: country.active,
      code: country.code,
      nameDE: country.nameDE,
      nameEN: country.nameEN,
      nameFR: country.nameFR,
      nameNL: country.nameNL,
      isEuropean: country.isEuropean
    };
  }
}
