import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Worker} from '../model/worker.model';
import {map} from 'rxjs/operators';
import {WorkerSerializer} from '../model/woker.serializer';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {WorkerPage} from '../../../shared/model/pagination/worker/worker-page.model';
import {WorkerPageSerializer} from '../../../shared/model/pagination/worker/worker-page.serializer';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {
  private serviceURL = environment.worker_service_URL + 'workers';
  private authUrl = environment.customer_service_URL;

  private workerSerializer: WorkerSerializer = new WorkerSerializer();
  private workerPageSerializer: WorkerPageSerializer = new WorkerPageSerializer();

  private dateSerializer: DateSerializer = new DateSerializer();

  constructor(private http: HttpClient) {
  }

  get(id: number): Observable<any> {
    return this.http.get<Worker>(this.serviceURL + '/' + id).pipe(
      map((data: Worker) => this.workerSerializer.fromJson(data))
    );
  }

  existByEmail(email: string): Observable<any> {
    return this.http.get<boolean>(this.serviceURL + '/email/' + email);
  }

  existByNiss(niss: string): Observable<any> {
    return this.http.get<boolean>(this.serviceURL + '/niss/' + niss);
  }

  list(): Observable<any> {
    return this.http.get<Worker[]>(this.serviceURL).pipe(
      map((data: Worker[]) => this.convertData(data))
    );
  }

  getPage(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<WorkerPage>(this.serviceURL + '/page', {params}).pipe(
      map((data: WorkerPage) => this.workerPageSerializer.fromJson(data))
    );
  }

  listActive(): Observable<any> {
    return this.http.get<Worker[]>(this.serviceURL + '/select').pipe(
      map((data: Worker[]) => this.convertData(data))
    );
  }

  save(worker: Worker): Observable<any> {
    return this.http.post<Worker>(this.serviceURL, this.workerSerializer.toJson(worker)).pipe(
      map((data: Worker) => this.workerSerializer.fromJson(data))
    );
  }

  delete(worker: Worker): Observable<any> {
    return this.http.delete<Worker>(this.serviceURL + '/' + worker.id).pipe(
      map((data: Worker) => this.workerSerializer.fromJson(data))
    );
  }

  activate(worker: Worker): Observable<any> {
    worker.active = true;
    return this.save(worker);
  }

  deactivate(worker: Worker): Observable<any> {
    worker.active = false;
    return this.save(worker);
  }

  export(startDate: Date, endDate: Date, operationUnitIds: Array<number>): Observable<any> {

    const params = new HttpParams({
      fromObject: {
        operationUnitIds: String(operationUnitIds),
        ...startDate ? {startDate: this.dateSerializer.toJson(startDate)} : {},
        ...endDate ? {endDate: this.dateSerializer.toJson(endDate)} : {}
      }
    });

    return this.http.get(
      this.serviceURL + '/export',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  private convertData(data: any): Worker[] {
    return data.map(worker => this.workerSerializer.fromJson(worker));
  }
}
