import {AbstractControl} from '@angular/forms';

export function emailValidator(control: AbstractControl) {

  const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (control && (control.value === null || control.value === '')) {
    return null;
  }

  if (control && (control.value !== null || true)) {
    const regex = new RegExp(EMAIL_PATTERN);

    if (!regex.test(control.value)) {
      return {
        isError: true
      };
    }
  }

  return null;
}
