import { FacadeService } from './../../../service/facade/facade.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SalaryScale } from '../../../../admin/model/salary-scale.model';

@Component({
  selector: 'app-salary-scale-dialog',
  templateUrl: './salary-scale-dialog.component.html',
  styleUrls: ['./salary-scale-dialog.component.css']
})
export class SalaryScaleDialogComponent implements OnInit {

  salaryScale: SalaryScale;
  jointCommissionCodeList: string[];
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SalaryScaleDialogComponent>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
  ) {
    this.salaryScale = this.data.element;
    this.jointCommissionCodeList = this.data.jcCodeList;
  }

  ngOnInit(): void {
    this.initForm();
  }


  initForm() {
    this.form = this.formBuilder.group({
      id: [this.salaryScale.id ? this.salaryScale.id : null, []],
      active: [this.salaryScale.active ? this.salaryScale.active : true, [Validators.required]],
      category: [this.salaryScale.category ? this.salaryScale.category : 0, [Validators.required]],
      jointCommissionCode: [this.salaryScale.jointCommissionCode ? this.salaryScale.jointCommissionCode : 0, [Validators.required]],
      seniority: [this.salaryScale.seniority ? this.salaryScale.seniority : 0, [Validators.required]],
      value: [this.salaryScale.value ? this.salaryScale.value : 0, [Validators.required]],
    });
  }

  onSubmit() {
    Object.assign(this.salaryScale, this.form.value);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.getFormValidationErrors(this.form);
      return this.openSnackBar(this.translate.instant('popup.form-invalid'), 'X', 'customSnackError');
    }

    this.facade.saveSalaryScale(this.salaryScale)
      .subscribe({
        next: salaryScale => {
          this.openSnackBar(this.translate.instant('popup.save-success', { id: salaryScale.id }), 'X', 'customSnackValid');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(
            this.translate.instant('popup.save-error', { error: error.message }),
            'X', 'customSnackError');
        }
      });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.get(key) instanceof FormGroup) {
        this.getFormValidationErrors(form.get(key) as FormGroup);
      }
    });
  }

  cancel(): void {
    this.form.reset();
    this.dialogRef.close();
  }

  clearDate(): void {
    this.form.controls.date.setValue('');
  }

}
