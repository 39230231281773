import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';
import { ValidationDialogComponent } from 'src/app/shared/component/admin-dialog/validation-dialog/validation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-generation',
  templateUrl: './generation.component.html',
  styleUrls: ['./generation.component.css', '../../admin.component.css']
})
export class GenerationComponent implements OnInit {

  clickedPTG = false;
  clickedInvoices = false;

  constructor(private facadeService: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  generatePtg() {
    this.clickedPTG = true;
    this.facadeService.generatePtg().subscribe((element) => {
      console.log('generation done')
    });
    this.openSnackBar(this.translate.instant('admin.tab.generation.generation-popup'), 'X', 'customSnackValid');
  }

  generateWeeklyInvoices(): void {
    this.clickedInvoices = true;
    this.facadeService.generateWeeklyInvoices().subscribe((element) => {
      console.log('generation done')
    });
    this.openSnackBar(this.translate.instant('admin.tab.generation.generation-popup'), 'X', 'customSnackValid');
  }

  generateMonthlyInvoices(): void {
    this.clickedInvoices = true;
    this.facadeService.generateMonthlyInvoices().subscribe((element) => {
      console.log('generation done')
    });
    this.openSnackBar(this.translate.instant('admin.tab.generation.generation-popup'), 'X', 'customSnackValid');
  }

  openPopUp() {
      const dialogRef = this.dialog.open(ValidationDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        this.clickedPTG = true;
      })
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });

  }
}
