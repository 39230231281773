import {Component, OnInit, ViewChild} from '@angular/core';
import {WorkerLite} from './shared/model/worker-lite.model';
import {FacadeService} from '../shared/service/facade/facade.service';
import {ActivatedRoute} from '@angular/router';
import {ConfirmationDialogComponent} from '../shared/component/confirmation-dialog/confirmation-dialog.component';
import {AuthorizationService} from '../login/shared/service/authorization.service';
import {Role} from '../users/shared/model/user.model';
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {WorkerLitePage} from '../shared/model/pagination/worker/worker-lite-page.model';

@Component({
  selector: 'app-workers-lite',
  templateUrl: './workers-lite.component.html',
  styleUrls: ['./workers-lite.component.css']
})
export class WorkersLiteComponent implements OnInit {
  displayedColumns: string[] = ['statusColor', 'id', 'lastName', 'firstName', 'email', 'delete', 'create'];
  workersLiteTable = new MatTableDataSource<WorkerLite>();
  @ViewChild('workersLitePaginator', {static: true}) paginator: MatPaginator;
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;

  constructor(private facadeService: FacadeService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private authorizationService: AuthorizationService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    const wL: WorkerLitePage = this.route.snapshot.data['workersLite'];
    this.pageIndex = 0;
    this.pageSize = 20;
    this.setMatTableDataSource(wL);
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  delete(workerLite: WorkerLite) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.facadeService.deactivateWorkerLite(workerLite.id)
          .subscribe({
            next: ignore => {
              this.facadeService.getPageWorkerLite(0, 20).subscribe(
                (data: WorkerLitePage) => {
                  this.setMatTableDataSource(data);
                  alert(this.translate.instant('workersLite.message.success.delete'));
                }
              );
            },
            error: () => {
              alert(this.translate.instant('workersLite.message.error.delete'));
            }
          });
      }
    });
  }

  getEventWorkerLiteData(event?: PageEvent) {
    this.facadeService.getPageWorkerLite(event.pageIndex, event.pageSize).subscribe(
      (data: WorkerLitePage) => {
        this.workersLiteTable.data = data.content;
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
    );
    return event;
  }

  private setMatTableDataSource(workerLites: WorkerLitePage) {
    this.workersLiteTable = new MatTableDataSource<WorkerLite>(workerLites.content);
    this.length = workerLites.totalElements;
    this.workersLiteTable.paginator = this.paginator;
  }
}
