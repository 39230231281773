import {Component, OnInit, ViewChild} from '@angular/core';
import {Request} from '../requests/shared/model/request.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FacadeService} from '../shared/service/facade/facade.service';
import {Prestation} from '../prestations/shared/model/prestation.model';
import {AuthorizationService} from '../login/shared/service/authorization.service';
import {Role} from '../users/shared/model/user.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {RequestPage} from '../shared/model/pagination/request/request-page.model';
import {PrestationPage} from '../shared/model/pagination/prestation/prestation-page.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  lengthErrors: number;
  errorRequestPage: RequestPage;
  prestationsDisplayedColumns: string[] = ['prestationId', 'customerId', 'workerId', 'startDate'];
  prestationsDataSource = new MatTableDataSource<Prestation>();
  @ViewChild('prestationsPaginator', {static: true}) prestationsPaginator: MatPaginator;
  pageEventToValidated: PageEvent;
  pageIndexToValidated: number;
  pageSizeToValidated: number;
  lengthToValidated: number;
  pendingRequestsDisplayedColumns: string[] = ['requestId', 'customerId', 'workerId', 'startDate'];
  pendingRequestsDataSource = new MatTableDataSource<Request>();
  @ViewChild('pendingRequestsPaginator', {static: true}) pendingRequestsPaginator: MatPaginator;
  pageEventPending: PageEvent;
  pageIndexPending: number;
  pageSizePending: number;
  lengthPending: number;

  constructor(private route: ActivatedRoute,
              private facadeService: FacadeService,
              private authorizationService: AuthorizationService,
              private router: Router) {
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  ngOnInit() {
    this.pageIndexPending = 0;
    this.pageSizePending = 5;
    this.getAllPendingRequests();

    this.pageIndexToValidated = 0;
    this.pageSizeToValidated = 5;
    this.getAllPrestationsToValidated();

    if (this.hasRole()) {
      this.errorRequestPage = this.getAllRequestsWithError();
    }
  }

  public getEventDeadlineData(event?: PageEvent) {
    this.facadeService.getPageDeadlineRequests(event.pageIndex, event.pageSize).subscribe({
      next:
        (data: RequestPage) => {
          this.pendingRequestsDataSource.data = data.content;
          this.pageIndexPending = event.pageIndex;
          this.pageSizePending = event.pageSize;
        },
      error: ignoreProperty => {
        // alert(this.translate.instant('requests.message.error.requests'));
      }
    });
    return event;
  }

  public getEventToValidateData(event?: PageEvent) {
    this.facadeService.getPagePrestationToValidate(event.pageIndex, event.pageSize, {}).subscribe(
      (data: PrestationPage) => {
        this.prestationsDataSource.data = data.content;
        this.pageIndexToValidated = event.pageIndex;
        this.pageSizeToValidated = event.pageSize;
      }
    );
    return event;
  }

  public navigate(id: number) {
    this.facadeService.findIndexToRelease(+id, 25).subscribe(
      (data: number) => {
        this.router.navigate(['prestations'], {queryParams: {id: id, index: data}}).then();
      });
  }

  private getAllPrestationsToValidated() {
    const prestations: PrestationPage = this.route.snapshot.data['prestationsToValidate'];
    this.prestationsDataSource = new MatTableDataSource<Prestation>(prestations.content);
    this.lengthToValidated = prestations.totalElements;
    this.prestationsDataSource.paginator = this.prestationsPaginator;
  }

  private getAllPendingRequests() {
    const pendingRequest: RequestPage = this.route.snapshot.data['deadlineRequests'];
    this.pendingRequestsDataSource = new MatTableDataSource<Request>(pendingRequest.content);
    this.lengthPending = pendingRequest.totalElements;
    this.pendingRequestsDataSource.paginator = this.pendingRequestsPaginator;
  }

  private getAllRequestsWithError(): RequestPage {
    const errorRequests: RequestPage = this.route.snapshot.data['errorRequests'];
    this.lengthErrors = errorRequests.totalElements;
    return errorRequests;
  }
}
