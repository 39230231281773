import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-export-dimonas',
  templateUrl: './export-dimonas.component.html',
  styleUrls: ['./export-dimonas.component.css']
})
export class ExportDimonasComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.form.addControl('declarationStartDate', new UntypedFormControl());
    this.form.addControl('declarationEndDate', new UntypedFormControl());
  }

  clearDeclarationStartDate() {
    this.form.controls.declarationStartDate.setValue('');
  }

  clearDeclarationEndDate() {
    this.form.controls.declarationEndDate.setValue('');
  }

}
