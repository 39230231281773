import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PrestationPeriod, PrestationPeriodStatus} from '../shared/model/prestation-period.model';
import {Prestation} from '../shared/model/prestation.model';
import {PrestationDialogComponent} from './prestation-dialog/prestation-dialog.component';
import {FacadeService} from '../../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {AuthorizationService} from '../../login/shared/service/authorization.service';
import {PrestationRejectDialogComponent} from './prestation-reject-dialog/prestation-reject-dialog.component';
import {ExportDialogComponent, ExportDialogParam} from '../../shared/component/export-dialog/export-dialog.component';
import {ActivatedRoute} from '@angular/router';
import {PrestationPeriodCode} from '../shared/model/prestation-period-code.model';
import {PrestationPage} from '../../shared/model/pagination/prestation/prestation-page.model';

@Component({
  selector: 'app-prestation-validation',
  templateUrl: './prestation-validation.component.html',
  styleUrls: ['./prestation-validation.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PrestationValidationComponent implements OnInit {
  @ViewChild('prestationsPaginator', {static: true}) paginator: MatPaginator;
  disabledSendReminderBtn = false;
  displayedPrestationColumns: string[] = ['title'];
  displayedPrestationPeriodCodeColumns: string[] = ['code', 'description', 'number', 'days', 'rate', 'percentage',
    'coefficient', 'total'];
  displayedPrestationPeriodColumns: string[] = [];
  expandedPrestation: Prestation | null;
  prestationPeriodSelected: PrestationPeriod | null;
  prestationDataSource = new MatTableDataSource<Prestation>();
  prestationPeriodDataSource = new MatTableDataSource<PrestationPeriod>();
  prestationPeriodCodeDataSource = new MatTableDataSource<PrestationPeriodCode>();
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;
  @Input() requestId: number;
  private displayedPrestationPeriodColumnsHoreca: string[] =
    ['statusColor', 'number', 'plannedStartDate', 'actualStartDate', 'total', 'customerValidation', 'status'];
  private displayedPrestationPeriodColumnsNursing: string[] =
    ['statusColor', 'number', 'plannedStartDate', 'actualStartDate', 'total', 'workerValidationHours', 'customerValidationHours', 'status',
      'validate', 'reject'];

  constructor(private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private translate: TranslateService,
              private authService: AuthorizationService,
              private route: ActivatedRoute) {
    //  sectorID === 1 pour HORECA
    this.displayedPrestationPeriodColumns = (this.authService.getCurrentUser().operatingUnit.sectorId === 1)
      ? this.displayedPrestationPeriodColumnsHoreca : this.displayedPrestationPeriodColumnsNursing;
  }

  get PrestationPeriodStatus() {
    return PrestationPeriodStatus;
  }

  @Input() set prestations(p: PrestationPage) {
    this.setPrestationDataSource(p);
  }

  ngOnInit() {
  }

  setPrestationDataSource(prestations: PrestationPage) {
    this.pageIndex = 0;
    this.pageSize = 25;
    if (this.requestId) {
      this.setExpandedElement(prestations.content[0]);
    } else {
      this.length = prestations.totalElements;
    }
    this.prestationDataSource = new MatTableDataSource<Prestation>(prestations.content);
    this.prestationDataSource.paginator = this.paginator;
    const id = +this.route.snapshot.queryParamMap.get('id');
    const index = +this.route.snapshot.queryParamMap.get('index');
    if (id != null && index != null && index !== 0) {
      this.pageIndex = index;
      this.setPrestationToValidate(this.pageIndex, this.pageSize, id);
    }
  }

  public getServerData(event?: PageEvent) {
    this.setPrestationToValidate(event.pageIndex, event.pageSize);
    return event;
  }

  setPrestationPeriodSelected(period: PrestationPeriod) {
    if (period === this.prestationPeriodSelected) {
      return this.prestationPeriodSelected = null;
    }
    this.prestationPeriodSelected = period;
    this.setPrestationPeriodCodeDataSource(period.prestationPeriodCodes);
  }

  setPrestationPeriodDataSource(prestationPeriods: PrestationPeriod[]) {
    this.prestationPeriodDataSource = new MatTableDataSource<PrestationPeriod>(prestationPeriods);
  }

  rejectPeriod(prestation: Prestation) {
    const dialogRef = this.dialog.open(PrestationRejectDialogComponent, {
      data: {
        prestationPeriod: this.prestationPeriodSelected,
        prestation: prestation
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.expandedPrestation = null;
        this.openSnackBar(this.translate.instant('prestations.validation.message.success.validatePeriod'));
        this.refreshPrestationToValidate();
      }
    });
  }

  validatePeriod(prestation: Prestation) {
    this.facadeService.getPrestationByRequestId(prestation.requestId).subscribe({
      next: (prestations: Prestation[]) => {
        const dialogRef = this.dialog.open(PrestationDialogComponent, {
          data: {
            prestationPeriod: this.prestationPeriodSelected,
            prestation: prestation,
            allPrestationForRequestId: prestations
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.expandedPrestation = null;
            this.openSnackBar(this.translate.instant('prestations.validation.message.success.validatePeriod'));
            this.refreshPrestationToValidate();
          }
        });
      },
      error: () => {
      }
    });


  }

  refreshPrestationToValidate() {
    if (this.requestId) {
      this.facadeService.getPrestationByRequestId(this.requestId).subscribe({
        next: (p: Prestation[]) => {
          this.prestationDataSource.data = p;
          this.setExpandedElement(p[0]);
        },
        error: () => {
        }
      });
    } else {
      this.facadeService.getPagePrestationToRelease(this.pageIndex, this.pageSize).subscribe({
        next: (p: PrestationPage) => {
          this.setPrestationDataSource(p);
        },
        error: () => {
        }
      });
    }
  }

  validatePrestation(prestation: Prestation) {
    this.facadeService.validatePrestation(prestation)
      .subscribe({
        next: ignoreProperty => {
          this.openSnackBar(this.translate.instant('prestations.validation.message.success.validate'));
          this.refreshPrestationToValidate();
        },
        error: () => {
          return this.openSnackBar(this.translate.instant('prestations.validation.message.error.validate'), 'customSnackError');
        }
      });
  }

  setExpandedElement(element: Prestation) {
    if (this.expandedPrestation === element) {
      return this.expandedPrestation = null;
    }
    this.expandedPrestation = element;
    this.setPrestationPeriodDataSource(element.periods);
  }

  sendReminder() {
    this.disabledSendReminderBtn = true;
    this.facadeService.prestationSendReminder().subscribe();
  }

  getWeekNumber(prestation: Prestation): string {
    const d = new Date(Date.UTC(prestation.startDate.getFullYear(), prestation.startDate.getMonth(), prestation.startDate.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7).toString();
  }

  openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: [pC]
    });
  }

  // Old Prestation Export //
  exportPrestations() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.prestationsExport = true;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});

    dialogRef.afterClosed().subscribe(
      result => param.saveFile(this.facadeService.getPrestationToExport(
        result.prestationStartDate,
        result.prestationEndDate,
        result.operatingUnitIds,
        result.customerId,
        result.workerId)));
  }

  // New Export Prestation (November 2023) //
  exportPrestationsNew() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.prestationsExportNew = true;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});

    dialogRef.afterClosed().subscribe(
      result => param.saveFile(this.facadeService.getPrestationNewToExport(
        result.operatingUnitIds,
        result.customerId,
        result.workerId,
        result.prestationStartDate,
        result.prestationEndDate
        )));
  }

  // Export Code prestation (2023) //
  exportCodePrestations() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.prestationsCodesExport = true;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});

    dialogRef.afterClosed().subscribe(
      result => param.saveFile(this.facadeService.getCodePrestationToExport(
        result.prestationStartDate,
        result.prestationEndDate,
        result.operatingUnitIds,
        result.customerId,
        result.workerId)));
  }

  private setPrestationToValidate(index: number, pageSize: number, id?: number) {
    this.facadeService.getPagePrestationToRelease(index, pageSize).subscribe(
      (data: PrestationPage) => {
        this.prestationDataSource.data = data.content;
        this.pageIndex = index;
        this.pageSize = pageSize;
        if (id) {
          this.setExpandedElement(data.content.find(prestation => prestation.id === id));
        } else {
          this.expandedPrestation = null;
        }
      }
    );
  }

  private setPrestationPeriodCodeDataSource(prestationPeriodCode: PrestationPeriodCode[]) {
    this.prestationPeriodCodeDataSource = new MatTableDataSource<PrestationPeriodCode>(prestationPeriodCode);
  }
}
