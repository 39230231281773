import {Component, Inject, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {PrestationPeriod} from '../../shared/model/prestation-period.model';
import {TranslateService} from '@ngx-translate/core';
import {PrestationCode} from '../../../shared/model/prestation-code/prestation-code.model';
import {AuthorizationService} from '../../../login/shared/service/authorization.service';
import {DatePipe} from '@angular/common';
import {Constants} from '../../../shared/constants';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

class PrestationFormModel {
  startDate: Date;
  startHour: Date;
  endDate: Date;
  endHour: Date;
  breakDuration: Date;
  totalWorkedHours: number;

  constructor(prestationPeriod: PrestationPeriod) {
    this.startDate = new Date(prestationPeriod.actualStartDate ?? prestationPeriod.plannedStartDate);
    this.startHour = new Date(prestationPeriod.actualStartDate ?? prestationPeriod.plannedStartDate);
    this.endDate = new Date(prestationPeriod.actualEndDate ?? prestationPeriod.plannedEndDate);
    this.endHour = new Date(prestationPeriod.actualEndDate ?? prestationPeriod.plannedEndDate);
    this.breakDuration = new Date(prestationPeriod.actualPauseTime ?? prestationPeriod.plannedPauseTime);
    this.totalWorkedHours = prestationPeriod?.getTotalHoursToNumber();
  }
}

@Component({
  selector: 'app-prestation-dialog',
  templateUrl: './prestation-dialog.component.html',
  styleUrls: ['./prestation-dialog.component.css']
})
export class PrestationDialogComponent implements OnInit {
  clicked = false;
  prestationCodes: PrestationCode[] = [];
  prestationFormModel: PrestationFormModel;
  breakDurationValues: string[] = [
    '00:00', '00:15', '00:30', '00:45',
    '01:00', '01:15', '01:30', '01:45',
    '02:00', '02:15', '02:30', '02:45',
    '03:00', '03:15', '03:30', '03:45',
    '04:00', '04:15', '04:30', '04:45',
    '05:00', '05:15', '05:30', '05:45',
    '06:00', '06:15', '06:30', '06:45',
    '07:00', '07:15', '07:30', '07:45',
    '08:00'];

  constructor(private dialogRef: MatDialogRef<PrestationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private facadeService: FacadeService,
              private translate: TranslateService,
              private datePipe: DatePipe,
              private snackBar: MatSnackBar,
              private authorizationService: AuthorizationService) {
    this.prestationFormModel = new PrestationFormModel(data.prestationPeriod);
  }

  ngOnInit() {
    this.getPrestationCodes();
  }

  closeDialog(updated = false): void {
    this.dialogRef.close(updated);
  }

  onValidate(form: NgForm) {
    if (form.valid) {
      this.clicked = true;
      const description = form.value.description;

      //  Compute Start Hour
      const startHour = form.value.startHour;
      let hours = startHour.split(':');
      const actualStartDate = new Date(form.value.startDate);
      actualStartDate.setHours(+hours[0]);
      actualStartDate.setMinutes(+hours[1]);

      //  Compute End Hour
      const endHour = form.value.endHour;
      hours = endHour.split(':');
      const actualEndDate = new Date(form.value.endDate);
      actualEndDate.setHours(+hours[0]);
      actualEndDate.setMinutes(+hours[1]);

      //  Compute Break Duration
      const breakDuration = form.value.breakDuration;
      hours = breakDuration.split(':');
      const breakDurationDate = new Date(0, 0, 0);
      breakDurationDate.setHours(+hours[0]);
      breakDurationDate.setMinutes(+hours[1]);

      const actualStartDate_ms = actualStartDate.getTime();
      const actualEndDate_ms = actualEndDate.getTime();
      const breakDuration_ms = breakDurationDate.getTime() - new Date(0, 0, 0, 0, 0, 0).getTime();
      const diff_ms = actualEndDate_ms - actualStartDate_ms - breakDuration_ms;

      if (!actualEndDate_ms || !actualStartDate_ms) {
        return this.openSnackBar(
          this.translate.instant('prestations.validation.dialog.message.error.invalidDate'), 'customSnackError');
      }

      //  Check if date are valid
      if (actualEndDate_ms < actualStartDate_ms) {
        this.clicked = false;
        return this.openSnackBar(
          this.translate.instant('prestations.validation.dialog.message.error.invalidHours'), 'customSnackError');
      }
      if (diff_ms > Constants.EVENT_MAXIMUM_DURATION) {
        this.clicked = false;
        return this.openSnackBar(
          this.translate.instant('prestations.validation.dialog.message.error.maximum'), 'customSnackError');
      }
      if (diff_ms < Constants.EVENT_MINIMUM_DURATION) {
        this.clicked = false;
        return this.openSnackBar(
          this.translate.instant('prestations.validation.dialog.message.error.minimum'), 'customSnackError');
      }

      const prestationPeriod: PrestationPeriod = this.data.prestationPeriod;
      prestationPeriod.description = description;
      prestationPeriod.actualStartDate = actualStartDate;
      prestationPeriod.actualEndDate = actualEndDate;
      prestationPeriod.actualPauseTime = breakDurationDate;

      this.validatePrestationPeriod(prestationPeriod);
    }
  }

  changeStartHour(formValue: NgForm) {
    this.setTotalHour(formValue);
  }

  changeBreakDuration(formValue: NgForm) {
    this.setTotalHour(formValue);
  }

  changeEndHour(formValue: NgForm) {
    const startHour = formValue.value.startHour;
    const endHour = formValue.value.endHour;
    const resultStart = startHour.match(/([0-9]{1,2}):[0-9]{2}/);
    const resultEnd = endHour.match(/([0-9]{1,2}):[0-9]{2}/);
    if (resultStart && resultEnd) {
      if (+resultStart[1] > +resultEnd[1]) {
        this.prestationFormModel.endDate.setDate(this.prestationFormModel.startDate.getDate() + 1);
      } else {
        this.prestationFormModel.endDate.setDate(this.prestationFormModel.startDate.getDate());
      }
      formValue.controls['endDate'].setValue(this.datePipe.transform(this.prestationFormModel.endDate, 'yyyy-MM-dd'));
    }
    this.setTotalHour(formValue);
  }

  setTotalHour(formValue: NgForm) {
    const startHour = this.getMinutesFromString(formValue.value.startHour);
    const endHour = this.getMinutesFromString(formValue.value.endHour);
    const breakDuration = formValue.value.breakDuration != null ?
      this.getMinutesFromString(formValue.value.breakDuration) :
      0.0;
    const diff = endHour - startHour - breakDuration;
    this.prestationFormModel.totalWorkedHours = parseFloat(Math.floor(diff / 60) + '.' + diff % 60);
  }

  getMinutesFromString(dateToChange: string): number {
    return +dateToChange.split(':')[0] * 60 + +dateToChange.split(':')[1];
  }

  private getPrestationCodes() {
    this.facadeService.getPrestationCodeJustification().subscribe({
      next: prestationCodes => {
        this.prestationCodes = prestationCodes;
      },
      error: () => {
      }
    });
  }

  private openSnackBar(message: string, pC: string, action: string = 'X') {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private validatePrestationPeriod(period: PrestationPeriod) {
    period.consultantValidationBy = this.authorizationService.getCurrentUser().username;
    period.prestation = this.data.prestation;

    this.facadeService.validatePrestationPeriod(period).subscribe({
      next: ignoreProperty => {
        return this.closeDialog(true);
      },
      error: () => {
        return this.openSnackBar(
          this.translate.instant('restations.validation.dialog.message.error.validatePrestationPeriod'), 'customSnackError');
      }
    });
  }
}
