import {WorkerPage} from './worker-page.model';
import {Serializer} from '../../serializer';
import {WorkerSerializer} from '../../../../workers/shared/model/woker.serializer';

export class WorkerPageSerializer implements Serializer {

  private workerSerializer = new WorkerSerializer();

  fromJson(json: any): WorkerPage {
    const workerPage = new WorkerPage();

    json.content.forEach(
      content => {
        workerPage.content.push(this.workerSerializer.fromJson(content));
      }
    );

    workerPage.totalPages = json.totalPages;
    workerPage.totalElements = json.totalElements;
    return workerPage;
  }

  toJson(resource: Object): any {
  }
}

