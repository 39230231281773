import {Component, OnInit, ViewChild} from '@angular/core';
import {Customer} from '../shared/model/customer.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FacadeService} from 'src/app/shared/service/facade/facade.service';
import {GoogleMapService} from 'src/app/shared/service/GoogleMap/google-map.service';
import {AuthorizationService} from '../../login/shared/service/authorization.service';
import {AccountStatus, Role} from '../../users/shared/model/user.model';
import {CompanyPrefix} from '../../shared/model/company-prefix/company-prefix.model';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {UploadDocumentComponent} from '../../workers/worker-view/upload-document/upload-document.component';
import {MatDialog} from '@angular/material/dialog';
import {Document} from '../../shared/model/document/document.model';
import {ConfirmationDialogComponent} from '../../shared/component/confirmation-dialog/confirmation-dialog.component';
import {ProcessedDocumentDialogComponent} from '../../workers/worker-view/processed-document-dialog/processed-document-dialog.component';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.css']
})
export class CustomerViewComponent implements OnInit {

  @ViewChild('requestsPaginator', {static: true}) paginator: MatPaginator;
  documentDisplayedColumns: string[] = ['name', 'type', 'processedBy', 'processedDate', 'view', 'edit', 'delete'];
  documents = new MatTableDataSource<Document>();
  @ViewChild('paginatorDocuments', {static: true}) paginatorDocuments: MatPaginator;
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;
  companyPrefix: CompanyPrefix;
  customer: Customer = new Customer();
  accountStatus: AccountStatus;
  protected readonly AccountStatus = AccountStatus;

  constructor(private googleMapService: GoogleMapService,
              private dialog: MatDialog,
              public route: ActivatedRoute,
              private router: Router,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private authorizationService: AuthorizationService,
              private translate: TranslateService) {
    this.getCustomer();
  }

  ngOnInit() {
    this.setDocuments(this.route.snapshot.data['documents']);
    this.setCompanyPrefixByCode(this.customer.prefix);

    if (this.customer.billingData.billingEmail) {
      this.facadeService.checkCustomerEmailTaken(this.customer.id, this.customer.billingData.billingEmail).subscribe(
        res => this.accountStatus = res);
    } else {
      this.accountStatus = AccountStatus.SECURED;
    }
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  getLinkWithAddress(customer: Customer): string {
    return this.googleMapService.getLinkWithAddress(customer.address);
  }

  onActivate() {
    this.facadeService.activeCustomer(this.customer)
      .subscribe({
        next:
          ignoreProperty => {
            this.router.navigate(['customers']).then();
            this.openSnackBar(this.translate.instant('customers.view.message.success.activate'));
          },
        error: () => {
          this.openSnackBar(this.translate.instant('customers.view.message.error.activate'), 'customSnackError');
        }
      });
  }

  onDeactivate() {
    this.facadeService.getPendingRequestByCustomer(this.customer.id).subscribe(
      data => {
        if (data.length === 0) {
          this.facadeService.deactivateCustomer(this.customer)
            .subscribe({
              next: ignoreProperty => {
                this.router.navigate(['customers']).then();
                return this.openSnackBar(this.translate.instant('customers.view.message.success.deactivate'));
              },
              error: () => {
                return this.openSnackBar(this.translate.instant('customers.view.message.error.deactivate'), 'customSnackError');
              }
            });
        } else {
          return this.openSnackBar(this.translate.instant('customers.view.message.error.pendingRequest'), 'customSnackError');
        }
      }
    );
  }

  createCustomerAccount() {
    this.facadeService.createCustomerAccount(this.customer.id, this.customer.billingData.billingEmail)
      .subscribe({
        next: () => {
          return this.openSnackBar(this.translate.instant('customers.view.message.success.mailSend'));
        },
        error: () => {
          return this.openSnackBar(this.translate.instant('customers.view.message.error.mailSend'), 'customSnackError');
        }
      });
  }

  openSnackBar(message: string, pC: string = 'customSnackValid', action: string = 'X') {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  downloadDocument(document: Document) {
    this.facadeService.downloadCustomerDocument(this.customer.id, document).subscribe({
      next: data => {
        const file = new Blob([data], {type: document.contentType});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      },
      error: () => {
        return alert(this.translate.instant('workers.view.message.error.downloadDocument'));
      }
    });
  }

  deleteDocument(document: Document) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.facadeService.deleteCustomerDocument(this.customer.id, document).subscribe({
          next: ignoreProperty => {
            this.reloadDocuments();
          },
          error: () => {
            return alert(this.translate.instant('workers.view.message.error.deleteDocument'));
          }
        });
      }
    });
  }

  uploadDocument() {
    const dialogRef = this.dialog.open(UploadDocumentComponent, {
      data: {
        customer: this.customer
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadDocuments();
      }
    });
  }

  editDocument(document: Document) {
    const dialogRef = this.dialog.open(ProcessedDocumentDialogComponent, {
      data: {
        document: document,
        customer: this.customer
      },
      minWidth: '50%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadDocuments();
      }
    });
  }

  private setCompanyPrefixByCode(code: string) {
    if (code) {
      this.facadeService.getCompanyPrefixByCode(code)
        .subscribe({
          next: (data: CompanyPrefix) => {
            this.companyPrefix = data;
          },
          error: () => {
            // alert(this.translate.instant('customers.view.message.error.company'));
          }
        });
    }
  }

  private getCustomer() {
    const data = this.route.snapshot.data['customer'];
    Object.assign(this.customer, data);
  }

  private reloadDocuments() {
    this.facadeService.getDocumentsByCustomerId(this.customer.id).subscribe({
      next: data => {
        this.setDocuments(data);
      }, error: () => {
        return alert(this.translate.instant('workers.view.message.error.reloadDocuments'));
      }
    });
  }

  private setDocuments(documents: Document[]) {
    this.documents = new MatTableDataSource<Document>(documents);
    this.documents.paginator = this.paginatorDocuments;
  }
}
