import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {PrestationPage} from '../../model/pagination/prestation/prestation-page.model';

@Injectable()
export class PrestationsNotReleasedResolver implements Resolve<Observable<PrestationPage>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(): Observable<PrestationPage> {
    return this.facadeService.getPagePrestationToRelease(0, 25).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
