import {Serializer} from '../serializer';
import {Document} from './document.model';
import {DateSerializer} from '../date.serializer';

export class DocumentSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): Document {
    const document: Document = new Document();

    document.fileName = json.fileName;
    document.originalFileName = json.originalFileName;
    document.typeFile = json.typeFile;
    document.contentType = json.contentType;
    document.consultant = json.consultant;
    document.comment = json.comment;
    document.reportMessage = json.reportMessage;
    document.dateUploaded = this.dateSerializer.fromJson(json.dateUploaded);
    document.dateProcessed = this.dateSerializer.fromJson(json.dateProcessed);
    document.endDate = this.dateSerializer.fromJson(json.endDate);
    document.startDate = this.dateSerializer.fromJson(json.startDate);

    return document;
  }

  toJson(document: Document): any {
    return {
      fileName: document.fileName,
      originalFileName: document.originalFileName,
      typeFile: document.typeFile,
      contentType: document.contentType,
      consultant: document.consultant,
      comment: document.comment,
      reportMessage: document.reportMessage,
      dateUpload: this.dateSerializer.dateWithTimeToJson(document.dateUploaded),
      dateProcessed: this.dateSerializer.dateWithTimeToJson(document.dateProcessed),
      endDate: this.dateSerializer.toJson(document.endDate),
      startDate: this.dateSerializer.toJson(document.startDate)
    };
  }
}
