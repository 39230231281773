import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Request} from 'src/app/requests/shared/model/request.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FacadeService} from '../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import {PlanningDto, WorkerOccupancyDto} from './shared/model/dto/planning-dto.model';
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';
import enLocale from '@fullcalendar/core/locales/en-gb';
import {Prestation} from '../prestations/shared/model/prestation.model';
import {Customer} from '../customers/shared/model/customer.model';
import {NonAvailability} from '../workers/shared/model/nonAvailability.model';
import {OperatingUnit} from '../operating-units/shared/model/operating-unit.model';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {UntypedFormControl} from '@angular/forms';
import {MatOption} from '@angular/material/core';
import {CalendarOptions, DatesSetArg, EventApi, EventClickArg, EventHoveringArg} from '@fullcalendar/core';
import {RequestPage} from '../shared/model/pagination/request/request-page.model';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import list from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {FullCalendarComponent} from '@fullcalendar/angular';

@Component({
  selector: 'app-planning-view',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.css']
})
export class PlanningComponent implements OnInit, AfterViewInit {

  @ViewChild('calendar') calendarRef: FullCalendarComponent;
  @ViewChild('operatingUnitSelection') operatingUnitSelection: MatSelect;
  @ViewChild('customerSelection') customerSelection: MatSelect;

  calendarOptions: CalendarOptions = {
    plugins: [rrulePlugin,
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      listPlugin,
      resourceTimelinePlugin,
      list],
    firstDay: 1,
    height: 'auto',
    locales: [enLocale, frLocale, nlLocale],
    timeZone: 'local',
    initialView: this.route.snapshot.queryParams['view'] ?? 'resourceTimelineWeek',
    views: {
      resourceTimelineWeek: {
        type: 'resourceTimelineWeek',
        resourcesInitiallyExpanded: false,
      },
    },
    resourceGroupLabelContent: function (args) {
      return {
        html: '<button style="cursor:pointer;border:none;" id="customerName">' + args.groupValue.split('_')[0] + '</button>\n'
      };
    },
    resourceGroupLabelDidMount: mountArg => {
      mountArg.el.addEventListener('click', (event) => {
        if ((event.target as Element).id === 'customerName') {
          return this.router.navigate(['customer-view'], {queryParams: {'c': mountArg.groupValue.split('_')[1]}});
        }
      });
    },
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'resourceTimelineDay resourceTimelineWeek listDay'
    },
    resourceGroupField: 'building',
    slotDuration: '12:00:00',
    resourceAreaColumns: [
      {
        field: 'costCenter',
        headerContent: this.costCenterHeader.bind(this),
      },
      {
        headerContent: this.workerHeader.bind(this),
        field: 'worker',
        cellContent: function (args) {
          return {
            html:
              '<div style="cursor: pointer;" id="text"">' + args.resource.extendedProps.worker + '</div>'
          };
        },
        cellDidMount: mountArg => {
          mountArg.el.addEventListener(
            'click', () => {
              return this.router.navigate(['worker-view'], {queryParams: {'w': mountArg.resource.extendedProps.workerId}});
            });
        },
      },
      {
        headerContent: this.totalHeader.bind(this),
        field: 'occupancy'
      },
      {
        headerContent: this.copyHeader.bind(this),
        field: 'copy',
        cellContent: function () {
          return {
            html:
              '<div style="cursor: pointer;" class="d-flex align-items-center justify-content-center">\n' +
              '    <i class="fas fa-copy fa-lg"></i>\n' +
              '</div>'
          };
        },
        cellDidMount: mountArg => {
          mountArg.el.addEventListener(
            'click', () => {
              let queryParams: Params;
              if (mountArg.resource.extendedProps.costCenter) {
                queryParams = {
                  'workerId': mountArg.resource.extendedProps.workerId,
                  'customerId': mountArg.resource.extendedProps.customerId,
                  'customerName': mountArg.resource.extendedProps.building,
                  'workFunctionId': mountArg.resource.extendedProps.workFunctionId,
                  'costCenter': mountArg.resource.extendedProps.costCenter,
                };
              } else {
                queryParams = {
                  'workerId': mountArg.resource.extendedProps.workerId,
                  'customerId': mountArg.resource.extendedProps.customerId,
                  'customerName': mountArg.resource.extendedProps.building,
                  'workFunctionId': mountArg.resource.extendedProps.workFunctionId,
                };
              }
              return this.router.navigate(['request-new'],
                {
                  queryParams
                });
            });
        },
      }
    ],
    initialDate: this.route.snapshot.queryParams['fromDate'],
    eventColor: '#8BD1DC',
    eventTextColor: '#ffffff',
    eventOrder: 'type',
    eventMinWidth: 0,
    locale: this.translate.currentLang,
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    resourceOrder: 'building, costCenter, worker',
    datesSet: this.handleDatesSet.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventMouseEnter: this.handleEventMouseEnter.bind(this)
  };

  customers: Customer[] = [];
  operatingUnits: OperatingUnit[] = [];
  startDate: Date;
  endDate: Date;
  customerIds: number[];
  operatingUnitIds: number[];
  countPeriods = 0;
  prestations: Prestation[] = [];
  pendingRequests: RequestPage;
  selectedColor1 = '#F46250';
  selectedColor2 = 'accent';
  selectedColor3 = 'accent';
  selectedSize1 = 'medium';
  selectedSize2 = 'small';
  selectedSize3 = 'small';

  operatingUnitFormControl = new UntypedFormControl();
  allOperatingUnitSelected = false;
  inAllOperatingUnitToggle = false;

  customerFormControl = new UntypedFormControl();
  allCustomerSelected = false;
  inAllCustomerToggle = false;

  constructor(private router: Router,
              public route: ActivatedRoute,
              private datePipe: DatePipe,
              private facadeService: FacadeService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.getAllCustomers();
    this.getAllOperatingUnits();
    this.getPendingRequests();
    this.translate.onLangChange.subscribe(next => {
      this.calendarRef.getApi().setOption('locale', next.lang);
    });
  }

  ngAfterViewInit() {
    this.startDate = this.calendarRef.getApi().view.activeStart;
    this.endDate = this.calendarRef.getApi().view.activeEnd;
    this.refreshTab();
  }

  workerFunctionTranslated(r: Request): string {
    switch (this.translate.currentLang.toLowerCase()) {
      case 'fr':
        return r.workFunction.nameFR;
      case 'nl':
        return r.workFunction.nameNL;
      case 'de':
        return r.workFunction.nameDE;
      default:
        return r.workFunction.nameEN;
    }
  }

  sameEvent(event1: EventApi, event2: any) {
    return event1.extendedProps.idOccupancy === event2.idOccupancy &&
      event1.start.getTime() === event2.start.getTime() &&
      event1.end.getTime() === event2.end.getTime();
  }

  // noinspection DuplicatedCode
  setCustomer(event: MatSelectChange) {
    if (event.value.indexOf(0) !== -1
      && this.allCustomerSelected &&
      !this.inAllCustomerToggle
    ) {
      this.customerSelection.options.find(item => item.value === 0).deselect();
      this.allCustomerSelected = false;
    }
    if (event.value.indexOf(0) === -1
      && event.value.length === this.customers.length
      && !this.allCustomerSelected
      && !this.inAllCustomerToggle
    ) {
      this.customerSelection.options.find(item => item.value === 0).select();
      this.allCustomerSelected = true;
    }
    this.customerIds = event.value;
  }

  // noinspection DuplicatedCode
  setOperatingUnit(event: MatSelectChange) {
    if (event.value.indexOf(0) !== -1
      && this.allOperatingUnitSelected &&
      !this.inAllOperatingUnitToggle
    ) {
      this.operatingUnitSelection.options.find(item => item.value === 0).deselect();
      this.allOperatingUnitSelected = false;
    }
    if (event.value.indexOf(0) === -1
      && event.value.length === this.operatingUnits.length
      && !this.allOperatingUnitSelected
      && !this.inAllOperatingUnitToggle
    ) {
      this.operatingUnitSelection.options.find(item => item.value === 0).select();
      this.allOperatingUnitSelected = true;
    }
    this.operatingUnitIds = event.value;
  }

  refreshTab() {
    this.calendarRef.getApi().refetchResources();
    this.facadeService.planningByStartDateAndEndDate(
      this.customerIds ? this.customerIds.filter(value => value !== 0) : [],
      this.operatingUnitIds ? this.operatingUnitIds.filter(value => value !== 0) : [],
      this.startDate,
      this.endDate).subscribe(value => this.getData(value));
  }

  // noinspection DuplicatedCode
  toggleAllOperatingUnit() {
    this.inAllOperatingUnitToggle = true;
    if (!this.allOperatingUnitSelected) {
      this.operatingUnitSelection.options.forEach((item: MatOption) => item.select());
    } else {
      this.operatingUnitSelection.options.forEach((item: MatOption) => item.deselect());
    }
    this.inAllOperatingUnitToggle = false;
    this.allOperatingUnitSelected = !this.allOperatingUnitSelected;
  }

  // noinspection DuplicatedCode
  toggleAllCustomer() {
    this.inAllCustomerToggle = true;
    if (!this.allCustomerSelected) {
      this.customerSelection.options.forEach((item: MatOption) => item.select());
    } else {
      this.customerSelection.options.forEach((item: MatOption) => item.deselect());
    }
    this.inAllCustomerToggle = false;
    this.allCustomerSelected = !this.allCustomerSelected;
  }

  handleDatesSet(arg: DatesSetArg) {
    if (this.calendarRef) {
      this.startDate = arg.start;
      this.endDate = arg.end;
      this.facadeService.planningByStartDateAndEndDate(
        this.customerIds,
        this.operatingUnitIds, arg.start, arg.end).subscribe(value => this.getData(value));
      this.router.navigate(['.'], {
          relativeTo: this.route,
          queryParams: {
            view: arg.view.type,
            fromDate: arg.startStr,
          }
        }
      );
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    const requestId = clickInfo.event.extendedProps.requestId;
    if (requestId) {
      window.open('/request-edit?r=' + requestId, '_blank');
    }
  }

  handleEventMouseEnter(arg: EventHoveringArg) {
    const el = arg.el;
    const event = arg.event;
    const start = this.datePipe.transform(event.start, 'HH:mm');
    const end = this.datePipe.transform(event.end, 'HH:mm');
    let title = '';
    if (event.extendedProps && event.extendedProps.workFunctionLabel) {
      title += event.extendedProps.workFunctionLabel + ' | ';
    }
    title += start + ' - ' + end;
    el.setAttribute('title', title);
  }

  costCenterHeader() {
    return this.translate.instant('planning.calendar.costCenter');
  }

  workerHeader() {
    return this.translate.instant('planning.calendar.worker');
  }

  totalHeader() {
    return this.translate.instant('planning.calendar.total');
  }

  copyHeader() {
    return '';
  }

  private getAllCustomers() {
    const data = this.route.snapshot.data['customers'];
    Object.assign(this.customers, data);
  }

  private getAllOperatingUnits() {
    const operatingUnits = this.route.snapshot.data['operatingUnits'];
    Object.assign(this.operatingUnits, operatingUnits);
  }

  private getPendingRequests() {
    this.pendingRequests = this.route.snapshot.data['pendingRequests'];
  }

  private getData(planningData: PlanningDto) {
    this.countPeriods = 0;
    this.calendarRef.getApi().getResources().forEach(
      r => {
        r.remove();
      }
    );
    this.calendarRef.getApi().removeAllEvents();
    this.countPeriods = planningData.count;
    for (const customer of planningData.customers) {
      for (const costCenter of customer.costCenters) {
        for (const worker of costCenter.workers) {
          const resourceId = `${customer.id}${worker.id}${costCenter.costCenter}`;

          // We can't access the resource data in groupLaneMount so we add id to name and we split after
          const resource = {
            id: resourceId,
            building: `${customer?.name + '_' + customer?.id}`,
            groupId: `${customer.id}`,
            costCenter: costCenter.costCenter,
            worker: worker.name ? worker.name : this.translate.instant('planning.addEvent.noWorker'),
            workerId: worker.id,
            customerId: customer.id,
            customerName: customer.name,
            workFunctionId: worker.requests[0].workFunction.id,
            costCenterId: costCenter.costCenter,
            occupancy: `${worker.totalHours}h${(worker.totalMinutes < 10) ? '0' + worker.totalMinutes : worker.totalMinutes}`
          };
          this.calendarRef.getApi().addResource(resource);
          if (planningData.nonAvailabilities.get(worker.id) != null && planningData.nonAvailabilities.get(worker.id).length > 0) {
            planningData.nonAvailabilities.get(worker.id).forEach(
              nonAvailability => {
                const nonAvailabilityEvent = NonAvailability.buildEvent(nonAvailability);
                nonAvailabilityEvent['type'] = 3;
                nonAvailabilityEvent['resourceId'] = resourceId;
                this.calendarRef.getApi().addEvent(nonAvailabilityEvent);
              }
            );
          }

          for (const request of worker.requests) {

            if (planningData.workerOccupancies.get(worker.id) != null
              && planningData.workerOccupancies.get(worker.id).length > 0) {
              planningData.workerOccupancies.get(worker.id)
                .filter(w => w.requestId !== request.id).forEach(
                (workerOccupancy: WorkerOccupancyDto) => {

                  const workerOccupancyEvent = {
                    start: workerOccupancy.startDate,
                    end: workerOccupancy.endDate,
                    resourceId: resourceId,
                    title: `#${workerOccupancy.requestId}`,
                    color: 'black',
                    editable: false,
                    idOccupancy: resourceId + '_' + workerOccupancy.requestId,
                    type: 2,
                  };

                  // Avoid double request on a single resource adding event on their own resource
                  if (worker.requests.find(w => w.id === workerOccupancy.requestId) == null) {

                    const eventApi = this.calendarRef.getApi().getEvents().find(
                      e => this.sameEvent(e, workerOccupancyEvent));

                    if (eventApi == null) {
                      this.calendarRef.getApi().addEvent(workerOccupancyEvent);
                    }
                  }
                }
              );
            }

            const workFunctionLabel = request.workFunction ? this.workerFunctionTranslated(request) : '';
            let title = workFunctionLabel;
            if (this.calendarRef.getApi().view.type === 'listDay') {
              title += ' | ' + customer?.name + ' | ' + worker.name;
            }
            request.periods.forEach(
              period => {
                const event = {
                  start: period.startDate,
                  end: period.endDate,
                  resourceId: resourceId,
                  title: `${title}`,
                  requestId: request.id,
                  workFunctionLabel: workFunctionLabel,
                  color: request.getColorByStatus(),
                  editable: true,
                  type: 1,
                };
                this.calendarRef.getApi().addEvent(event);
              }
            );
          }
        }
      }
    }
  }
}
