import {Serializer} from '../../serializer';
import {OperatingUnitSerializer} from '../../../../operating-units/shared/model/operating-unit.serializer';
import {OperatingUnitPage} from './operating-unit-page.model';

export class OperatingUnitPageSerializer implements Serializer {

  private operatingUnitSerializer = new OperatingUnitSerializer();

  fromJson(json: any): OperatingUnitPage {
    const operatingUnitPage = new OperatingUnitPage();

    json.content.forEach(
      content => {
        operatingUnitPage.content.push(this.operatingUnitSerializer.fromJson(content));
      }
    );

    operatingUnitPage.totalElements = json.totalElements;
    operatingUnitPage.totalPages = json.totalPages;
    return operatingUnitPage;
  }

  toJson(resource: Object): any {
  }
}

