export enum DimonaType {
  DIMONA_IN = 'DIMONA_IN',
  DIMONA_OUT = 'DIMONA_OUT',
  DIMONA_UPDATE = 'DIMONA_UPDATE',
  DIMONA_CANCEL = 'DIMONA_CANCEL',
  DAILY_REGISTRATION_DECLARATION = 'DAILY_REGISTRATION_DECLARATION'
}

export enum DimonaWorkerType {
  A17 = 'A17',
  BCW = 'BCW',
  DWD = 'DWD',
  EXT = 'EXT',
  FLX = 'FLX',
  IVT = 'IVT',
  PMP = 'PMP',
  RTA = 'RTA',
  STU = 'STU',
  STX = 'STX',
  TEA = 'TEA',
  TRI = 'TRI',
  OTH = 'OTH'
}

export enum DimonaStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  INVALID_FORMAT = 'INVALID_FORMAT',
  ACK = 'ACK',
  NACK = 'NACK',
  OK_PARTIAL = 'OK_PARTIAL',
  OK = 'OK',
  NOK = 'NOK',
  CANCELLED = 'CANCELLED'
}

export enum DimonaPeriodStatus {
  SENT = 'SENT',
  SENT_ERROR = 'SENT_ERROR',
  A = 'A',
  B = 'B',
  W = 'W',
  S = 'S'
}

export enum DimonaErrorAction {
  VALIDATE = 'VALIDATE',
  REDIRECT = 'REDIRECT',
  INVALID_NISS = 'INVALID_NISS'
}

export class DimonaPeriod {
  id: number;
  label: string;
  contractPeriodId: number;
  requestPeriodId: number;
  reference: string;
  dimonaPeriodId: string;
  status: DimonaPeriodStatus;
  errorCodes: string;
  startDate: Date;
  endDate: Date;
  plannedNbrHours: number;
  active: boolean;
  idDimona: number;
  idContractDimona: number;
}

export class Dimona {
  id: number;
  parentId: number;
  dimonaType: DimonaType;
  dimonaWorkerType: DimonaWorkerType;
  jointCommission: string;
  sequenceNumber: string;
  senderNumber: string;
  referenceNumber: string;
  startDate: Date;
  endDate: Date;
  dimonaPeriods: DimonaPeriod[] = [];
  requestId: number;
  operatingUnitId: number;
  contractId: number;
  operatingUnitOnssNumber: string;
  customerId: number;
  customerEnterpriseNumber: string;
  workerId: number;
  workerNiss: string;
  dimonaStatus: DimonaStatus;
  errorCodes: string;
  active: boolean;
}
