import {Serializer} from '../../serializer';
import {WorkerLiteSerializer} from '../../../../workers-lite/shared/model/worker-lite.serializer';
import {WorkerLitePage} from './worker-lite-page.model';

export class WorkerLitePageSerializer implements Serializer {

  private workerLiteSerializer = new WorkerLiteSerializer();

  fromJson(json: any): WorkerLitePage {
    const workerLitePage = new WorkerLitePage();

    json.content.forEach(
      content => {
        workerLitePage.content.push(this.workerLiteSerializer.fromJson(content));
      }
    );

    workerLitePage.totalPages = json.totalPages;
    workerLitePage.totalElements = json.totalElements;
    return workerLitePage;
  }

  toJson(resource: Object): any {
  }
}

