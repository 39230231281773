import {Serializer} from '../../../shared/model/serializer';
import {AggregatedInvoiceLine} from './aggregated-invoice-line.model';

export class AggregatedInvoiceLineSerializer implements Serializer {
  fromJson(json: any): AggregatedInvoiceLine {
    const aggregatedInvoiceLine: AggregatedInvoiceLine = new AggregatedInvoiceLine();

    aggregatedInvoiceLine.code = json.code;
    aggregatedInvoiceLine.labelFR = json.labelFR;
    aggregatedInvoiceLine.labelEN = json.labelEN;
    aggregatedInvoiceLine.labelNL = json.labelNL;
    aggregatedInvoiceLine.totalVATIncluded = json.totalVATIncluded;

    return aggregatedInvoiceLine;
  }

  toJson(aggregatedInvoiceLine: AggregatedInvoiceLine): any {
    return {
      code: aggregatedInvoiceLine.code,
      labelFR: aggregatedInvoiceLine.labelFR,
      labelEN: aggregatedInvoiceLine.labelEN,
      labelNL: aggregatedInvoiceLine.labelNL,
      totalVATIncluded: aggregatedInvoiceLine.totalVATIncluded,
    };
  }

}
