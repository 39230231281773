import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Dimona} from '../../model/dimona/dimona.model';
import {DimonaSerializer} from '../../model/dimona/dimona.serializer';
import {DateSerializer} from '../../model/date.serializer';

@Injectable({
  providedIn: 'root'
})
export class DimonaService {
  private serviceURL = environment.dimona_service_URL;
  private dimonaSerializer: DimonaSerializer = new DimonaSerializer();

  constructor(private http: HttpClient) {
  }

  getDimonaByRequestId(requestId: number): Observable<any> {
    return this.http.get<Dimona[]>(this.serviceURL + 'dimonas/requests/' + requestId).pipe(
      map((data) => this.convertDataDimona(data))
    );
  }

  submitDimona(periodId: string): Observable<any> {
    return this.http.post<Dimona>(this.serviceURL + 'periods/submit/' + periodId, null);
  }

  export(startDate: Date, endDate: Date, operationUnitIds: Array<number>): Observable<any> {
    const dateSerializer = new DateSerializer();
    const params = new HttpParams({
      fromObject: {
        operationUnitIds: String(operationUnitIds),
        ...startDate ? {startDate: dateSerializer.toJson(startDate)} : {},
        ...endDate ? {endDate: dateSerializer.toJson(endDate)} : {}
      }
    });
    return this.http.get(
      this.serviceURL + 'dimonas/export',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  private convertDataDimona(data: any): Dimona[] {
    const dimonas: Dimona[] = data.map(dimona => this.dimonaSerializer.fromJson(dimona));
    dimonas.sort((a, b) => b.id - a.id);
    return dimonas;
  }
}
