import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {FacadeService} from '../../service/facade/facade.service';
import {Observable, of} from 'rxjs';
import {Request} from '../../../requests/shared/model/request.model';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class WorkerFromRequestResolver implements Resolve<Observable<Request>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Request> {
    const requestId = route.queryParams['r'];
    if (requestId) {
      return this.facadeService.getRequestById(+requestId).pipe(
        mergeMap((request: Request) => {
          return this.facadeService.getWorkerById(+request.workerId).pipe(
            map((data) => data),
            catchError(error => {
              return of({error: error});
            })
          );
        })
      );
    }
  }
}
