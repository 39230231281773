import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BillingCoefficients } from 'src/app/customers/shared/model/customer.model';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';

@Component({
  selector: 'app-billing-coefficient-dialog',
  templateUrl: './billing-coefficient-dialog.component.html',
  styleUrls: ['./billing-coefficient-dialog.component.css']
})
export class BillingCoefficientDialogComponent implements OnInit {

  billingCoefficients: BillingCoefficients;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BillingCoefficientDialogComponent>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {
    this.billingCoefficients = this.data.element;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: [(this.billingCoefficients.id !== null && this.billingCoefficients.id !== undefined) ? this.billingCoefficients.id : null, []],
      active: [(this.billingCoefficients.active !== null && this.billingCoefficients.active !== undefined) ? this.billingCoefficients.active : true, [Validators.required]],
      customerSize: [(this.billingCoefficients.customerSize !== null && this.billingCoefficients.customerSize !== undefined) ? this.billingCoefficients.customerSize : "", []],
      employeePayroll: [(this.billingCoefficients.employeePayroll !== null && this.billingCoefficients.employeePayroll !== undefined) ? this.billingCoefficients.employeePayroll : 0, [Validators.required]],
      employeeSelection: [(this.billingCoefficients.employeeSelection !== null && this.billingCoefficients.employeeSelection !== undefined) ? this.billingCoefficients.employeeSelection : 0, [Validators.required]],
      extraPayroll: [(this.billingCoefficients.extraPayroll !== null && this.billingCoefficients.extraPayroll !== undefined) ? this.billingCoefficients.extraPayroll : 0, [Validators.required]],
      extraSelection: [(this.billingCoefficients.extraSelection !== null && this.billingCoefficients.extraSelection !== undefined) ? this.billingCoefficients.extraSelection : 0, [Validators.required]],
      flexiPayroll: [(this.billingCoefficients.flexiPayroll !== null && this.billingCoefficients.flexiPayroll !== undefined) ? this.billingCoefficients.flexiPayroll : 0, [Validators.required]],
      flexiSelection: [(this.billingCoefficients.flexiSelection !== null && this.billingCoefficients.flexiSelection !== undefined) ? this.billingCoefficients.flexiSelection : 0, [Validators.required]],
      studentPayrollNotSubmitted: [(this.billingCoefficients.studentPayrollNotSubmitted !== null && this.billingCoefficients.studentPayrollNotSubmitted !== undefined) ? this.billingCoefficients.studentPayrollNotSubmitted : 0, [Validators.required]],
      studentPayrollSubmitted: [(this.billingCoefficients.studentPayrollSubmitted !== null && this.billingCoefficients.studentPayrollSubmitted !== undefined) ? this.billingCoefficients.studentPayrollSubmitted : 0, [Validators.required]],
      studentSelectionNotSubmitted: [(this.billingCoefficients.studentSelectionNotSubmitted !== null && this.billingCoefficients.studentSelectionNotSubmitted !== undefined) ? this.billingCoefficients.studentSelectionNotSubmitted : 0, [Validators.required]],
      studentSelectionSubmitted: [(this.billingCoefficients.studentSelectionSubmitted !== null && this.billingCoefficients.studentSelectionSubmitted !== undefined) ? this.billingCoefficients.studentSelectionSubmitted : 0, [Validators.required]],
      workerPayroll: [(this.billingCoefficients.workerPayroll !== null && this.billingCoefficients.workerPayroll !== undefined) ? this.billingCoefficients.workerPayroll : 0, [Validators.required]],
      workerSelection: [(this.billingCoefficients.workerSelection !== null && this.billingCoefficients.workerSelection !== undefined) ? this.billingCoefficients.workerSelection : 0, [Validators.required]],
    }
    );
  }

  onSubmit() {
    Object.assign(this.billingCoefficients, this.form.value);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.getFormValidationErrors(this.form);
      return this.openSnackBar(this.translate.instant('popup.form-invalid'), 'X', 'customSnackError');
    }

    this.facade.saveBillingCoefficient(this.billingCoefficients)
      .subscribe({
        next: billingCoefficients => {
          this.openSnackBar(this.translate.instant('popup.save-success', { id: billingCoefficients.id }), 'X', 'customSnackValid');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(
            this.translate.instant('popup.save-error', { error: error.message }),
            'X', 'customSnackError');
        }
      });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.get(key) instanceof FormGroup) {
        this.getFormValidationErrors(form.get(key) as FormGroup);
      }
    });
  }

  cancel(): void {
    this.form.reset();
    this.dialogRef.close();
  }

}


