import {OperatingUnit} from '../../../operating-units/shared/model/operating-unit.model';
import {Request} from '../../../requests/shared/model/request.model';
import {Customer} from '../../../customers/shared/model/customer.model';
import {Worker, WorkerType} from '../../../workers/shared/model/worker.model';
import {Address} from '../../../shared/model/address/address.model';

export enum DimonaStatus {
  OK = 'OK',
  OK_PARTIAL = 'OK_PARTIAL',
  NOK = 'NOK'
}

export enum ContractStatus {
  CREATED = 'CREATED',
  GENERATED = 'GENERATED',
  SIGNED = 'SIGNED'
}

export enum ContractType {
  SELECTION = 'SELECTION',
  PAYROLL = 'PAYROLL'
}

export class Contract {
  id: number;
  startDate: Date;
  endDate: Date;
  contractType: ContractType;
  workerType: WorkerType;
  workFunctionId: number;
  jointCommissionCode: string;
  costCenter: string;
  request: Request;
  operatingUnit: OperatingUnit;
  worker: Worker;
  customer: Customer;
  billingCoefficient: number;
  salaryScaleValue: number;
  referenceSalaryScaleValue: number;
  travelExpenses: number;
  travelCoefficient: number;
  qS: number;
  status: ContractStatus;
  dimonaStatus: DimonaStatus;
  contractReason: string;
  contractPeriods: ContractPeriod[] = [];
  active = true;
  contractNumber: string;
  fullTime: boolean;
  workPlaceName: string;
  workPlaceAddress: Address;

  isGenerated(): boolean {
    return [ContractStatus.GENERATED].includes(this.status);
  }
}

export class ContractPeriod {
  id: number;
  startDate: Date;
  endDate: Date;
  description: string;
  active: boolean;
  breakDuration: Date;

  computeWorkTime() {
    return (this.endDate?.getTime() - this.startDate?.getTime() - this.breakDuration?.getTime() - 3600000);
  }
}
