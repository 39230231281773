import {AbstractControl} from '@angular/forms';

export function integerValidator(control: AbstractControl) {

  if (control && (control.value !== null || true)) {

    if (typeof control.value === 'string') {
      return {
        isError: true
      };
    }
  }

  return null;
}
