import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {Language} from '../../model/language/language.model';

@Injectable()
export class LanguagesResolver implements Resolve<Observable<Language[]>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(): Observable<Language[]> {
    return this.facadeService.getLanguages().pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
