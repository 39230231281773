import {Component, Input, OnInit} from '@angular/core';
import {NgForm, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AccountType, Role, User} from '../../model/user.model';
import {OperatingUnit} from '../../../../operating-units/shared/model/operating-unit.model';
import {FacadeService} from '../../../../shared/service/facade/facade.service';
import {AuthorizationService} from '../../../../login/shared/service/authorization.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observer} from 'rxjs';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {
  userForm: UntypedFormGroup;
  @Input() user: User;
  @Input() operatingUnits: OperatingUnit[];
  roles: Role[] = [];
  currentUser: User;
  isAdmin = false;

  constructor(private formBuilder: UntypedFormBuilder,
              private facadeService: FacadeService,
              private authorizationService: AuthorizationService,
              private snackBar: MatSnackBar,
              private router: Router,
              private translate: TranslateService) {
  }


  public get accountType(): typeof AccountType {
    return AccountType;
  }

  ngOnInit() {
    this.buildForm();
    this.currentUser = this.authorizationService.getCurrentUser();
    this.roles = this.authorizationService.getUserRole(this.user.accountType ?? AccountType.CONSULTANT);
    this.isAdmin = this.authorizationService.hasRole(Role.SUPER_ADMIN);
  }

  onSubmit() {
    if (!this.userForm.valid) {
      return this.openSnackBar(this.translate.instant('users.form.message.error.form'), 'customSnackError');
    }
    const newUser: User = this.userForm.getRawValue();
    newUser.accountType = this.user.accountType;

    if (!newUser.id) {
      this.facadeService.create(newUser).subscribe({
        next: () => {
          this.router.navigate(['users']).then();
          this.openSnackBar(this.translate.instant('users.form.message.success.save'), 'customSnackValid');
        },
        error: () => {
          return this.openSnackBar(this.translate.instant('users.form.message.error.save'), 'customSnackError');
        }
      });
    } else {
      this.facadeService.saveUser(newUser).subscribe({
        next: (user: User) => {
          if (this.currentUser.id === user.id) {
            this.authorizationService.setCurrentUser(user);
          }
          this.router.navigate(['users']).then();
          this.openSnackBar(this.translate.instant('users.form.message.success.save'), 'customSnackValid');
        },
        error: () => {
          return this.openSnackBar(this.translate.instant('users.form.message.error.save'), 'customSnackError');
        }
      });
    }
  }

  changePassword(form: NgForm) {
    if (!form.valid) {
      return this.openSnackBar(this.translate.instant('users.form.message.error.passwordForm.form'), 'customSnackError');
    }

    const oldPassword = form.value.oldPassword ? form.value.oldPassword.trim() : '';
    const newPassword = form.value.newPassword.trim();
    const confirmPassword = form.value.confirmNewPassword.trim();

    if (newPassword !== confirmPassword) {
      return this.openSnackBar(this.translate.instant('users.form.message.error.passwordForm.different'), 'customSnackError');
    }

    if (!this.isAdmin) {
      this.facadeService.updateUserPassword(oldPassword, newPassword, this.user.username).subscribe(this.passwordChangeObserver());
    } else {
      this.facadeService.changePasswordAdmin(this.user.username, newPassword).subscribe(this.passwordChangeObserver());
    }
  }

  openSnackBar(message: string, pC: string, action: string = 'X') {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private passwordChangeObserver(): Partial<Observer<any>> {
    return {
      next: ignoreProperty => {
        this.openSnackBar(this.translate.instant('users.form.message.success.passwordForm.save'), 'customSnackValid');
      },
      error: ignoreProperty => {
        this.openSnackBar(this.translate.instant('users.form.message.error.passwordForm.save'), 'customSnackError');
      },
      complete: () => {
        if (this.user.id === this.authorizationService.getCurrentUser().id) {
          this.authorizationService.logout();
        }
      }
    };
  }

  private buildForm() {
    this.userForm = this.formBuilder.group({
      id: [this.user.id, []],
      username: [this.user.username, [Validators.required]],
      languageCode: [this.user.languageCode, [Validators.required]],
      firstName: [this.user.firstName, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
      operatingUnitId: [{
        value: this.user.operatingUnitId,
        disabled: this.user.operatingUnitId
      }, [Validators.required]],
      role: [this.user.role, [Validators.required]],
      workerId: [{
        value: this.user.workerId,
        disabled: true
      }, AccountType.WORKER === this.user.accountType ? [Validators.required] : []],
      customerId: [{
        value: this.user.customerId,
        disabled: true
      }, AccountType.CUSTOMER === this.user.accountType ? [Validators.required] : []]
    });

    if (this.user.id) {
      this.userForm.controls.password.setValidators(null);
      this.userForm.controls.confirmPassword.setValidators(null);
      this.userForm.updateValueAndValidity();
    }
  }
}
