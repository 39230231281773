export const environment = {
  version: require('../../package.json').version,
  env_name: 'T',
  production: false,
  isNursing: false,
  storage_service_bucket: 'hs-test-storage-service',
  customer_service_URL: 'https://hs-test-api.comexis.net/customer-service/',
  operating_unit_service_URL: 'https://hs-test-api.comexis.net/operating-unit-service/',
  worker_service_URL: 'https://hs-test-api.comexis.net/worker-service/',
  request_service_URL: 'https://hs-test-api.comexis.net/request-service/',
  contract_service_URL: 'https://hs-test-api.comexis.net/contract-service/',
  reference_data_service_URL: 'https://hs-test-api.comexis.net/reference-data-service/',
  prestation_service_URL: 'https://hs-test-api.comexis.net/prestation-service/',
  dimona_service_URL: 'https://hs-test-api.comexis.net/dimona-service/',
  elastic_search_URL: 'https://hs-test-api.comexis.net/search-service/',
  bill_service_URL: 'https://hs-test-api.comexis.net/billing-service/',
  storage_service_URL: 'https://hs-test-api.comexis.net/storage-service/',
  user_service_URL: 'https://hs-test-api.comexis.net/',
  status_service_URL: 'https://hs-test-api.comexis.net/status-service/'
};
