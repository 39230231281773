export class Pagination {
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
}

export function checkIfValidSearchTerm(searchTerm) {
  return (searchTerm && searchTerm.length >= 3);
}
