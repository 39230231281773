import {Serializer} from 'src/app/shared/model/serializer';
import {
  BillingCoefficients,
  BillingData,
  BusinessContact,
  CommercialFollowup,
  Customer,
  LegalData,
  PaymentTerms,
  SubsidiaryAddress,
  UncomfortableWorkingHours
} from './customer.model';
import {Address} from '../../../shared/model/address/address.model';
import {AddressSerializer} from '../../../shared/model/address/address.serializer';
import {BillingCoefficientSerializer} from './billing-coefficient.serializer';
import {UncomfortableWorkingHoursSerializer} from './uncomfortable-working-hours.serializer';
import {PaymentTermsSerializer} from './payment-terms.serializer';
import {SubsidiaryAddressSerializer} from './subsidiary-address.serializer';

export class CustomerSerializer implements Serializer {
  private addressSerializer: AddressSerializer = new AddressSerializer();
  private subsidiaryAddressSerializer: SubsidiaryAddressSerializer = new SubsidiaryAddressSerializer();
  private billingCoefficientSerializer: BillingCoefficientSerializer = new BillingCoefficientSerializer();
  private uncomfortableWorkingHoursSerializer: UncomfortableWorkingHoursSerializer = new UncomfortableWorkingHoursSerializer();
  private paymentTermsSerializer: PaymentTermsSerializer = new PaymentTermsSerializer();

  private static businessContactToJson(businessContact: BusinessContact): any {
    return {
      function: businessContact.function,
      name: businessContact.name,
      email: businessContact.email,
      phone: businessContact.phone,
      fax: businessContact.fax,
      mobile: businessContact.mobile,
      languageCode: businessContact.languageCode,
      contactMethod: businessContact.contactMethod,
      civility: businessContact.civility,
      businessArea: businessContact.businessArea
    };
  }

  private static businessContactFromJson(json: any): BusinessContact {
    const businessContact: BusinessContact = new BusinessContact();

    businessContact.function = json.function;
    businessContact.name = json.name;
    businessContact.email = json.email;
    businessContact.phone = json.phone;
    businessContact.fax = json.fax;
    businessContact.mobile = json.mobile;
    businessContact.languageCode = json.languageCode;
    businessContact.contactMethod = json.contactMethod;
    businessContact.civility = json.civility;
    businessContact.businessArea = json.businessArea;

    return businessContact;
  }

  private static fromJsonToLegalData(jsonLegalData: any): LegalData {
    const legalData: LegalData = new LegalData();

    legalData.unionDelegation = jsonLegalData.unionDelegation;
    legalData.socialReportStart = jsonLegalData.socialReportStart;
    legalData.socialReportEnd = jsonLegalData.socialReportEnd;
    legalData.invoicingPeriodicity = jsonLegalData.invoicingPeriodicity;
    legalData.paymentDelay = jsonLegalData.paymentDelay;
    legalData.weeklyActivity = jsonLegalData.weeklyActivity;
    legalData.successiveContracts = jsonLegalData.successiveContracts;
    legalData.holidayClosing = jsonLegalData.holidayClosing;
    legalData.naceCodeId = jsonLegalData.naceCodeId;
    if (jsonLegalData.costCenter) {
      legalData.costCenter = jsonLegalData.costCenter.split(';');
    }

    return legalData;
  }

  private static fromJsonToCommercialFollowup(jsonCommercialFollowup: any): CommercialFollowup {
    const commercialFollowup: CommercialFollowup = new CommercialFollowup();

    commercialFollowup.comment = jsonCommercialFollowup.comment;

    return commercialFollowup;
  }

  ////////////////////// USE THE BILLING COEFFICIENT SERIALISER INSTEAD ///////////////////////////////

  // private static fromJsonToBillingCoefficients(billingCoefficientsJson: any): BillingCoefficients {
  //   const billingCoefficients: BillingCoefficients = new BillingCoefficients();

  //   billingCoefficients.employeePayroll = billingCoefficientsJson.employeePayroll;
  //   billingCoefficients.employeeSelection = billingCoefficientsJson.employeeSelection;
  //   billingCoefficients.extraPayroll = billingCoefficientsJson.extraPayroll;
  //   billingCoefficients.extraSelection = billingCoefficientsJson.extraSelection;
  //   billingCoefficients.flexiPayroll = billingCoefficientsJson.flexiPayroll;
  //   billingCoefficients.flexiSelection = billingCoefficientsJson.flexiSelection;
  //   billingCoefficients.studentPayrollNotSubmitted = billingCoefficientsJson.studentPayrollNotSubmitted;
  //   billingCoefficients.studentPayrollSubmitted = billingCoefficientsJson.studentPayrollSubmitted;
  //   billingCoefficients.studentSelectionNotSubmitted = billingCoefficientsJson.studentSelectionNotSubmitted;
  //   billingCoefficients.studentSelectionSubmitted = billingCoefficientsJson.studentSelectionSubmitted;
  //   billingCoefficients.workerPayroll = billingCoefficientsJson.workerPayroll;
  //   billingCoefficients.workerSelection = billingCoefficientsJson.workerSelection;

  //   return billingCoefficients;
  // }

  private static legalDataToJson(legalData: LegalData) {
    return {
      unionDelegation: legalData.unionDelegation,
      socialReportStart: legalData.socialReportStart,
      socialReportEnd: legalData.socialReportEnd,
      invoicingPeriodicity: legalData.invoicingPeriodicity,
      paymentDelay: legalData.paymentDelay,
      weeklyActivity: legalData.weeklyActivity,
      successiveContracts: legalData.successiveContracts,
      holidayClosing: legalData.holidayClosing,
      naceCodeId: legalData.naceCodeId,
      costCenter: legalData.costCenter.join(';').toUpperCase()
    };
  }

  private static commercialFollowupToJson(commercialFollowup: CommercialFollowup) {
    return {
      comment: commercialFollowup.comment
    };
  }

  fromJson(json: any): Customer {
    const customer: Customer = new Customer();
    let address: Address = new Address();
    let businessContact1: BusinessContact = new BusinessContact();
    let businessContact2: BusinessContact = new BusinessContact();
    let businessContact3: BusinessContact = new BusinessContact();
    let businessContact4: BusinessContact = new BusinessContact();
    let businessContact5: BusinessContact = new BusinessContact();
    let billingData: BillingData = new BillingData();
    let paymentTerms: PaymentTerms = new PaymentTerms();
    let uncomfortableWorkingHours: UncomfortableWorkingHours = new UncomfortableWorkingHours();
    let legalData: LegalData = new LegalData();
    let commercialFollowup: CommercialFollowup = new CommercialFollowup();
    let billingCoefficients: BillingCoefficients = new BillingCoefficients();

    customer.id = json.id;
    customer.customerNumber = json.customerNumber;
    customer.active = json.active;
    customer.operatingUnitId = json.operatingUnitId;
    customer.enterpriseNumber = json.enterpriseNumber;
    customer.name = json.name;
    customer.prefix = json.prefix;
    customer.customerSize = json.customerSize;

    const jsonAddress = json.address;
    if (jsonAddress) {
      address = this.addressSerializer.fromJson(jsonAddress);
    }
    customer.address = address;

    const subsidiaryAddresses: SubsidiaryAddress[] = [];
    const subsidiaryAddressesJson = json.subsidiaryAddresses;
    if (subsidiaryAddressesJson) {
      subsidiaryAddressesJson.forEach(
        subsidiaryAddressJson => {
          subsidiaryAddresses.push(this.subsidiaryAddressSerializer.fromJson(subsidiaryAddressJson));
        }
      );
    }
    customer.subsidiaryAddresses = subsidiaryAddresses;

    const jsonBusinessContact1 = json.businessContact1;
    if (jsonBusinessContact1) {
      businessContact1 = CustomerSerializer.businessContactFromJson(jsonBusinessContact1);
    }
    customer.businessContact1 = businessContact1;

    const jsonBusinessContact2 = json.businessContact2;
    if (jsonBusinessContact2) {
      businessContact2 = CustomerSerializer.businessContactFromJson(jsonBusinessContact2);
    }
    customer.businessContact2 = businessContact2;

    const jsonBusinessContact3 = json.businessContact3;
    if (jsonBusinessContact3) {
      businessContact3 = CustomerSerializer.businessContactFromJson(jsonBusinessContact3);
    }
    customer.businessContact3 = businessContact3;

    const jsonBusinessContact4 = json.businessContact4;
    if (jsonBusinessContact4) {
      businessContact4 = CustomerSerializer.businessContactFromJson(jsonBusinessContact4);
    }
    customer.businessContact4 = businessContact4;

    const jsonBusinessContact5 = json.businessContact5;
    if (jsonBusinessContact5) {
      businessContact5 = CustomerSerializer.businessContactFromJson(jsonBusinessContact5);
    }
    customer.businessContact5 = businessContact5;

    const jsonBillingData = json.billingData;
    if (jsonBillingData) {
      billingData = this.fromJsonToBillingData(jsonBillingData);
    }
    customer.billingData = billingData;

    const jsonPaymentTerms = json.paymentTerms;
    if (jsonPaymentTerms) {
      paymentTerms = this.paymentTermsSerializer.fromJson(jsonPaymentTerms);
    }
    customer.paymentTerms = paymentTerms;

    const jsonUncomfortableWorkingHours = json.uncomfortableWorkingHours;
    if (jsonUncomfortableWorkingHours) {
      uncomfortableWorkingHours = this.uncomfortableWorkingHoursSerializer.fromJson(jsonUncomfortableWorkingHours);
    }
    customer.uncomfortableWorkingHours = uncomfortableWorkingHours;

    const jsonLegalData = json.legalData;
    if (jsonLegalData) {
      legalData = CustomerSerializer.fromJsonToLegalData(jsonLegalData);
    }
    customer.legalData = legalData;

    const jsonCommercialFollowup = json.commercialFollowup;
    if (jsonCommercialFollowup) {
      commercialFollowup = CustomerSerializer.fromJsonToCommercialFollowup(jsonCommercialFollowup);
    }
    customer.commercialFollowup = commercialFollowup;

    const billingCoefficientsJson = json.billingCoefficients;
    if (billingCoefficientsJson) {
      billingCoefficients = this.billingCoefficientSerializer.fromJson(billingCoefficientsJson);
    }
    customer.billingCoefficients = billingCoefficients;

    return customer;
  }

  toJson(customer: Customer): any {

    const subsidiaryAddresses: any [] = [];
    if (customer.subsidiaryAddresses) {
      customer.subsidiaryAddresses.forEach(
        subsidiaryAddress => {
          subsidiaryAddresses.push(this.subsidiaryAddressSerializer.toJson(subsidiaryAddress));
        }
      );
    }

    return {
      id: customer.id,
      customerNumber: customer.customerNumber,
      active: customer.active,
      operatingUnitId: customer.operatingUnitId,
      enterpriseNumber: customer.enterpriseNumber,
      name: customer.name,
      prefix: customer.prefix,
      address: this.addressSerializer.toJson(customer.address),
      subsidiaryAddresses: subsidiaryAddresses,
      businessContact1: CustomerSerializer.businessContactToJson(customer.businessContact1),
      businessContact2: CustomerSerializer.businessContactToJson(customer.businessContact2),
      businessContact3: CustomerSerializer.businessContactToJson(customer.businessContact3),
      businessContact4: CustomerSerializer.businessContactToJson(customer.businessContact4),
      businessContact5: CustomerSerializer.businessContactToJson(customer.businessContact5),
      billingData: this.billingDataToJson(customer.billingData),
      paymentTerms: this.paymentTermsSerializer.toJson(customer.paymentTerms),
      uncomfortableWorkingHours: this.uncomfortableWorkingHoursSerializer.toJson(customer.uncomfortableWorkingHours),
      legalData: CustomerSerializer.legalDataToJson(customer.legalData),
      commercialFollowup: CustomerSerializer.commercialFollowupToJson(customer.commercialFollowup),
      customerSize: customer.customerSize,
      billingCoefficients: this.billingCoefficientSerializer.toJson(customer.billingCoefficients)
    };
  }

  private fromJsonToBillingData(jsonBillingData: any): BillingData {
    const billingData: BillingData = new BillingData();
    let billingDataAddress = new Address();

    billingData.vatCode = jsonBillingData.vatCode;
    billingData.jointCommissionCode = jsonBillingData.jointCommissionCode;
    billingData.onssNumber = jsonBillingData.onssNumber;
    billingData.vatNumber = jsonBillingData.vatNumber;
    billingData.iban = jsonBillingData.iban;
    billingData.bic = jsonBillingData.bic;
    billingData.billingEmail = jsonBillingData.billingEmail;
    billingData.multiSiteBilling = jsonBillingData.multiSiteBilling;
    billingData.invoicingType = jsonBillingData.invoicingType;
    billingData.socialCharter = jsonBillingData.socialCharter;
    billingData.specificScale = jsonBillingData.specificScale;
    billingData.seniorityTaken = jsonBillingData.seniorityTaken;

    const jsonBillingDataAddress = jsonBillingData.billingAddress;
    if (jsonBillingDataAddress) {
      billingDataAddress = this.addressSerializer.fromJson(jsonBillingDataAddress);
    }
    billingData.billingAddress = billingDataAddress;

    return billingData;
  }

  private billingDataToJson(billingData: BillingData) {
    return {
      vatCode: billingData.vatCode,
      jointCommissionCode: billingData.jointCommissionCode,
      onssNumber: billingData.onssNumber,
      vatNumber: billingData.vatNumber,
      iban: billingData.iban,
      bic: billingData.bic,
      billingAddress: this.addressSerializer.toJson(billingData.billingAddress),
      billingEmail: billingData.billingEmail,
      multiSiteBilling: billingData.multiSiteBilling,
      invoicingType: billingData.invoicingType,
      socialCharter: billingData.socialCharter,
      specificScale: billingData.specificScale,
      seniorityTaken: billingData.seniorityTaken
    };
  }
}
