import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {Prestation} from '../../../prestations/shared/model/prestation.model';

@Injectable()
export class PrestationsCorrectionResolver implements Resolve<Observable<Prestation[]>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Prestation[]> {
    const requestId = route.queryParams['r'];
    return this.facadeService.getPrestationCorrectionFromRequest(requestId).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
