import {Component} from '@angular/core';
import {Invoice} from '../shared/model/invoice.model';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {BusinessArea, BusinessContact, Customer} from '../../customers/shared/model/customer.model';
import {FacadeService} from '../../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import { SnackbarComponent } from 'src/app/shared/component/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-bill-view',
  templateUrl: './bill-view.component.html',
  styleUrls: ['./bill-view.component.css']
})
export class BillViewComponent {
  invoice: Invoice;
  favoriteBusinessContactForCustomer: BusinessContact;

  constructor(private route: ActivatedRoute,
              private facadeService: FacadeService,
              private location: Location,
              public translate: TranslateService,
              private snackBar: MatSnackBar) {
    this.getInvoice();
  }

  onBack(): void {
    this.location.back();
  }
  
  printPDF() {
    this.openCustomSnackBar(this.translate.instant('bills.view.downloading'));
    this.facadeService.getInvoiceOriginalFileById(this.invoice.id).subscribe({
      next: res => {
        const file = new Blob([res], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        this.openSnackBar(this.translate.instant('bills.view.downloadingSuccessfull'));
        window.open(fileURL, '_blank');
      },
      error: () => {
        alert(this.translate.instant('bills.view.message.error.file'));
      }
    });
  }

  getFavoriteBusinessContactForCustomer(customer: Customer): BusinessContact {
    if (customer.businessContact1 && customer.businessContact1.businessArea === BusinessArea.BILLING) {
      return customer.businessContact1;
    }
    if (customer.businessContact2 && customer.businessContact2.businessArea === BusinessArea.BILLING) {
      return customer.businessContact2;
    }
    if (customer.businessContact3 && customer.businessContact3.businessArea === BusinessArea.BILLING) {
      return customer.businessContact3;
    }
    if (customer.businessContact4 && customer.businessContact4.businessArea === BusinessArea.BILLING) {
      return customer.businessContact4;
    }
    if (customer.businessContact5 && customer.businessContact5.businessArea === BusinessArea.BILLING) {
      return customer.businessContact5;
    }

    return null;
  }

  private getInvoice() {
    this.invoice = this.route.snapshot.data['invoice'];
    this.favoriteBusinessContactForCustomer = this.getFavoriteBusinessContactForCustomer(this.invoice.customer);
  }

  private openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: [pC]
    });
  }

  openCustomSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.openFromComponent(SnackbarComponent, {
      panelClass: [pC],
      data: {
        message: message
      }
    });
  }
}
