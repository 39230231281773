import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FacadeService } from '../../../shared/service/facade/facade.service';
import { MatDialog } from '@angular/material/dialog';
import { JointCommissionCoefficientPage } from 'src/app/shared/model/pagination/joint-commision-coefficient/joint-commision-coefficient-page.model';
import { JointCommissionCoefficientAdmin } from '../../model/joint-commission-coefficient.model';
import { JointCommissionCoefficientDialogComponent } from 'src/app/shared/component/admin-dialog/joint-commission-coefficient-dialog/joint-commission-coefficient-dialog.component';

@Component({
  selector: 'app-joint-commission-coefficient',
  templateUrl: './joint-commission-coefficient.component.html',
  styleUrls: ['./joint-commission-coefficient.component.css', '../../admin.component.css']
})
export class JointCommissionCoefficientComponent implements OnInit {

  @ViewChild('JointCommissionCoefficientPaginator', { static: true }) paginator: MatPaginator;

  jointCommissionCoefficient: JointCommissionCoefficientAdmin;

  dataSource = new MatTableDataSource<JointCommissionCoefficientAdmin>();
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 20;
  length: number;

  constructor(
    private facadeService: FacadeService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = [
    "id",
    "active",
    "jointCommissionCode",
    "nightShiftWorkType",
    "clothesBillingCoefficient",
    "coefficientClothes",
    "coefficientEcoCheques",
    "coefficientPension",
    "coefficientPurchasing",
    "dimonaBillingCoefficient",
    "dimonaFee",
    "ecoChequeBillingCoefficient",
    "flexyRate",
    "mealEmployer",
    "mealFacial",
    "mealVoucher",
    "pensionBillingCoefficient",
    "purchasingBillingCoefficient",
    "travelExpenseBillingCoefficient",
    "travelExpenses",
    "riskedActivity",
    "rtt",
    "whCutSchedule",
    "whLate",
    "whNight",
    "whPublicHoliday",
    "whSaturday",
    "whSunday"
  ];

  public getData(event?: PageEvent) {
    this.pageIndex = event ? event.pageIndex : 0;
    this.pageSize = event ? event.pageSize : 20;
    this.facadeService.getPageJointCommissionCoefficients(this.pageIndex, this.pageSize).subscribe(
      (data: JointCommissionCoefficientPage) => {
        this.dataSource.data = data.content;
        this.dataSource.paginator = this.paginator;
        this.length = data.totalElements; 
      }
    );
    return event;
  }

  openPopUp(row) {
    const dialogRef = this.dialog.open(JointCommissionCoefficientDialogComponent, {
      data: { element: row },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }

  addElement() {
    this.jointCommissionCoefficient = new JointCommissionCoefficientAdmin;
    this.openPopUp(this.jointCommissionCoefficient);
  }

}
