import {TranslateService} from '@ngx-translate/core';

const commonCountriesCode = ['BE', 'FR', 'NL', 'LU', 'DE'];

export class Country {
  id: number;
  active: boolean;
  code: string;
  nameDE: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  nameTranslated: string;
  isEuropean: boolean;
  commonCountry = false;

  public translateCountry(name: string): Country {
    this.nameTranslated = name;
    return this;
  }
}

export function convertCountries(countries: Country[], translate: TranslateService): Country[] {
  countries = countries.filter(c => c.commonCountry === false);
  switch (translate.currentLang.toLowerCase()) {
    case 'fr':
      return addCommonCountries(countries.map(c => c.translateCountry(c.nameFR)));
    case 'nl':
      return addCommonCountries(countries.map(c => c.translateCountry(c.nameNL)));
    case 'de':
      return addCommonCountries(countries.map(c => c.translateCountry(c.nameDE)));
    default :
      return addCommonCountries(countries.map(c => c.translateCountry(c.nameEN)));
  }
}

function addCommonCountries(countries: Country[]): Country[] {
  const commons = [];
  countries
    .filter(c => commonCountriesCode.indexOf(c.code) !== -1)
    .forEach(c => {
      const common = {...c};
      common.commonCountry = true;
      commons.push(common);
    });
  return sortCountries(commons).concat(sortCountries(countries));
}

function sortCountries(countries: Country[]): Country[] {
  return countries.sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
}
