import {JointCommission} from '../joint-commission/joint-commission.model';
import {TranslateService} from '@ngx-translate/core';

export class WorkFunction {
  id: number;
  active: boolean;
  category: number;
  code: string;
  nameDE: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  nameTranslated: string;
  validityEndDate: Date;
  validityStartDate: Date;
  jointCommission: JointCommission;

  public translateWorkFunction(name: string): WorkFunction {
    this.nameTranslated = name;
    return this;
  }
}

export function convertWorkFunction(workFunctions: WorkFunction[], translate: TranslateService): WorkFunction[] {
  switch (translate.currentLang.toLowerCase()) {
    case 'fr':
      return workFunctions.map(wf => wf.translateWorkFunction(wf.nameFR));
    case 'nl':
      return workFunctions.map(wf => wf.translateWorkFunction(wf.nameNL));
    case 'de':
      return workFunctions.map(wf => wf.translateWorkFunction(wf.nameDE));
    default :
      return workFunctions.map(wf => wf.translateWorkFunction(wf.nameEN));
  }
}

export function convertSingleWorkFunction(workFunctions: WorkFunction, translate: TranslateService): WorkFunction {
  switch (translate.currentLang.toLowerCase()) {
    case 'fr':
      return workFunctions.translateWorkFunction(workFunctions.nameFR);
    case 'nl':
      return workFunctions.translateWorkFunction(workFunctions.nameNL);
    case 'de':
      return workFunctions.translateWorkFunction(workFunctions.nameDE);
    default :
      return workFunctions.translateWorkFunction(workFunctions.nameEN);
  }
}


