import {Serializer} from '../../../shared/model/serializer';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {PrestationPeriod} from './prestation-period.model';
import {PrestationPeriodCodeSerializer} from './prestation-period-code.serializer';
import {PrestationPeriodCode} from './prestation-period-code.model';

export class PrestationPeriodSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();
  private prestationPeriodCodeSerializer: PrestationPeriodCodeSerializer = new PrestationPeriodCodeSerializer();

  fromJson(json: any): PrestationPeriod {
    const period = new PrestationPeriod();
    const prestationPeriodCodes: PrestationPeriodCode[] = [];

    period.id = json.id;
    period.contractPeriodId = json.contractPeriodId;
    //  period.prestation = this.prestationSerializer.fromJson(json.prestation);
    period.plannedStartDate = this.dateSerializer.fromJson(json.plannedStartDate);
    period.plannedEndDate = this.dateSerializer.fromJson(json.plannedEndDate);
    period.plannedPauseTime = this.dateSerializer.hoursFromJson(json.plannedPauseTime);
    period.actualStartDate = this.dateSerializer.fromJson(json.actualStartDate);
    period.actualEndDate = this.dateSerializer.fromJson(json.actualEndDate);
    period.actualCustomerStartDate = this.dateSerializer.fromJson(json.actualCustomerStartDate);
    period.actualCustomerEndDate = this.dateSerializer.fromJson(json.actualCustomerEndDate);
    period.actualWorkerEndDate = this.dateSerializer.fromJson(json.actualWorkerEndDate);
    period.actualWorkerStartDate = this.dateSerializer.fromJson(json.actualWorkerStartDate);
    period.actualPauseTime = this.dateSerializer.hoursFromJson(json.actualPauseTime);
    period.status = json.status;
    period.description = json.description;
    period.function = json.function;
    period.workerValidationBy = json.workerValidationBy;
    period.workerValidationId = json.workerValidationId;
    period.workerValidationDate = this.dateSerializer.fromJson(json.workerValidationDate);
    period.customerValidationBy = json.customerValidationBy;
    period.customerValidationId = json.customerValidationId;
    period.customerValidationDate = this.dateSerializer.fromJson(json.customerValidationDate);
    period.customerRejectionDate = this.dateSerializer.fromJson(json.customerRejectionDate);
    period.consultantValidationBy = json.consultantValidationBy;
    period.consultantValidationId = json.consultantValidationId;
    period.consultantValidationDate = this.dateSerializer.fromJson(json.consultantValidationDate);
    period.correctionCode = json.correctionCode;
    period.correctionLabel = json.correctionLabel;
    period.active = json.active;
    period.actualHours = new Date(null, null, null, json.actualHours[0], json.actualHours[1]);
    period.totalActualHours = new Date(null, null, null, json.totalActualHours[0], json.totalActualHours[1]);
    period.updated = json.updated;
    period.minutes = json.minutes;
    period.processed = json.processed;
    period.justificationFile = json.justificationFile;
    const jsonPrestationPeriodCodes = json.prestationPeriodCodes;
    if (jsonPrestationPeriodCodes) {
      jsonPrestationPeriodCodes.forEach(
        prestationPeriodCode => {
          prestationPeriodCodes.push(this.prestationPeriodCodeSerializer.fromJson(prestationPeriodCode));
        });
    }
    period.prestationPeriodCodes = prestationPeriodCodes;

    return period;
  }

  toJson(period: PrestationPeriod): any {
    const prestationPeriodCodes: any[] = [];
    period.prestationPeriodCodes.forEach(
      prestationPeriodCode => {
        prestationPeriodCode.prestationPeriod = period;
        prestationPeriodCodes.push(this.prestationPeriodCodeSerializer.toJson(prestationPeriodCode));
      }
    );

    return {
      id: period.id,
      contractPeriodId: period.contractPeriodId,
      prestation: {
        id: period.prestation.id
      },
      plannedStartDate: this.dateSerializer.dateWithTimeToJson(period.plannedStartDate),
      plannedEndDate: this.dateSerializer.dateWithTimeToJson(period.plannedEndDate),
      plannedPauseTime: this.dateSerializer.hoursToJson(period.plannedPauseTime),
      actualStartDate: this.dateSerializer.dateWithTimeToJson(period.actualStartDate),
      actualEndDate: this.dateSerializer.dateWithTimeToJson(period.actualEndDate),
      actualCustomerStartDate: this.dateSerializer.dateWithTimeToJson(period.actualCustomerStartDate),
      actualCustomerEndDate: this.dateSerializer.dateWithTimeToJson(period.actualCustomerEndDate),
      actualWorkerEndDate: this.dateSerializer.dateWithTimeToJson(period.actualWorkerEndDate),
      actualWorkerStartDate: this.dateSerializer.dateWithTimeToJson(period.actualWorkerStartDate),
      actualPauseTime: this.dateSerializer.hoursToJson(period.actualPauseTime),
      status: period.status,
      description: period.description,
      function: period.function,
      workerValidationBy: period.workerValidationBy,
      workerValidationId: period.workerValidationId,
      workerValidationDate: this.dateSerializer.dateWithTimeToJson(period.workerValidationDate),
      customerValidationBy: period.customerValidationBy,
      customerValidationId: period.customerValidationId,
      customerValidationDate: this.dateSerializer.dateWithTimeToJson(period.customerValidationDate),
      customerRejectionDate: this.dateSerializer.dateWithTimeToJson(period.customerRejectionDate),
      consultantValidationBy: period.consultantValidationBy,
      consultantValidationId: period.consultantValidationId,
      consultantValidationDate: this.dateSerializer.dateWithTimeToJson(period.consultantValidationDate),
      correctionCode: period.correctionCode,
      correctionLabel: period.correctionLabel,
      active: period.active,
      justificationFile: period.justificationFile,
      prestationPeriodCodes: prestationPeriodCodes
    };
  }
}
