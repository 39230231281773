import {TranslateService} from '@ngx-translate/core';

export class CompanyPrefix {
  id: number;
  active: boolean;
  code: string;
  nameDE: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  nameTranslated: string;

  public translateCompanyPrefix(name: string): CompanyPrefix {
    this.nameTranslated = name;
    return this;
  }
}

export function convertCompanyPrefix(companyPrefixes: CompanyPrefix[], translate: TranslateService): CompanyPrefix[] {
  switch (translate.currentLang.toLowerCase()) {
    case 'fr':
      return companyPrefixes.map(cp => cp.translateCompanyPrefix(cp.nameFR))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
    case 'nl':
      return companyPrefixes.map(cp => cp.translateCompanyPrefix(cp.nameNL))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
    case 'de':
      return companyPrefixes.map(cp => cp.translateCompanyPrefix(cp.nameDE))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
    default :
      return companyPrefixes.map(cp => cp.translateCompanyPrefix(cp.nameEN))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
  }
}
