import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {Role} from '../../../users/shared/model/user.model';
import {AuthorizationService} from '../../../login/shared/service/authorization.service';
import {RequestPage} from '../../model/pagination/request/request-page.model';

@Injectable()
export class ErrorRequestsResolver implements Resolve<Observable<RequestPage>> {
  constructor(private facadeService: FacadeService,
              private authorizationService: AuthorizationService) {
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  resolve(): Observable<RequestPage> {
    if (this.hasRole()) {
      return this.facadeService.getPageRequestsWithError(0, 5).pipe(
        map((data) => data),
        catchError(error => {
          return of({error: error});
        })
      );
    }
  }
}
