import {Serializer} from '../serializer';
import {CorrectionType} from './correction-type.model';

export class CorrectionTypeSerializer implements Serializer {
  fromJson(json: any): CorrectionType {
    const correctionType: CorrectionType = new CorrectionType();

    correctionType.id = json.id;
    correctionType.active = json.active;
    correctionType.correctionCode = json.correctionCode;
    correctionType.correctionCodeLabel = json.correctionCodeLabel;

    return correctionType;
  }

  toJson(correctionType: CorrectionType): any {
    return {
      id: correctionType.id,
      active: correctionType.active,
      correctionCode: correctionType.correctionCode,
      correctionCodeLabel: correctionType.correctionCodeLabel
    };
  }

}
