import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {APP_BASE_HREF, DatePipe, registerLocaleData} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CustomersComponent} from './customers/customers.component';
import {CustomerNewComponent} from './customers/customer-new/customer-new.component';
import {LoginComponent} from './login/login.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {UsersComponent} from './users/users.component';
import {OperatingUnitsComponent} from './operating-units/operating-units.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomerEditComponent} from './customers/customer-edit/customer-edit.component';
import {CustomerViewComponent} from './customers/customer-view/customer-view.component';
import {AuthorizationService} from './login/shared/service/authorization.service';
import {UserService} from './users/shared/service/user.service';
import {OperatingUnitEditComponent} from './operating-units/operating-unit-edit/operating-unit-edit.component';
import {OperatingUnitNewComponent} from './operating-units/operating-unit-new/operating-unit-new.component';
import {WorkersComponent} from './workers/workers.component';
import {WorkerNewComponent} from './workers/worker-new/worker-new.component';
import {WorkerEditComponent} from './workers/worker-edit/worker-edit.component';
import {WorkerViewComponent} from './workers/worker-view/worker-view.component';
import {MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RequestsComponent} from './requests/requests.component';
import {RequestNewComponent} from './requests/request-new/request-new.component';
import {RequestEditComponent} from './requests/request-edit/request-edit.component';
import {PlanningComponent} from './planning/planning.component';
import {ListingRequestComponent} from './requests/shared/component/listing-request/listing-request.component';
import {ContractsComponent} from './contracts/contracts.component';
import {ContractViewComponent} from './contracts/contract-view/contract-view.component';
import {ContractListComponent} from './contracts/shared/component/contract-list/contract-list.component';
import {FacadeService} from './shared/service/facade/facade.service';
import {RequestFormComponent} from './requests/shared/component/request-form/request-form.component';
import {CustomerFormComponent} from './customers/shared/component/customer-form/customer-form.component';
import {OperatingUnitFormComponent} from './operating-units/shared/component/operating-unit-form/operating-unit-form.component';
import {WorkerFormComponent} from './workers/shared/component/worker-form/worker-form.component';
import {MaterialModule} from './material.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MyDateAdapter} from './shared/adapter/date.adapter';
import {UserNewComponent} from './users/user-new/user-new.component';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {UserFormComponent} from './users/shared/component/user-form/user-form.component';
import {PrestationsComponent} from './prestations/prestations.component';
import {ErrorListingRequestComponent} from './requests/shared/component/error-listing-request/error-listing-request.component';
import {CustomMissingTranslationHandler} from './custom-missing-translation-handler';
import {PaidRequestComponent} from './requests/shared/component/paid-request/paid-request.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {RequestErrorDetailComponent} from './requests/shared/component/request-error-detail/request-error-detail.component';
import {
  ErrorDetailDialogComponent
} from './requests/shared/component/request-error-detail/error-detail-dialog/error-detail-dialog.component';
import {NonAvailabilityDialogComponent} from './workers/worker-view/non-availability-dialog/non-availability-dialog.component';
import {
  ValidateRequestDialogComponent
} from './requests/shared/component/request-form/validate-request-dialog/validate-request-dialog.component';
import {
  AddEventPeriodDialogComponent
} from './requests/shared/component/request-form/add-event-period-dialog/add-event-period-dialog.component';
import {PrestationDialogComponent} from './prestations/prestation-validation/prestation-dialog/prestation-dialog.component';
import {BusinessContactComponent} from './customers/shared/component/customer-form/business-contact/business-contact.component';
import {BusinessContactViewComponent} from './customers/customer-view/business-contact-view/business-contact-view.component';
import {PrestationCorrectionComponent} from './prestations/prestation-correction/prestation-correction.component';
import {PrestationValidationComponent} from './prestations/prestation-validation/prestation-validation.component';
import {BillsComponent} from './bills/bills.component';
import {BillViewComponent} from './bills/bill-view/bill-view.component';
import {ResolverModule} from './resolver.module';
import {ProcessedDocumentDialogComponent} from './workers/worker-view/processed-document-dialog/processed-document-dialog.component';
import {UploadDocumentComponent} from './workers/worker-view/upload-document/upload-document.component';
import {ConfirmationDialogComponent} from './shared/component/confirmation-dialog/confirmation-dialog.component';
import {ExportDialogComponent} from './shared/component/export-dialog/export-dialog.component';
import {WorkersLiteComponent} from './workers-lite/workers-lite.component';
import localeFr from '@angular/common/locales/fr';
import {
  CancelCorrectionPrestationDialogComponent
} from './prestations/prestation-correction/cancel-correction-prestation-dialog/cancel-correction-prestation-dialog.component';
import {EditCorrectionDialogComponent} from './prestations/prestation-correction/edit-correction-dialog/edit-correction-dialog.component';
import {
  ValidationCorrectionDialogComponent
} from './prestations/prestation-correction/validation-correction-dialog/validation-correction-dialog.component';
import {SpinnerComponent} from './shared/component/spinner/spinner.component';
import {HttpInterceptorService} from './shared/component/spinner/service/http-interceptor.service';
import {SupervisorGuard} from './login/shared/guard/supervisor-guard.service';
import {SuperAdminGuard} from './login/shared/guard/super-admin-guard.service';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {
  PrestationRejectDialogComponent
} from './prestations/prestation-validation/prestation-reject-dialog/prestation-reject-dialog.component';
import {NgxIbanModule} from 'ngx-iban';
import {PendingListingRequestsComponent} from './requests/shared/component/pending-listing-requests/pending-listing-requests.component';
import {CopyRequestDialogComponent} from './contracts/contract-view/copy-request-dialog/copy-request-dialog.component';
import {RequestResetPasswordComponent} from './reset-password/request-reset-password/request-reset-password.component';
import {EditPasswordComponent} from './reset-password/edit-password/edit-password.component';
import {AdminComponent} from './admin/admin.component';
import {ExportContractsComponent} from './shared/component/export-dialog/export-contracts/export-contracts.component';
import {ExportDimonasComponent} from './shared/component/export-dialog/export-dimonas/export-dimonas.component';
import {ExportPrestationsComponent} from './shared/component/export-dialog/export-prestations/export-prestations.component';
import {ExportPrestationsNewComponent} from './shared/component/export-dialog/export-prestations-new/export-prestations-new.component';
import {ExportPrestationscodesComponent} from './shared/component/export-dialog/export-prestations-codes/export-prestations-codes.component';
import {ExportInvoicesComponent} from './shared/component/export-dialog/export-invoices/export-invoices.component';
import {ExportInvoicesExactOnlineComponent} from './shared/component/export-dialog/export-invoices-exact-online/export-invoices-exact-online.component';
import {ExportGenericComponent} from './shared/component/export-dialog/export-generic/export-generic.component';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {PaginatorIntlService} from './shared/component/translations/paginator-translater';
import {PrestationPayrollReleasedComponent} from './prestations/prestation-payroll-released/prestation-payroll-released.component';
import {SnackbarComponent} from './shared/component/snackbar/snackbar.component';
import {JWT_OPTIONS, JwtHelperService} from '@auth0/angular-jwt';
import {AuthGuard} from './login/shared/guard/auth-guard.service';
import {appInitializer} from './login/shared/service/auto-login.service';
import {
  SubsidiaryCustomerDialogComponent
} from './customers/shared/component/customer-form/subsidiary-adress/subsidiary-customer-dialog/subsidiary-customer-dialog.component';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {
  RequestErrorDetailContentComponent
} from './requests/shared/component/request-error-detail/request-error-detail-content/request-error-detail-content.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import {ListingPrestationComponent} from './prestations/prestation-correction/listing-prestation/listing-prestation.component';
import { GenerationComponent } from './admin/tab/generation/generation.component';
import { ServicesComponent } from './admin/tab/services/services.component';
import { BillingCoefficientComponent } from './admin/tab/billing-coefficient/billing-coefficient.component';
import { BillingCoefficientDialogComponent } from './shared/component/admin-dialog/billing-coefficient-dialog/billing-coefficient-dialog.component';
import { TravelExpenseCoefficientDialogComponent } from './shared/component/admin-dialog/travel-expense-coefficient-dialog/travel-expense-coefficient-dialog.component';
import { PublicHolidayDialogComponent } from './shared/component/admin-dialog/public-holiday-dialog/public-holiday-dialog.component';
import { PublicHolidayComponent } from './admin/tab/public-holiday/public-holiday.component';
import { JointCommissionCoefficientComponent } from './admin/tab/joint-commission-coefficient/joint-commission-coefficient.component';
import { JointCommissionCoefficientDialogComponent } from './shared/component/admin-dialog/joint-commission-coefficient-dialog/joint-commission-coefficient-dialog.component';
import { TravelExpenseCoefficientComponent } from './admin/tab/travel-expense-coefficient/travel-expense-coefficient.component';
import { StudentQuotaHoursComponent } from './admin/tab/student-quota-hours/student-quota-hours.component';
import { StudentQuotaHoursDialogComponent } from './shared/component/admin-dialog/student-quota-hours-dialog/student-quota-hours-dialog.component';
import { SalaryScaleDialogComponent } from './shared/component/admin-dialog/salary-scale-dialog/salary-scale-dialog.component';
import { SalaryScaleComponent } from './admin/tab/salary-scale/salary-scale.component';
import { WorkFunctionComponent } from './admin/tab/work-function/work-function.component';
import { WorkFunctionDialogComponent } from './shared/component/admin-dialog/work-function-dialog/work-function-dialog.component';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    UsersComponent,
    CustomersComponent,
    CustomerNewComponent,
    CustomerEditComponent,
    CustomerViewComponent,
    OperatingUnitsComponent,
    OperatingUnitEditComponent,
    OperatingUnitNewComponent,
    WorkersComponent,
    WorkerNewComponent,
    WorkerEditComponent,
    WorkerViewComponent,
    RequestsComponent,
    RequestNewComponent,
    RequestEditComponent,
    ListingRequestComponent,
    ContractsComponent,
    ContractViewComponent,
    ContractListComponent,
    RequestFormComponent,
    CustomerFormComponent,
    OperatingUnitFormComponent,
    WorkerFormComponent,
    PlanningComponent,
    UserNewComponent,
    UserEditComponent,
    UserFormComponent,
    PrestationsComponent,
    ErrorListingRequestComponent,
    PaidRequestComponent,
    DashboardComponent,
    RequestErrorDetailComponent,
    ErrorDetailDialogComponent,
    NonAvailabilityDialogComponent,
    ValidateRequestDialogComponent,
    AddEventPeriodDialogComponent,
    PrestationDialogComponent,
    BusinessContactComponent,
    BusinessContactViewComponent,
    PrestationCorrectionComponent,
    PrestationValidationComponent,
    BillsComponent,
    BillViewComponent,
    ProcessedDocumentDialogComponent,
    UploadDocumentComponent,
    ConfirmationDialogComponent,
    ExportDialogComponent,
    WorkersLiteComponent,
    CancelCorrectionPrestationDialogComponent,
    EditCorrectionDialogComponent,
    ValidationCorrectionDialogComponent,
    SpinnerComponent,
    PrestationRejectDialogComponent,
    PendingListingRequestsComponent,
    CopyRequestDialogComponent,
    RequestResetPasswordComponent,
    EditPasswordComponent,
    AdminComponent,
    ExportContractsComponent,
    ExportDimonasComponent,
    ExportPrestationsComponent,
    ExportPrestationsNewComponent, //November 2023 export
    ExportPrestationscodesComponent,
    ExportInvoicesComponent,
    ExportInvoicesExactOnlineComponent,
    ExportGenericComponent,
    PrestationPayrollReleasedComponent,
    SnackbarComponent,
    SubsidiaryCustomerDialogComponent,
    RequestErrorDetailContentComponent,
    ListingPrestationComponent,
    GenerationComponent,
    ServicesComponent,
    BillingCoefficientComponent,
    BillingCoefficientDialogComponent,
    TravelExpenseCoefficientDialogComponent,
    PublicHolidayDialogComponent,
    PublicHolidayComponent,
    JointCommissionCoefficientComponent,
    JointCommissionCoefficientDialogComponent,
    TravelExpenseCoefficientComponent,
    StudentQuotaHoursComponent,
    StudentQuotaHoursDialogComponent,
    WorkFunctionComponent,
    WorkFunctionDialogComponent,
    StudentQuotaHoursDialogComponent,
    SalaryScaleDialogComponent,
    SalaryScaleComponent
  ],
  imports: [
    BrowserModule,
    FullCalendarModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatDialogModule,
    ResolverModule,
    NgxIbanModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthorizationService]
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-BE'
    },
    UserService,
    AuthorizationService,
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    DatePipe,
    FacadeService,
    {
      provide: DateAdapter,
      useClass: MyDateAdapter
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    SupervisorGuard,
    SuperAdminGuard,
    AuthGuard,
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    },
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS
    },
    JwtHelperService,
    // {
    //   provide: NGX_MAT_DATE_FORMATS,
    //   deps: [TranslateService],
    //   useFactory: getDateFormatFactory,
    // },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


// // DECLARE A CUSTOM HERE WILL NOT ALLOW THE FORMAT TO CHANGE DYNAMICALLY ! //
// App module is only called once so we couldn't use it dynamically with the currentLang (or at least we didn't find) //

// export function getDateFormatFactory(translate: TranslateService) {

//   switch (translate.currentLang) {
//     case 'fr':
//      return {
//         parse: {
//           dateInput: 'DD-MM-YYYY HH:mm:ss'
//         },
//         display: {
//           dateInput: 'MM-DD-YYYY HH:mm',
//           monthYearLabel: 'MMM YYYY',
//           dateA11yLabel: 'LL',
//           monthYearA11yLabel: 'MMMM YYYY',
//         }
//       } as NgxMatDateFormats;

//       case 'nl':
//         return {
//           parse: {
//             dateInput: 'YYYY-MM-DD HH:mm:ss'
//           },
//           display: {
//             dateInput: 'MM-DD-YYYY HH:mm',
//             monthYearLabel: 'MMM YYYY',
//             dateA11yLabel: 'LL',
//             monthYearA11yLabel: 'MMMM YYYY',
//           }
//         } as NgxMatDateFormats;

//     default:
//       return {
//         parse: {
//           dateInput: 'MM-YYYY HH:mm:ss'
//         },
//         display: {
//           dateInput: 'MM-DD-YYYY HH:mm',
//           monthYearLabel: 'MMM YYYY',
//           dateA11yLabel: 'LL',
//           monthYearA11yLabel: 'MMMM YYYY',
//         }
//       } as NgxMatDateFormats;
//   }
// }


