import {Serializer} from '../../../shared/model/serializer';
import {Prestation} from './prestation.model';
import {PrestationPeriodSerializer} from './prestation-period.serializer';
import {PrestationPeriod} from './prestation-period.model';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {JointCommissionCoefficientSerializer} from '../../../shared/model/joint-commission/joint-commission-coefficient.serializer';
import {JointCommissionCoefficient} from '../../../shared/model/joint-commission/joint-commission-coefficient.model';

export class PrestationSerializer implements Serializer {
  private periodSerializer: PrestationPeriodSerializer = new PrestationPeriodSerializer();
  private dateSerializer: DateSerializer = new DateSerializer();
  private jointCommissionCoefficientSerializer: JointCommissionCoefficientSerializer = new JointCommissionCoefficientSerializer();

  fromJson(json: any): Prestation {
    const prestation: Prestation = new Prestation();
    const periods: PrestationPeriod[] = [];
    let jointCommissionCoefficient: JointCommissionCoefficient = new JointCommissionCoefficient();

    prestation.id = json.id;
    prestation.jointCommissionCode = json.jointCommissionCode;
    prestation.contractId = json.contractId;
    prestation.requestId = json.requestId;
    prestation.operatingUnitId = json.operatingUnitId;
    prestation.operatingUnitName = json.operatingUnitName;
    prestation.customerId = json.customerId;
    prestation.customerName = json.customerName;
    prestation.customerNumber = json.customerNumber;
    prestation.workerId = json.workerId;
    prestation.workerFirstName = json.workerFirstName;
    prestation.workerLastName = json.workerLastName;
    prestation.workerNiss = json.workerNiss;
    prestation.workerFamilySituation = json.workerFamilySituation;
    prestation.workerType = json.workerType;
    prestation.billingCoefficient = json.billingCoefficient;
    prestation.salaryScaleValue = json.salaryScaleValue;
    prestation.travelExpenses = json.travelExpenses;
    prestation.travelDistance = json.travelDistance;
    prestation.costCenter = json.costCenter;
    prestation.status = json.status;
    prestation.socialSecretaryStatus = json.socialSecretaryStatus;
    prestation.invoicingStatus = json.invoicingStatus;
    prestation.startDate = this.dateSerializer.fromJson(json.startDate);
    prestation.endDate = this.dateSerializer.fromJson(json.endDate);
    prestation.sentToSSDate = this.dateSerializer.fromJson(json.sentToSSDate);
    prestation.active = json.active;
    prestation.travelCoefficient = json.travelCoefficient;
    prestation.partiallyProcessed = json.partiallyProcessed;
    prestation.fullyProcessed = json.fullyProcessed;
    const jsonPeriods = json.periods;
    if (jsonPeriods) {
      jsonPeriods.forEach(
        jsonPeriod => {
          periods.push(this.periodSerializer.fromJson(jsonPeriod));
        });
    }
    prestation.periods = periods;
    const jsonJointCommissionCoefficient = json.jointCommissionCoefficient;
    if (jsonJointCommissionCoefficient) {
      jointCommissionCoefficient = this.jointCommissionCoefficientSerializer.fromJson(jsonJointCommissionCoefficient);
    }
    prestation.jointCommissionCoefficient = jointCommissionCoefficient;

    return prestation;
  }

  toJson(prestation: Prestation): any {
    const periods: any[] = [];
    prestation.periods.forEach(
      period => {
        period.prestation = prestation;
        periods.push(this.periodSerializer.toJson(period));
      }
    );

    return {
      id: prestation.id,
      jointCommissionCode: prestation.jointCommissionCode,
      contractId: prestation.contractId,
      requestId: prestation.requestId,
      operatingUnitId: prestation.operatingUnitId,
      operatingUnitName: prestation.operatingUnitName,
      customerId: prestation.customerId,
      customerNumber: prestation.customerNumber,
      customerName: prestation.customerName,
      workerId: prestation.workerId,
      workerFirstName: prestation.workerFirstName,
      workerLastName: prestation.workerLastName,
      workerNiss: prestation.workerNiss,
      workerFamilySituation: prestation.workerFamilySituation,
      workerType: prestation.workerType,
      billingCoefficient: prestation.billingCoefficient,
      salaryScaleValue: prestation.salaryScaleValue,
      travelExpenses: prestation.travelExpenses,
      travelDistance: prestation.travelDistance,
      costCenter: prestation.costCenter,
      status: prestation.status,
      startDate: this.dateSerializer.fromJson(prestation.startDate),
      endDate: this.dateSerializer.fromJson(prestation.endDate),
      sentToSSDate: this.dateSerializer.fromJson(prestation.sentToSSDate),
      active: prestation.active,
      travelCoefficient: prestation.travelCoefficient,
      periods: periods,
      jointCommissionCoefficient: this.jointCommissionCoefficientSerializer.toJson(prestation.jointCommissionCoefficient)
    };
  }

}
