import {Component, Inject} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-correction-prestation-dialog',
  templateUrl: './cancel-correction-prestation-dialog.component.html',
  styleUrls: ['./cancel-correction-prestation-dialog.component.css']
})
export class CancelCorrectionPrestationDialogComponent {

  constructor(public dialogRef: MatDialogRef<CancelCorrectionPrestationDialogComponent>,
              private facadeService: FacadeService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private translate: TranslateService) {
  }

  cancelPrestationPeriod(form: NgForm) {
    if (!form.valid) {
      return alert(this.translate.instant('prestations.correction.cancel.message.error.form'));
    }
    const correctionPrestationCodeId = form.value.correctionPrestationCode;
    this.facadeService.cancelPrestationPeriod(this.data.prestationPeriod, correctionPrestationCodeId)
      .subscribe({
        next: answer => {
          this.dialogRef.close(answer);
        },
        error: () => {
          return alert(this.translate.instant('prestations.correction.cancel.message.error.save'));
        }
      });
  }

}
