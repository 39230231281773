import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FacadeService } from '../../../shared/service/facade/facade.service';
import { MatDialog } from '@angular/material/dialog';
import { TravelExpenseCoefficient } from '../../model/travel-expense-coefficient.model';
import { TravelExpenseCoefficientPage } from '../../../shared/model/pagination/travel-expense-coefficient/travel-expense-coefficient-page.model';
import { TravelExpenseCoefficientDialogComponent } from 'src/app/shared/component/admin-dialog/travel-expense-coefficient-dialog/travel-expense-coefficient-dialog.component';

@Component({
  selector: 'app-travel-expense-coefficient',
  templateUrl: './travel-expense-coefficient.component.html',
  styleUrls: ['./travel-expense-coefficient.component.css', '../../admin.component.css']
})
export class TravelExpenseCoefficientComponent implements OnInit {

  @ViewChild('TravelExpenseCoefficientPaginator', { static: true }) paginator: MatPaginator;

  travelExpenseCoefficient: TravelExpenseCoefficient;

  dataSource = new MatTableDataSource<TravelExpenseCoefficient>();
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 20;
  length: number;

  constructor(
    private facadeService: FacadeService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = [
    "id",
    "active",
    "distanceKM",
    "weeklyIndemnities",
    "weeklyA5",
    "weeklyA6",
    "weeklyA7"
  ];

  public getData(event?: PageEvent) {
    this.pageIndex = event ? event.pageIndex : 0;
    this.pageSize = event ? event.pageSize : 20;
    this.facadeService.getPageTravelExpenseCoefficient(this.pageIndex, this.pageSize).subscribe(
      (data: TravelExpenseCoefficientPage) => {
        this.dataSource.data = data.content;
        this.dataSource.paginator = this.paginator;
        this.length = data.totalElements;
      }
    );
    return event;
  }

  openPopUp(row) {
    const dialogRef = this.dialog.open(TravelExpenseCoefficientDialogComponent, {
      data: { element: row },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }

  addElement() {
    this.travelExpenseCoefficient = new TravelExpenseCoefficient;
    this.openPopUp(this.travelExpenseCoefficient);
  }

}
