import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class AgeCheckValidators {
  static ageCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = new Date(control.value);

      if (!controlDate) {
        return null;
      }

      const validationDate = new Date();
      
      return (
        controlDate.getFullYear() > (validationDate.getFullYear() - 120) &&
        controlDate.getFullYear() < (validationDate.getFullYear() - 14)
      )? null : {
        'date-minimum': {
          'date-minimum': validationDate,
          'actual': controlDate
        }
      };
    };
  }
}

