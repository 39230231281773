import {ContractPeriod} from '../contract.model';

export class ContractWeekPeriodDay {
  date: Date;
  periods: ContractPeriod[] = [];
  totalHours: number;
  totalMinutes: number;

  constructor(date: Date, periods: ContractPeriod[]) {
    this.date = new Date(date);
    this.periods = periods;
    this.computeTotal(periods);
  }

  private computeTotal(periods: ContractPeriod[]) {
    let duration = 0;
    periods.forEach(
      p => {
        duration += p.endDate.getTime() - p.startDate.getTime();

        const b = new Date(0, 0, 0, 0, 0, 0);
        b.setHours(p.breakDuration.getHours());
        b.setMinutes(p.breakDuration.getMinutes());
        duration -= b.getTime() - new Date(0, 0, 0, 0, 0, 0).getTime();
      }
    );
    const totalSeconds = duration / 1000;
    this.totalMinutes = Math.floor(totalSeconds / 60);
    this.totalHours = Math.floor(this.totalMinutes / 60);
    this.totalMinutes = this.totalMinutes - this.totalHours * 60;
  }
}
