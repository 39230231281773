import {Component} from '@angular/core';
import {OperatingUnit} from 'src/app/operating-units/shared/model/operating-unit.model';
import {ActivatedRoute} from '@angular/router';
import {Country} from '../../shared/model/country/country.model';
import {Language} from '../../shared/model/language/language.model';
import {WorkerLite} from '../../workers-lite/shared/model/worker-lite.model';
import {Worker} from '../shared/model/worker.model';
import {WorkerPosition} from '../../shared/worker-position/worker-position.model';

@Component({
  selector: 'app-worker-new',
  templateUrl: './worker-new.component.html',
  styleUrls: ['./worker-new.component.css']
})
export class WorkerNewComponent {
  operatingUnits: OperatingUnit[] = [];
  countries: Country[] = [];
  languages: Language[] = [];
  worker: Worker;
  workerPositions: WorkerPosition[] = [];

  constructor(private route: ActivatedRoute) {
    this.getAllOperatingUnits();
    this.getAllCountries();
    this.getAllLanguages();
    this.getWorkerLite();
    this.getAllWorkerPositions();
  }

  private getWorkerLite() {
    const workerLite = <WorkerLite>this.route.snapshot.data['workerLite'];
    if (workerLite) {
      this.worker = workerLite.createWorkerFromWorkerLite();
    }
  }

  private getAllOperatingUnits() {
    const operatingUnits = <OperatingUnit[]>this.route.snapshot.data['operatingUnits'];
    Object.assign(this.operatingUnits, operatingUnits);
  }

  private getAllCountries() {
    const countries = <Country[]>this.route.snapshot.data['countries'];
    Object.assign(this.countries, countries);
  }

  private getAllLanguages() {
    const languages = <Language[]>this.route.snapshot.data['languages'];
    Object.assign(this.languages, languages);
  }

  private getAllWorkerPositions() {
    const workerPositions = <WorkerPosition[]>this.route.snapshot.data['workerPositions'];
    Object.assign(this.workerPositions, workerPositions);
  }
}
